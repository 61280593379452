import { render, staticRenderFns } from "./setbranchInfo.vue?vue&type=template&id=2fdd01b8&scoped=true&"
import script from "./setbranchInfo.vue?vue&type=script&lang=js&"
export * from "./setbranchInfo.vue?vue&type=script&lang=js&"
import style0 from "./setbranchInfo.vue?vue&type=style&index=0&id=2fdd01b8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fdd01b8",
  null
  
)

export default component.exports