var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('draggable',{class:[_vm.level === 0 ? 'color-item-box small-scroll' : ''],attrs:{"forceFallback":true,"options":{
      animation: 500,
      ghostClass: 'sortable-ghost',
      chosenClass: 'sortable-chosen',
      handle: '.paixu-icon',
    },"draggable":'.' + _vm.draggableGroupName},on:{"change":_vm.change,"refreshSort":_vm.refreshSort},model:{value:(_vm.labelList),callback:function ($$v) {_vm.labelList=$$v},expression:"labelList"}},_vm._l((_vm.labelList),function(item,i){return _c('div',{key:item.Id + '-loop-group',staticClass:"level-item",class:_vm.draggableGroupName,style:({
        'padding-left': _vm.level * 18 + 'px'
      })},[_c('div',{staticClass:"content",on:{"click":function($event){return _vm.expand(item)}}},[_c('div',{staticClass:"title-level-div"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"arrow-icon",class:{
                'padding': _vm.level < 2 && (!item.ChildList || !item.ChildList.length),
                'padding-left': _vm.level >= 2
              }},[(item.ChildList && item.ChildList.length)?_c('i',{staticClass:"iconfont icon_arrow_right",class:{'down': _vm.expandId === item.Id}}):_vm._e()]),_c('div',{staticClass:"label-content"},[_vm._t("label-content",null,{"info":item})],2)]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"icon-group",attrs:{"_":_vm._ = _vm.getIconGroup(item)}},[_vm._l((_vm._),function(it,ix){return [(it.type !== 'move')?[_c('ConIcon',{attrs:{"icon":it.icon,"tip-text":it.text},on:{"click":function($event){return _vm.iconClick(it.type, item)}}})]:_vm._t("move-popover",null,{"info":item})]})],2),(!_vm.curType)?_c('ConIcon',{staticClass:"paixu-icon",attrs:{"icon":"iconfont paixu","tipText":"排序"}}):_vm._e()],1)]),_vm._t("label-type",null,{"info":item})],2),(item.ChildList && item.ChildList.length && _vm.expandId === item.Id)?[_c('label-draggable',{ref:'item-' + item.Id,refInFor:true,attrs:{"list":item.ChildList,"get-icon-group":_vm.getIconGroup,"level":item.NowLevel + 1,"pid":item.Id,"curType":_vm.curType},on:{"refreshSort":function (list) { return _vm.refreshSort(item,list); },"icon-click":function (e) { return _vm.$emit('icon-click', e); }},scopedSlots:_vm._u([{key:"label-content",fn:function(ref){
              var info = ref.info;
return [_vm._t("label-content",[_vm._v(_vm._s(info.LabelContent))],{"info":info})]}},{key:"move-popover",fn:function(ref){
              var info = ref.info;
return [_vm._t("move-popover",null,{"info":info})]}}],null,true)})]:_vm._e()],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }