<!--
 * @Author: ZBH
 * @Date: 2021-01-24 16:43:00
 * @LastEditors: tangshuo
 * @LastEditTime: 2022-01-11 18:53:21
 * @Remarks:
-->

<template>
  <div class="accountSet-main">
    <header class="account-header-w flex flex-js">
      <div class="account-header">
        <i class="iconfont icon_arrow_left" @click="closeAddDig"></i>
        <span>更多</span>
      </div>
    </header>
    <i class="head-line"></i>
    <div class="tab-list">
      <div
        class="tab-item tab-item1 flex flex-b"
        @click="tabChange(item)"
        v-Auth="{
          type: 'btn',
          code: item.name,
          pCode: 'Menu',
          rCode: '',
          isAdmin: isSetAuth,
        }"
        v-for="(item, index) in tabList2"
        :key="'ind' + index"
      >
        {{ item.value }}
        <i class="iconfont icon_arrow_right"></i>
      </div>
      <div
        class="tab-item tab-item1 flex flex-b"
        @click="tabChange({ value: '权限设置' })"
        v-if="isSetAuth"
      >
        权限设置
        <i class="iconfont icon_arrow_right"></i>
      </div>
      <div
        class="tab-item tab-item1 flex flex-b"
        @click="$emit('openInquiryLog')"
      >
        询档记录
        <i class="iconfont icon_arrow_right"></i>
      </div>
      <div
          class="tab-item tab-item1 flex flex-b"
          @click="tabChange({value: '音乐库'})"
      >
        音乐库
        <i class="iconfont icon_arrow_right"></i>
      </div>
      <div
        class="tab-item tab-item1 flex flex-b"
        @click="tabChange(item)"
        v-Auth="{
          type: 'btn',
          code: item.name,
          pCode: 'Menu',
          rCode: '',
          isAdmin: isSetAuth,
        }"
        v-for="(item, index) in tabList3"
        :key="'i' + index"
      >
        {{ item.value }}
        <i class="iconfont icon_arrow_right"></i>
      </div>
    </div>
    <SetBranchInfo
      :isShowSet="isShowSet"
      @closeSetDig="isShowSet = false"
    ></SetBranchInfo>
    <CustomTitle
      :isShowCustom="isShowCustom"
      @closeCustomDig="isShowCustom = false"
    ></CustomTitle>
    <CustomLoginBg
      :isShowCustom="isShowLoginBg"
      @closeCustomDig="isShowLoginBg = false"
    ></CustomLoginBg>
    <FullscreenSet
      :isShowSetFull="isShowSetFull"
      @closeFull="isShowSetFull = false"
    ></FullscreenSet>
    <SetSequence
      :isShowSequence="isShowSequence"
      @closeCustomDig="isShowSequence = false" />
  </div>
</template>

<script>
import SetBranchInfo from "./setbranchInfo.vue";
import CustomTitle from "./customTitle.vue";
import CustomLoginBg from "./customLoginBg.vue";
import FullscreenSet from "./fullscreenSet.vue";
import SetSequence from "./setSequence.vue";

export default {
  data() {
    return {
      tabList2: [
        { value: "品牌设置", name: "Setting" },
        { value: "用户管理", name: "Authorize" },
      ],
      tabList3: [
        { value: "公司页自定义", name: "Setting" },
        { value: "登录背景自定义", name: "Setting" },
        { value: "门户页(休息)设置", name: "Setting" },
        { value: "导航菜单自定义", name: "Setting" },
        { value: "新增/推送案例默认顺序设置", name: "Setting" },
      ],
      isShowSet: false,
      isShowCustom: false,
      isShowLoginBg: false,
      isShowSetFull: false,
      isShowSequence: false,
    };
  },
  props: {
    isSetAuth: {
      default: false,
    },
  },
  components: {
    SetBranchInfo,
    CustomTitle,
    CustomLoginBg,
    FullscreenSet,
    SetSequence
  },
  mounted() {
    console.log("11", this.isSetAuth);
  },
  methods: {
    tabChange(val) {
      if (val.value === "品牌设置") {
        this.isShowSet = true;
        return;
      }
      if (val.value === "导航菜单自定义") {
        this.isShowCustom = true;
        return;
      }
      if (val.value === "登录背景自定义") {
        this.isShowLoginBg = true;
        return;
      }
      if (val.value === "门户页(休息)设置") {
        this.isShowSetFull = true;
        return;
      }
      if (val.value === "新增/推送案例默认顺序设置") {
        this.isShowSequence = true
        return;
      }
      this.$emit("tabChange", val);
    },
    //返回
    closeAddDig() {
      this.$emit("closeAddDig");
    },
  },
};
</script>

<style scoped lang="scss">
.accountSet-main {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  @include themify($themes) {
    background: themed("main-bg");
  }
  z-index: 3;

  .account-header-w {
    border: 0px solid transparent;
  }
  .tab-item1 {
    padding-left: 24px;
  }
  .head-line {
    display: inline-block;
    width: calc(100% - 48px);
    margin-left: 24px;
    height: 1px;
    background: #eeeeee;
    @include themify($themes) {
      background: themed("hover-bg");
    }
    vertical-align: top;
  }
}
.edit-case-iframe {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999;
}
.edit-case-iframe iframe {
  width: 100%;
  height: 100%;
  border: 0;
  background: transparent;
}
</style>
