/*
 * @Author: tangshuo
 * @Date: 2021-01-21 09:34:13
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-12-31 14:18:34
 * @Remarks: 
 */
/**
 * 公共基础组件
 */
import button from './con-button/button';
import icon from './con-icon/icon';
import tipDig from './con-tip-dig/tip-dig';
import conDig from './con-dig/dig';
import conInput from './con-input/input';
import conSelect from './con-select/select';
import conPlayer from './con-player/ckPlayer';
import conSlideTab from './con-slide-tab/slide-tab';

const component = {
    install: function (Vue) {
        Vue.component('ConButton', button); //按钮
        Vue.component('ConIcon', icon); //图标
        Vue.component('ConTip', tipDig); //提示操作
        Vue.component('ConDig', conDig); //弹窗
        Vue.component('ConInput', conInput); //输入框
        Vue.component('ConSelect', conSelect); //下拉选择弹窗        
        Vue.component('ConPlayer', conPlayer); //视频播放弹窗     
        Vue.component('ConSlideTab', conSlideTab); //滑块切换
    }
}

export default component;