<template>
<div>
  <ConDig
      title="价格区间自定义"
      width="460px"
      :isShowDig="showCustomPrice"
      :loading="isLoading"
      :appendToBody="true"
      @open="initData"
      @closeDig="$emit('cancelClick')"
      @closed="$emit('cancelClick')"
      @cancelClick="$emit('cancelClick')"
      @confirmClick="confirmClick"
      :confirmText="'保存'"
  >
    <main slot="dialogMain" class="custom-price" v-loading="dataLoading">
      <!--   第一条   -->
      <div class="gradient-item flex flex-start">
        <el-input
            v-model="minPrice"
            @input="minPrice = oninput($event)"
            @blur="minInput"
            type="number"
            class="gradient-input"
            placeholder="请输入"
        ></el-input>
        {{priceType === 'thousand' ? '万' : '元'}}（含）以下
      </div>

      <!--   中间   -->
      <template v-if="priceList.length > 0">
        <div class="gradient-item flex flex-start" v-for="(item, index) in priceList" :key="'index' + index">
          <el-input
              v-model="item.SmallPriceVal"
              type="number"
              :disabled="true"
              class="gradient-input"
              placeholder="请输入"
          ></el-input>
          {{priceType === 'thousand' ? '万' : '元'}}至
          <el-input
              @input="item.BigPriceVal = oninput($event)"
              @blur="priceInput(item.BigPriceVal, index)"
              v-model="item.BigPriceVal"
              type="number"
              class="gradient-input"
              placeholder="请输入"
          ></el-input>
          {{priceType === 'thousand' ? '万' : '元'}}（含）
          <i class="iconfont icon_qingchu" @click="deleteInterval(index)"></i>
        </div>
      </template>

      <div class="gradient-item add-btn-wrap  flex flex-start" @click="addGradItem">
        <div class="add-btn flex">
          <i class="iconfont icon_add_big"></i>
          新增区间
        </div>
      </div>

      <!--  最后  -->
      <div class="gradient-item flex flex-start line-hide">
        <el-input
            v-model="maxPrice"
            type="number"
            :disabled="true"
            class="gradient-input"
            placeholder="请输入"
        ></el-input>
        {{priceType === 'thousand' ? '万' : '元'}} 以上
      </div>
    </main>
  </ConDig>
</div>
</template>

<script>
export default {
  name: "custom-price",
  methods: {
    oninput(e) {
      // //只能输入整数
      // e = Number(e) >= 0 ? e.replace(/^(0+)|[^\d]+/g,'') : '';

      //只能输入两个小数
      e = Number(e) >= 0 ? e.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') : '';
      return e;
    },
    //中间参数 blur
    priceInput(e, index) {
      let val = e;
      if (
          Number(e) <= Number(this.minPrice) &&
          index === 0
      ) {
        // this.$message.warning(`金额必须大于${this.minPrice}`);

        val = Number(this.minPrice) + 1;
        this.priceList[index].BigPriceVal = val;
      } else if (
          index >= 0 &&
          Number(e) <= Number(this.priceList[index].SmallPriceVal)
      ) {
        // this.$message.warning(`金额必须大于${this.priceList[index].SmallPriceVal}`);

        val = Number(this.priceList[index].SmallPriceVal) + 1;
        this.priceList[index].BigPriceVal = val;
      }

      if (index === this.priceList.length - 1) {
        this.maxPrice = val;
      } else {
        this.priceList[index + 1].SmallPriceVal = val;
      }
    },
    // 第一个参数 blur
    minInput() {
      if (this.priceList.length) {
        this.priceList[0].SmallPriceVal = this.minPrice;
      } else {
        this.maxPrice = this.minPrice;
      }
    },
    initData() {
      this.isChange = false;
      this.dataLoading = true;
      const key = this.priceType === 'thousand' ? 0 : 1; // 价格单位 thousand 万元 / unit 元
      this.$axios({
        url: "NewWeddingApi/FileManage/HomeShow/FilePush/GetBranchIniPriceList?Key="+ key,
        method: "POST",
        success(res) {
          this.dataLoading = false;

          if (res && res.data && res.data.status) {
            if (res.data.data) {
              const data = res.data.data;
              this.minPrice = data.length > 0 ? data[0].BigPriceVal : 0;
              this.maxPrice = data.length > 1 ? data[data.length - 1].SmallPriceVal : 0;
              this.priceList = data.length > 2 ? data.filter((res, index) => index > 0 && index < data.length - 1) : [];
            }
          } else {
            this.$Error("获取自定义价格失败");
          }
        },
        error() {
          this.dataLoading = false;
          this.$Error("获取自定义价格失败");
        },
      });
    },
    // 删除区间
    deleteInterval(index) {
      if (index === 0) {
        this.priceList.length > 1 ? (this.priceList[index + 1].SmallPriceVal = this.minPrice) : ( this.maxPrice = this.minPrice);
      } else if (index === this.priceList.length - 1) {
        this.maxPrice = this.priceList[index - 1].BigPriceVal;
      } else {
        this.priceList[index + 1].SmallPriceVal = this.priceList[index - 1].BigPriceVal;
      }
      this.priceList.splice(index, 1);
    },
    // 新增区间
    addGradItem() {
      if (
          this.priceList.length &&
          this.checkNull(this.priceList[this.priceList.length - 1].BigPriceVal)
      ) {
        this.$message.warning('请先完善上一个价格区间');
        return;
      }

      let min = '';
      if (this.priceList.length) {
        min = this.priceList[this.priceList.length - 1].BigPriceVal;
      } else {
        min = this.minPrice;
      }
      this.priceList.push({
        SmallPriceVal: min || null,
        BigPriceVal: null,
      });
    },
    //表单验证 空数据
    checkForm() {
      if (this.checkNull(this.minPrice)) {
        return false;
      }

      let nullList = this.priceList.find(c => this.checkNull(c.SmallPriceVal) || this.checkNull(c.BigPriceVal));
      return !nullList;
    },
    confirmClick() {
      //空数据验证 空数据
      if (!this.checkForm()) {
        this.$message.warning('请完善所有信息');
        return;
      }

      //最高大于最低
      let maxList = this.priceList.find((type, index) => Number(type.BigPriceVal) <= Number(type.SmallPriceVal));
      if (maxList) {
        this.$message.warning('区间最高要大于最低');
        return;
      }
      const min = {
        "SmallPriceVal": null,
        "BigPriceVal": this.minPrice
      }
      const max = {
        "SmallPriceVal": this.maxPrice,
        "BigPriceVal": null
      }
      const list = this.priceList.map(c => {
        return {
          "SmallPriceVal": c.SmallPriceVal,
          "BigPriceVal": c.BigPriceVal
        }
      })
      const postData = [min, ...list, max];
      const key = this.priceType === 'thousand' ? 0 : 1; // 价格单位 thousand 万元 / unit 元

      this.$axios({
        url: "NewWeddingApi/FileManage/HomeShow/FilePush/SetBranchIniPriceList?Key=" + key,
        method: "POST",
        data: postData,
        success(res) {
          if (res && res.data.status) {
            this.isChange = true;
            this.$emit('confirmClick', this.isChange);
          } else {
            this.$Error("设置自定义价格失败");
          }
        },
        error() {
          this.$Error("设置自定义价格失败");
        },
      });
    },
    //空判断
    checkNull(val) {
      // return val === null || val === '' || val === 0;
      return val === null || val === '' || val === undefined;
    },
  },
  data() {
    return {
      isLoading: false,
      isChange: false, // 是否改变了
      priceList: [], //自定义价格列表
      minPrice: 0, // 最小价格
      maxPrice: 0, // 最大价格
      dataLoading: true
    }
  },
  props: {
    //是否显示弹窗
    showCustomPrice: {
      type: Boolean,
      default: false,
    },
    // 价格单位 thousand 万元 / unit 元
    priceType: {
      type: String,
      default: 'thousand'
    }
  }

}
</script>

<style scoped lang="scss">
.custom-price {
  padding: 24px;
  height: 400px;
  overflow: auto;
  .flex {
    align-items: center;
  }
  .flex-start {
    justify-content: flex-start;
  }
}
.gradient-item {
  padding-left: 16px;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  color: #666;
  line-height: 20px;
  margin-bottom: 16px;
  @include themify($themes) {
    color: themed("hotel-color");
  }
  .icon-ico_remove_hover1 {
    color: #ff3355;
    margin-left: 8px;
    cursor: pointer;
  }
  .icon_qingchu {
    color: #999;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 4px;
    &:hover {
      color: #222;
      @include themify($themes) {
        color: themed("input-color");
      }
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 10px;
    left: -1px;
    width: 8px;
    height: 8px;
    background: #cccccc;
    border-radius: 4px;
    @include themify($themes) {
      background: themed("input-tip-color");
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 22px;
    left: 2px;
    width: 2px;
    height: 28px;
    background: #cccccc;
    @include themify($themes) {
      background: themed("input-tip-color");
    }
  }
}
.add-btn-wrap {
  display: inline-block;
}

::v-deep .gradient-input {
  width: 80px;
  border-radius: 0;
  margin: 0 4px;
  .el-input__inner {
    height: 28px;
    line-height: 28px;
    border-radius: 0;
  }

  .el-input__suffix-inner {
    .suffix-input {
      font-size: 14px;
      font-weight: 400;
      color: #666f80;
      line-height: 28px;
      margin-right: 4px;
    }
  }
}
.gradient-input ::v-deep .el-input__inner {
  border-color: #eee;
  color: #222;
  background: #fff;
  @include themify($themes) {
    background: themed("lable-bg");
    border-color: themed('search-his-bg-h');
    color: themed('input-color');
  }
  &:focus {
    border-color: #222;
    @include themify($themes) {
      border-color: themed('input-color');
    }
  }
}
.el-input.is-disabled ::v-deep .el-input__inner {
  border-color: #eee;
  color: #999;
  background: #eee;
  @include themify($themes) {
    background: themed("cancel-btn-border");
    border-color: themed('search-his-bg-h');
    color: themed('search-item-color');
  }
}
.line-hide {
  &::before {
    display: none;
  }
}
.add-btn-wrap {
  margin-bottom: 13px;
}
.add-btn {
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  cursor: pointer;
  display: inline-flex;
  padding: 0 8px;
  box-sizing: border-box;
  width: 96px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  @include themify($themes) {
    background: themed("search-bg");
    border-color: themed('hover-bg');
    color: themed('operate-color');
  }
  &:hover {
    background: #EEEEEE;
    @include themify($themes) {
      background: themed("hover-bg");
      border-color: themed('hover-bg');
      color: themed('font-color-active2');
    }
  }
}
</style>

