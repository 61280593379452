<!--
 * @Author: tangshuo
 * @Date: 2021-02-20 17:16:49
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-10-14 15:32:16
 * @Remarks: 裁剪图片组件
-->
<template>
  <ConDig
    title="裁剪"
    width="648px"
    :isShowDig="isShowTailor"
    :loading="isLodding"
    @open="initData"
    :appendToBody="true"
    @closeDig="closeTailorDig"
    @cancelClick="closeTailorDig"
    @confirmClick="confirmClick"
  >
    <main slot="dialogMain" class="tailoring-cover" v-loading="loading">
      <div class="cropper-box">
        <vueCropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.size"
          :outputType="option.outputType"
          :info="true"
          :full="option.full"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :fixed="option.fixed"
          :fixedNumber="option.fixedNumber"
          :centerBox="option.centerBox"
          :infoTrue="option.infoTrue"
          :fixedBox="option.fixedBox"
          :mode="option.mode"
        ></vueCropper>
      </div>
      <span class="tip-span">温馨提示：滚动鼠标可以缩放图片大小</span>
    </main>
  </ConDig>
</template>

<script>
export default {
  data() {
    return {
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "png", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 360, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [8,5], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        mode: '100% auto',
      },
      branchId: sessionStorage.getItem("BranchId"),
      progress: 0, //进度
      isLodding: false,
      loading: true,
      originalImg: null, // 原始图片信息
    };
  },
  props: {
    isShowTailor: {
      default: true,
    },
    imgSrc: {
      default: "",
    },
    fileId: {
      default: "",
    },
    isImg: {
      type: Boolean,
      default: false,
    },
    // 是否 设置数据万象 图片
    isImageMogr2: {
      type: Boolean,
      default: false,
    },
    pathKey: {
      default: "",
    },
    fixed: {
      type: Boolean,
      default: true,
    },
    centerBox: {
      type: Boolean,
      default: true,
    },
    fixedNumber: {
      default: () => [8, 5],
    },
    autoCropWidth: {
      default: 360,
    },
    autoCropHeight: {
      default: 200,
    },
    /**
     * 是否不压缩
     */
    uncompressed: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否上传logo
     */
    logoUpload: {
      type: Boolean,
      default: false,
    }
  },
  created() {},
  mounted() {
    this.initData()
  },
  methods: {
    /**
     * 获取裁剪后的 宽高 位置
     */
    getCropImgInfo() {
      if (
          !this.originalImg ||
          (
              this.imgSrc.indexOf('http://') === -1 &&
              this.imgSrc.indexOf('https://') === -1
          )
      ) {
        return null;
      }

      const cropW = this.$refs.cropper.cropW; // 截图框宽度
      const cropH = this.$refs.cropper.cropH; // 截图框高度
      const scale = this.$refs.cropper.scale; // 图片缩放比例
      const trueWidth = this.$refs.cropper.trueWidth; // 图片真实宽度
      const trueHeight = this.$refs.cropper.trueHeight; // 图片真实高度
      const cropOffsertX = this.$refs.cropper.cropOffsertX;
      const cropOffsertY = this.$refs.cropper.cropOffsertY;
      const x = this.$refs.cropper.x; // 图片偏移x轴
      const y = this.$refs.cropper.y; // 图片偏移y轴
      const high = this.$refs.cropper.high; // 是否按照设备的dpr 输出等比例图片
      const full = this.$refs.cropper.full; // 是否输出原图比例的截图
      const enlarge = this.$refs.cropper.enlarge; // 图片根据截图框输出比例倍数

      let dpr = 1;
      if (high & !full) {
        dpr = window.devicePixelRatio;
      }
      if ((enlarge !== 1) & !full) {
        dpr = Math.abs(Number(enlarge));
        console.log(dpr);
      }

      const ratio = trueWidth/this.originalImg.width;

      const width = cropW * dpr;
      const height = cropH * dpr;

      const dx = Math.abs((x - cropOffsertX + (trueWidth * (1 - scale)) / 2) * dpr); // 图片x轴偏移
      const dy = Math.abs((y - cropOffsertY + (trueHeight * (1 - scale)) / 2) * dpr); // 图片y轴偏移

      let info = {
        width: Math.ceil(width/scale/ratio),
        height: Math.ceil(height/scale/ratio),
        dx: Math.ceil(dx/scale/ratio),
        dy: Math.ceil(dy/scale/ratio),
      }
      info.url = `${this.imgSrc}?imageMogr2/cut/${info.width}x${info.height}x${info.dx}x${info.dy}`;
      return info;
    },
    async initData() {
      // 上传logo 可自由裁剪
      if (this.logoUpload) {
        this.option.fixed = false;
      }
      this.option.autoCropHeight = this.autoCropHeight;
      this.option.autoCropWidth = this.autoCropWidth;
      this.option.fixedNumber = this.fixedNumber;
      this.option.centerBox = this.centerBox;

      this.loading = true;
      let src = this.imgSrc;
      let imgSrc = src;
      if (
          src.indexOf("blob:") > -1 ||
          src.indexOf('data:') > -1
      ) {
        //为本地路径图片 直接显示
        imgSrc = src;
      } else {
        imgSrc = `${src}?v=${new Date().getTime()}`;
      }
      const imgInfo = await this.getImgInfo(imgSrc);
      if (
          imgInfo &&
          imgInfo.width &&
          imgInfo.height
      ) {
        this.option.mode = imgInfo.width / imgInfo.height > 600/375 ? '100% auto' : 'auto 100%';

        this.originalImg = {
          width: imgInfo.width,
          height: imgInfo.height
        }
      }
      this.option.img = imgSrc;
      this.loading = false;
    },
    /**
     * 获取图片信息
     */
    getImgInfo(imgSrc) {
      return new Promise((resolve) => {
        let img = new Image();
        img.setAttribute('crossOrigin', 'Anonymous');
        img.src = imgSrc;
        img.onload = () => {
          resolve(img);
        };
        img.onerror = () => {
          resolve(null);
        }
      });
    },
    confirmClick() {
      this.$refs.cropper.getCropData((data) => {
        //保存裁剪图片
        this.newUploadImg(this.dataURLtoFile(data));
      });
    },
    //将base64转换为文件
    dataURLtoFile(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], this.$newGuid() + "." + mime.split("/")[1], {
        type: mime,
      });
    },
    newUploadImg(file) {
      this.isLodding = true;
      let self = this;
      let ysType = this.uncompressed ? 1 : (this.isImg ? 2 : 3); //ysType 1:仅源文件  2:源文件及压缩文件  3:仅压缩文件

      // 裁剪封面 传给小程序的图是数据万象图 （上一版的逻辑 1.0）
      // // 裁剪案例封面图
      let cuttingImg = null;
      // if (this.isImageMogr2) {
      //   const cuttingInfo = this.getCropImgInfo();
      //   cuttingImg = cuttingInfo && cuttingInfo.url ? cuttingInfo.url : null;
      //   ysType = !cuttingImg ? 2 : 3;
      // }

      // 裁剪封面 传给小程序的图是不压缩的原图（现在这一版 2.0）
      let thFile = '';
      let applet_cover_uncompression = false; // 小程序封面 是否不压缩
      if (this.isImageMogr2) {
        ysType = 2;
        thFile = file;
        applet_cover_uncompression = true;
      }

      this.$newUploadFile({
        file: file,
        fileId: "",
        thFile: "", //压缩文件(仅非图片文件有效)
        pathKey: this.pathKey
          ? this.pathKey
          : `${this.branchId}/CaseReveal/${this.fileId}/Images/`,
        ysType: ysType,
        isNoYs: this.logoUpload,
        onProgress(progress) {
          //上传进度
          file.progress = progress;
        },
        onTaskReady(taskId) {
          // console.log("准备就绪,开始上传 ：", taskId);
          file.taskId = taskId;
        },
        success(res) {
          console.log("上传成功", res);
          let path = res.key;
          self.isLodding = false;
          self.$emit("uploadCallback", path, res.fileSize, res.ysKey, applet_cover_uncompression ? res.key : cuttingImg);
        },
        error() {
          self.isLodding = false;
          self.$forceUpdate();
        },
      });
    },
    closeTailorDig() {
      this.$emit("closeTailorDig");
    },
  },
};
</script>

<style scoped lang="scss">
.tailoring-cover {
  padding: 24px 24px 0;
  .cropper-box {
    width: 600px;
    height: 375px;
  }
  .tip-span {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    display: inline-block;
    margin-top: 8px;
    margin-bottom: 24px;
  }
}
</style>
<style lang="scss">
.cropper-view-box {
  outline: 1px solid #fff !important;
}
.crop-point {
  width: 6px !important;
  height: 6px !important;
  border-radius: 0 !important;
  background-color: #fff !important;
  &.point1 {
    top: -3px;
    left: -3px;
  }
  &.point2 {
    top: -4px;
  }
  &.point3 {
    top: -3px;
    right: -3px;
  }
  &.point4 {
    left: -3px;
  }
  &.point5 {
    right: -3px;
  }
  &.point6 {
    bottom: -4px;
    left: -3px;
  }
  &.point7 {
    bottom: -4px;
  }
  &.point8 {
    bottom: -3px;
    right: -3px;
  }
}
</style>
