/*
 * @Author: tangshuo
 * @Date: 2021-01-19 11:39:41
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-02-02 14:35:23
 * @Remarks: 接口地址配置
 */
export default {
    baseApi: process.env.VUE_APP_URL,
    // baseApi: 'http://192.168.0.77:8082/',
    // baseApi: '/api',
    // baseApiIM: 'http://192.168.1.65:57360',
    baseApiIM: 'https://chat.weddingbo.com',
    hostApi: process.env.VUE_APP_TEAMURL,
    // hostApi: 'http://192.168.0.77:8084/', // 雄本地
    sdkAppID: process.env.VUE_APP_SDKID,
    // baseApi: 'https://weddingbo.com/',
    // hostApi: 'https://host.weddingbo.com/'

}
