<!--
 * @Author: tangshuo
 * @Date: 2021-10-09 16:48:52
 * @LastEditors: tangshuo
 * @LastEditTime: 2022-01-10 18:16:37
 * @Remarks: 导航自定义
-->
<template>
  <ConDig
      title="导航菜单自定义"
      width="480px"
      :isShowDig="isShowCustom"
      :appendToBody="true"
      :isShowFooter="false"
      @open="initData"
      @closed="isShowCustom = false"
      @closeDig="closeCustomDig"
      @cancelClick="closeCustomDig"
  >
    <main slot="dialogMain" class="custom-title-main" v-loading="loading">
      <p class="tips">*可设置本店前台功能模块是否启用、自定义模块名称、拖动排序</p>
      <div class="div-nodata" v-if="!loading && titleList.length <= 0">
        没有导航菜单可以设置
      </div>
      <draggable
          :forceFallback='true'
          v-model="titleList"
          :options="{
            animation: 500,
            handle: '.paixu',
          }"
          draggable=".custom-item"
          @change="change"
          v-if="titleList.length > 0"
      >
        <div
            class="custom-item"
            :class="{
              'custom-item-active': title.isEdit,
              'showTree': title.treeShow,
              'not-tree': !title.isHasTree
            }"
            v-for="(title,index) in titleList"
            :key="'index' + index"
        >
          <div class="head" @click="title.isHasTree ? title.treeShow = !title.treeShow : null">
            <div class="item-left">
              <i
                  class="icon-tree iconfont icon_arrow_right"
                  :class="{
                  'show': title.treeShow
                }"
              ></i>
              <!--     暂时注释掉显示与隐藏的操作       -->
              <!--            @click.stop="showTitle(title)"  -->
              <div class="name-div" v-if="!title.isEdit" >
                <!--              <span class="left-icon-span">-->
                <!--                <i-->
                <!--                    class="iconfont"-->
                <!--                    :class="{-->
                <!--                      'btn_nor2': !title.IsShow,-->
                <!--                      'btn_sel2': title.IsShow-->
                <!--                    }"-->
                <!--                ></i>-->
                <!--              </span>-->
                <span class="title-span">{{ title.Name }}</span>
              </div>
              <el-input
                  v-else
                  size="mini"
                  v-model="title.Name"
                  maxlength="6"
                  class="con-input"
                  @keydown.enter.native="setHeader(title,'titleName')"
                  show-word-limit
              ></el-input>
            </div>
            <div class="right">
              <el-switch
                  v-if="!title.isEdit"
                  v-model="title.IsShow"
                  @change="changeSwitch(title)"
              ></el-switch>
              <div class="item-right">
                <a v-if="title.isEdit" @click.stop="cancelEdit(title)">取消</a>
                <a v-if="title.isEdit" @click.stop="setHeader(title,'titleName')">确定</a>
                <ConIcon
                    icon="icon_edit"
                    v-if="!title.isEdit"
                    size="min-icon"
                    @click="title.isEdit = !title.isEdit;"
                ></ConIcon>
              </div>
              <i class="iconfont paixu" v-if="!title.isEdit"></i>
            </div>
          </div>
          <div class="junior-box" v-if="title.setObj">
            <!--      列表      -->
            <div class="set-item" v-if="title.setObj.list">
              {{title.setObj.list.name1}}
              <el-select v-model="title.listVal" class="con-input" @change="changeSet(title)">
                <el-option
                  v-for="(item,idx) in title.setObj.list.option"
                  :key="'list-'+idx + '-' + index"
                  :label="item.name"
                  :value="item.val"
                ></el-option>
              </el-select>
              {{title.setObj.list.name2}}
            </div>
            <!--      详情      -->
            <div class="set-item" v-if="title.setObj.details">
              {{title.setObj.details.name1}}
              <el-select v-model="title.detailsVal" class="con-input" @change="changeSet(title)">
                <el-option
                    v-for="(item,idx) in title.setObj.details.option"
                    :key="'list-'+idx + '-' + index"
                    :label="item.name"
                    :value="item.val"
                ></el-option>
              </el-select>
              {{title.setObj.details.name2}}
            </div>
          </div>
        </div>
      </draggable>
    </main>
  </ConDig>
</template>

<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";
import headerJumpSetting from "@/assets/js/config/header-jump-setting";
export default {
  data() {
    return {
      loading: true,
      titleList: [],
      isChange: false,
      headerJumpSetting: headerJumpSetting,
    };
  },
  props: {
    isShowCustom: {
      default: false,
    },
  },
  computed: {
    ...mapState({
      authList: (state) => state.authList,
    }),
  },
  created() {},
  mounted() {},
  methods: {
    changeSwitch(val) {
      if (
          this.titleList.filter(c => !c.IsShow).length === this.titleList.length &&
          !val.IsShow
      ) {
        val.IsShow = true;
        this.$message('至少显示一个导航菜单');
        return void 0;
      }
      this.setHeader(val,'show');
    },
    //排序
    change(evt) {
      let changeId = evt.moved.element.Id;
      let firstId = 0;
      let secondId = 0;
      if (evt.moved.newIndex > 0) {
        firstId = this.titleList[evt.moved.newIndex - 1].Id;
      }
      if (evt.moved.newIndex < this.titleList.length - 1) {
        secondId = this.titleList[evt.moved.newIndex + 1].Id;
      }
      this.$axios({
        method: "post",
        url: "NewWeddingApi/OrderManage/HeaderManage/HeaderDetail/SortHeader",
        data: {
          ChangeId: changeId,
          FirstId: firstId,
          SecondId: secondId,
        },
        success(res) {
          if (res.data.status) {
            this.isChange = true;
          } else {
            this.$Error(res.data.msg ? res.data.msg : "排序失败");
          }
          this.getHeader();
        },
        error(err) {
          this.getHeader();
          this.$Error("获取失败");
          console.log(err);
        },
      });
    },
    showTitle(item) {
      item.IsShow = !item.IsShow;
      this.setHeader(item,'show');
    },
    async initData() {
      this.isChange = false;
      this.loading = true;
      await this.getUserAuth(1);
      this.getHeader();
    },
    /**
     * 获取用户权限
     */
    getUserAuth(type = 1) {
      return new Promise((resolve, reject) => {
        this.$axios({
          url: "NewWeddingApi/SystemModule/Module/Module/GetHomeModuleTreeNeedChild",
          method: "POST",
          data: {
            moduleId: type === 1 ? "" : 0,
            category: type,
          },
          success(res) {
            if (res.data.status) {
              const authArr = res.data.data ? res.data.data : [];
              const authList = this.$store.state.authList || [];
              let authList1 = [];
              let authList2 = [];
              if (authArr.length >= authList.length) {
                authList1 = this.$Clone(authArr);
                authList2 = this.$Clone(authList);
              } else {
                authList1 = this.$Clone(authList);
                authList2 = this.$Clone(authArr);
              }
              let list = [];
              authList1.forEach(c => {
                c.exist = !!authList2.find(
                    (a) => a.value.toLowerCase() === c.value.toLowerCase()
                );
              })
              list = authList1.filter((t) => t.exist);
              if (list.length !== authList.length || authArr.length !== authList.length) this.isChange = true;
              if (type === 1) this.$store.state.authList = res.data.data;
              resolve(res.data.data);
            } else {
              reject();
            }
          },
          error() {
            reject();
          },
        });
      });
    },
    getHeader() {
      this.$axios({
        method: "post",
        url: "NewWeddingApi/OrderManage/HeaderManage/HeaderDetail/GetHeader",
        data: {
          Type: 5,
        },
        success(res) {
          this.loading = false;
          if (res.data.status) {
            let titleList = res.data.data;
            let authList = this.authList || [];
            titleList.forEach((title,index) => {
              title.isHasTree = title.Key !== 'company' && title.Key !== "discover" && title.Key !== "inspira";
              title.treeShow = this.titleList[index] ? this.titleList[index].treeShow : false;
              title.isEdit = this.titleList[index] ? this.titleList[index].isEdit : false;
              title.oldName = title.Name;
              title.isAuth = !!authList.find(
                  (a) => a.value.toLowerCase() === title.Key.toLowerCase()
              );
              if (headerJumpSetting[title.Key]) {
                title.setObj = headerJumpSetting[title.Key];
              }
              // 跳转设置
              let Content = null;
              try {
                Content = JSON.parse(title.Content)
              } catch (err) {
              }
              const isJson = Object.prototype.toString.call(Content) === '[object Object]';
              title.listVal = isJson && Content && Content.listVal || '';
              title.detailsVal = isJson && Content && Content.detailsVal || '';
            });
            this.titleList = titleList.filter((t) => t.isAuth);
            this.$store.state.headList = this.$Clone(this.titleList.filter((t) => t.IsShow));
            sessionStorage.setItem("insercell", JSON.stringify(this.$store.state));
          } else {
            this.$Error(res.data.msg ? res.data.msg : "获取失败");
          }
        },
        error(err) {
          this.loading = false;
          this.$Error("获取失败");
          console.log(err);
        },
      });
    },
    /**
     * 设置标题
     * title
     * type ： titleName 设置title名字，show 设置是否显示
     */
    setHeader(title,type) {
      if (type === 'titleName') {
        if (!title || !title.Name) {
          this.$Message("标题不能为空");
          return;
        }
      }

      this.$axios({
        method: "post",
        url: "NewWeddingApi/OrderManage/HeaderManage/HeaderDetail/SetHeader",
        data: {
          HeaderId: title.Id,
          IniName: title.Name,
          IsShow: title.IsShow,
        },
        success(res) {
          if (res.data.status) {
            this.isChange = true;
            this.$Success("修改成功");
            if (type === 'titleName') {
              title.oldName = title.Name;
              title.isEdit = false;
            }
          } else {
            this.$Error(res.data.msg ? res.data.msg : "操作失败");
          }
          this.getHeader();
        },
        error(err) {
          this.getHeader();
          this.$Error("操作失败");
          console.log(err);
        },
      });
    },
    // 导航栏默认设置信息
    changeSet(title) {
      let data = {
        listVal: title.listVal,
        detailsVal: title.detailsVal,
      };
      this.$axios({
        method: "post",
        url: "NewWeddingApi/OrderManage/HeaderManage/HeaderDetail/SaveHeaderContent",
        data: {
          Id: title.Id,
          Content: JSON.stringify(data),
        },
        success(res) {
          if (res.data.status) {
            this.isChange = true;
            this.$Success("修改成功");

          } else {
            this.$Error(res.data.msg ? res.data.msg : "操作失败");
          }
          this.getHeader();
        },
        error(err) {
          this.getHeader();
          this.$Error("操作失败");
          console.log(err);
        },
      });
    },
    /**
     * 取消编辑
     */
    cancelEdit(title) {
      title.Name = title.oldName;
      title.isEdit = false;
    },
    closeCustomDig() {
      if (
          this.titleList.length > 0 &&
          this.titleList.filter((t) => t.IsShow).length <= 0
      ) {
        this.$message('至少显示一个导航菜单');
        return void 0;
      }
      if (this.isChange) {
        this.$bus.$emit("customChange");
        this.$emit("Success");
      }
      this.$emit("closeCustomDig");
    },
  },
  components: {
    draggable
  }
};
</script>

<style scoped lang="scss">
.custom-title-main {
  //padding: 24px 16px;
  padding: 24px 0 0 16px;
  margin-bottom: 10px;
  height: 360px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  .custom-item {
    width: 442px;
    .head {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 8px;
      box-sizing: border-box;
      background: #fff;
      @include themify($themes) {
        background: themed("main-bg");
      }
    }

    &:hover {
      .head {
        background: #eeeeee;
        @include themify($themes) {
          background: themed("search-his-bg-h");
        }
      }
      .item-right {
        opacity: 1!important;
      }
    }
    &.showTree .junior-box {
      display: block;
    }
    &.not-tree .icon-tree {
      opacity: 0!important;
    }
    .junior-box {
      display: none;
      transition: 0.3s;
      padding: 0 36px;
      overflow: hidden;
      background: #eeeeee;
      @include themify($themes) {
        background: themed("search-his-bg-h");
      }
      .set-item {
        color: #666666;
        margin: 8px 0;
        @include themify($themes) {
          color: themed("set-t-color");
        }
        ::v-deep .el-input__inner {
          width: 120px;
          height: 28px;
          line-height: 26px;
          background: #FFFFFF;
          border: 1px solid #ddd;
          border-radius: 0!important;
          color: #666;
          font-weight: 400;
          font-family: PingFangSC, PingFang SC;
          padding: 0 8px;
          @include themify($themes) {
            color: themed("set-t-color");
            background: themed('add-item-bg');
          }
        }
        ::v-deep .el-input__icon {
          line-height: 28px;
        }
      }
    }
    .item-left {
      display: flex;
      align-items: center;
      .icon-tree {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        font-size: 20px;
        color: #333;
        margin-right: 4px;
        cursor: pointer;
        transition: 0.3s;
        opacity: 1;
        @include themify($themes) {
          color: themed("font-color-active2");
        }
        &.show {
          opacity: 1;
          transform: rotate(90deg);
        }
      }
      .name-div {
        cursor: pointer;
      }
      .left-icon-span {
        display: inline-block;
        font-size: 16px;
        color: #DFDCDF;
        margin-right: 5px;
        .btn_sel2 {
          color: #333;
          @include themify($themes) {
            color: themed("font-color-active2");
          }
        }
      }
      .title-span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        @include themify($themes) {
          color: themed("input-color");
        }
        line-height: 20px;
      }
      .el-input {
        width: 140px;
        ::v-deep .el-input__inner {
          padding-left: 8px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
          @include themify($themes) {
            color: themed("input-color");
          }
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
    }
    .iconfont.paixu {
      font-size: 24px;
      color: #999;
      cursor: pointer;
      display: inline-block;
      text-align: center;
      width: 32px;
      height: 32px;
      line-height: 32px;
      &:hover {
        color: #222;
        @include themify($themes) {
          color: themed("input-color");
          background: themed('hover-bg');
        }
      }
    }
    .item-right {
      display: flex;
      align-items: center;
      //opacity: 0;
      a {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 20px;
        user-select: none;
        cursor: pointer;
        margin-left: 16px;
        @include themify($themes) {
          color: themed("input-color");
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .custom-item-active {
    background: #eeeeee;
    @include themify($themes) {
      background: themed("search-his-bg-h");
    }
    .item-right {
      //opacity: 1;
    }
  }
}
.custom-item .el-switch {
  width: 36px;
  height: 20px;
  background: #222222;
  border-radius: 12px;
  margin-right: 10px;
}

p.tips {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  margin-bottom: 16px;
}
.div-nodata {
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
  font-size: 24px;
}
</style>
<style scoped lang="scss">
.custom-item ::v-deep .el-switch.is-checked .el-switch__core {
  background: #222222;
  border-color: #222222;
  @include themify($themes) {
    background: themed('color-gou1');
    border-color: themed('color-gou1');
  }
}
.custom-item >>> .el-switch .el-switch__core {
  background: #dddddd;
  border-color: #dddddd;
}
</style>
