<!--
 * @Author: tangshuo
 * @Date: 2021-10-09 11:57:09
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-10-14 11:08:50
 * @Remarks: 品牌设置
-->
<template>
  <ConDig
    title="品牌设置"
    width="480px"
    :isShowDig="isShowSet"
    :loading="isLodding"
    :appendToBody="true"
    confirmText="保存"
    @open="initData"
    @closed="isShowSet = false"
    @closeDig="closeSetDig"
    @cancelClick="closeSetDig"
    @confirmClick="confirmClick"
  >
    <main slot="dialogMain" class="set-branch-main">
      <span class="tip-title">品牌logo</span>
      <div
        class="logo-box"
        :class="{ 'logo-box-none': !logoUrl }"
        @click="!logoUrl ? openLogoFileDig() : ''"
      >
        <div class="logo-item" v-if="!!logoUrl">
          <el-image :src="logoUrl" fit="cover"></el-image>
          <div class="img-icons">
            <ConIcon
              icon="icon_add3"
              tipText="重新上传"
              @click="openLogoFileDig"
            ></ConIcon>
            <ConIcon
              icon="icon_cut"
              tipText="裁剪logo"
              @click="coverImg=logoUrl;showTailoringDig=true"
            ></ConIcon>
          </div>
        </div>
        <i v-else class="iconfont icon_add2 add-icon"></i>
      </div>
      <span class="tip-title">品牌名称</span>
      <ConInput
        v-model="branchName"
        maxlength="16"
        placeholder="请输入品牌名称"
        :showWordLimit="true"
      ></ConInput>
      <input
        type="file"
        id="logoFile"
        ref="logoFile"
        accept="image/png, image/jpeg"
        style="opacity: 0; width: 0; height: 0; position: absolute"
        @change="selLogoImg"
      />
      <TailoringCoverDig
        ref="tailoringDig"
        :isShowTailor="showTailoringDig"
        :autoCropWidth="200"
        :autoCropHeight="200"
        :imgSrc="coverImg"
        :fixedNumber="[1, 1]"
        :fixed="false"
        :uncompressed="true"
        :logoUpload="true"
        :centerBox="false"
        @uploadCallback="uploadCallback"
        :pathKey="branchId + '/' + 'LOGO/'"
        @closeTailorDig="
          coverImg = '';
          showTailoringDig = false;
        "
      ></TailoringCoverDig>
    </main>
  </ConDig>
</template>

<script>
import { getBranchBaseInfo, updateBranchLogoInfo } from "@/api/base";
import TailoringCoverDig from "@/components/tailoring/tailoringCoverDig";
export default {
  data() {
    return {
      isLodding: false,
      branchName: "",
      branchId: "",
      logoUrl: "",
      logoKey: "",
      coverImg: "", //需要裁剪处理路径
      branchInfo: {},
      isChange: false, //信息是否修改
      showTailoringDig: false, //是否显示裁剪弹窗
      cosIp: sessionStorage.getItem("BranchCosCdnUrl_B"),
    };
  },
  props: {
    isShowSet: {
      default: false,
    },
  },
  components: {
    TailoringCoverDig,
  },
  created() {},
  mounted() {},
  methods: {
    initData() {
      this.isChange = false;
      this.getBranchBaseInfo();
    },
    /**
     * 打开logo文件选择框
     */
    openLogoFileDig() {
      $("#logoFile").val(""); //赋值为'',防止选择同一文件不触发change事件
      $("#logoFile").trigger("click");
    },
    /**
     * 选择logo
     */
    selLogoImg() {
      let file = document.getElementById("logoFile").files[0];
      let url = this.$getObjectURL(file);
      this.coverImg = url;
      this.showTailoringDig = true;
    },
    /**
     * 获取店铺信息
     */
    getBranchBaseInfo() {
      getBranchBaseInfo()
        .then((res) => {
          if (res.status) {
            this.branchInfo = res.data || {};
          } else {
            this.$Error("获取店铺信息失败!");
          }
          if (this.branchInfo) {
            this.branchId = this.branchInfo.BranchId;
            this.branchName = this.branchInfo.BranchName;
            this.logoUrl = this.cosIp + this.branchInfo.BranchLogoUrl;
            this.logoKey = this.branchInfo.BranchLogoUrl;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Error("获取店铺信息失败!");
        });
    },
    /**
     * 图片上传/裁剪完成
     */
    uploadCallback(url) {
      this.logoUrl = this.cosIp + url;
      this.logoKey = url;
      this.showTailoringDig = false;
    },
    /**
     * 保存信息
     */
    confirmClick() {
      if (!this.branchName) {
        this.$Message("品牌名称不能为空");
        return;
      }
      this.isLodding = true;
      updateBranchLogoInfo({
        LogoUrl: this.logoKey,
        BranchName: this.branchName,
      })
        .then((res) => {
          this.isLodding = false;
          if (res.status) {
            this.isChange = true;
            this.$Success("操作成功");
            this.$bus.$emit('baseInfoChange')
            this.closeSetDig();
          } else {
            this.$Error(res.msg ? res.msg : "修改店铺信息失败");
          }
        })
        .catch((err) => {
          this.isLodding = false;
          console.log(err);
          this.$Error("修改店铺信息失败");
        });
    },
    closeSetDig() {
      this.$emit("closeSetDig",this.isChange);
      setTimeout(() => {
        if (this.branchInfo) {
          this.branchId = this.branchInfo.BranchId;
          this.branchName = this.branchInfo.BranchName;
          this.logoUrl = this.cosIp + this.branchInfo.BranchLogoUrl;
          this.logoKey = this.branchInfo.BranchLogoUrl;
        }
      }, 400);
    },
  },
};
</script>

<style scoped lang="scss">
.set-branch-main {
  padding: 24px 24px 0;
  .tip-title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 20px;
    margin-bottom: 8px;
    display: inline-block;
  }
  .logo-box {
    width: 160px;
    height: 160px;
    background: #ffffff;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    .logo-item {
      width: 100%;
      height: 100%;
      position: relative;
      .el-image {
        width: 100%;
        height: 100%;
      }
      .img-icons {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 160px;
        height: 60px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.5) 100%
        );
        .iconfont {
          color: #ffffff;
          &:hover {
            background: rgba($color: #ffffff, $alpha: 0.2);
          }
        }
      }
    }
    .add-icon {
      font-size: 24px;
      color: #ddd;
    }
  }
  .logo-box-none {
    border: 2px #ddd dashed;
    cursor: pointer;
  }
}
</style>
