<!--
 * @Author: tangshuo
 * @Date: 2021-01-21 09:34:13
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-01-28 17:37:47
 * @Remarks: 
-->
<template>
  <el-button :loading="loading" :class="btnClass" @click="$emit('click')">{{
    title
  }}</el-button>
</template>
<script>
export default {
  name: "con-button",
  props: {
    type: {
      //按钮类型 con默认 err错误 cancel取消
      type: String,
      default: "con",
    },
    title: {
      //按钮文字
      type: String,
      default: "",
    },
    loading: {
      //按钮是否加载中
      type: Boolean,
      default: false,
    },
  },
  computed: {
    btnClass() {
      switch (this.type) {
        case "err":
          {
            return "err-button";
          }
          break;
        case "cancel":
          {
            return "cancel-button";
          }
          break;
        default:
          {
            return "con-button";
          }
          break;
      }
    },
  },
  components: {},

  methods: {},
};
</script>
<style lang="scss">
</style>