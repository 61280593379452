<template>
  <div>
    <ConDig
        class="set-sequence-dig-box"
        title="新增/推送案例默认顺序设置"
        width="480px"
        :isShowDig="isShowSequence"
        :appendToBody="true"
        :confirmText="'保存'"
        :clickModal="false"
        :pressEescape="false"
        @open="initData"
        @closed="$emit('closeCustomDig')"
        @closeDig="$emit('closeCustomDig')"
        @cancelClick="$emit('closeCustomDig')"
        @confirmClick="confirmClick"

    >
      <main slot="dialogMain" class="custom-title-main" v-loading="isLoading">
        <div
            class="row-info"
            v-for="(item,index) in list"
            :key="item.type + '_' + index"
        >
          <p class="text">{{item.name}}</p>
          <el-popover
              popper-class="option-popover-w"
              placement="bottom"
              width="74px"
              trigger="click"
              :visible-arrow="false"
          >
            <div class="select-div" slot="reference">
              <span
                  :class="{
                    'no-data': getSit(item.type) === '请选择'
                  }"
              >{{getSit(item.type)}}</span>
              <i class="iconfont icon_arrow_down"></i>
            </div>
            <div class="option-div">
              <div class="option-item" @click="selHandler(item, 'first')">首 <i class="iconfont icon_gou2" v-if="getSit(item.type) == '首'"></i></div>
              <div class="option-item" @click="selHandler(item, 'last')">末 <i class="iconfont icon_gou2" v-if="getSit(item.type) == '末'"></i></div>
              <div class="option-item" @click="selHandler(item, 'custom')">自定义 <i class="iconfont icon_arrow_right"></i></div>
            </div>
          </el-popover>
          <p class="text">位</p>
        </div>
      </main>
    </ConDig>
    <con-dig
        class="set-sequence-dig-box"
        title="自定义顺序"
        width="480px"
        :is-show-dig="showCustomDig"
        :appendToBody="true"
        confirmText="确定"
        @closeDig="showCustomDig = false"
        @cancelClick="showCustomDig = false"
        @confirmClick="confirmClick('custom')"
    >
      <main slot="dialogMain">
        <el-input class="custom-sequence" @input="pageIndexInput" v-model="customSequence" type="number" placeholder="请输入数字"></el-input>
      </main>
    </con-dig>
  </div>
</template>

<script>
export default {
  props: {
    isShowSequence: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      BaseInsNum: 0,// 移动端新增案例置位
      BasePushNum: 0,//移动端推送PC置位
      PcInsNum: 0,//PC端新增置位
      PcPushNum: 0,//PC端推送移动端置位
      hostAuthStr: sessionStorage.getItem("hostAuthStr"),
      isLoading: true,
      list: [{
        name: '移动端新增案例默认置于第',
        type: 'BaseInsNum',
      }, {
        name: '移动端推送案例到PC端默认置于第',
        type: 'BasePushNum',
      }, {
        name: 'PC端新增案例默认置于第',
        type: 'PcInsNum',
      }, {
        name: 'PC端推送案例到移动端默认置于第',
        type: 'PcPushNum',
      }],
      showCustomDig: false,
      customSequence: '',
      customSequenceType: '',
    }
  },
  methods: {
    initData() {
      this.$axios({
        url: `${process.env.VUE_APP_TEAMURL}xlapi/HostManage/HostUserManage/HostUser/GetOtherInfo`,
        method: "GET",
        authorizationStr: this.hostAuthStr,
        success(res) {
          this.isLoading = false
          if (res && res.data.status) {
            this.BaseInsNum = res.data.data.BaseInsNum || 0
            this.BasePushNum = res.data.data.BasePushNum || 0
            this.PcInsNum = res.data.data.PcInsNum || 0
            this.PcPushNum = res.data.data.PcPushNum || 0
          } else {
            this.$Error(res.data.msg ? res.data.msg : "操作失败");
          }
        },
        error(err) {
          this.isLoading = false
          this.$Error("操作失败");
        },
      });
    },
    pageIndexInput(val) {
      if (val === '') return this.customSequence = '';
      val = val.replace(/^(0+)|[^\d]+/g,'');
      this.customSequence = val ? val : '';
      if (val <= 0) this.customSequence = 1;
    },
    selHandler(item,type) {
      let val = 1;
      if (type === 'first') val = 1;
      if (type === 'last') val = -1;
      if (type === 'custom') {
        this.customSequence = this[item.type] == -1 ? '' : this[item.type];
        this.customSequenceType = item.type;
        this.showCustomDig = true;
        return
      }
      switch (item.type) {
        case 'BaseInsNum': this.BaseInsNum = val;break;
        case 'BasePushNum': this.BasePushNum = val;break;
        case 'PcInsNum': this.PcInsNum = val;break;
        case 'PcPushNum': this.PcPushNum = val;break;
      }
    },
    getSit(type) {
      let val = 1;
      switch (type) {
        case 'BaseInsNum': val = this.BaseInsNum;break;
        case 'BasePushNum': val = this.BasePushNum;break;
        case 'PcInsNum': val = this.PcInsNum;break;
        case 'PcPushNum': val = this.PcPushNum;break;
      }
      if (val === "" || val === undefined || val === null) return '请选择';
      return val === -1 ? '末' : val === 1 ? '首' : val;
    },
    closeCustomDig() {//取消
      this.$emit('closeCustomDig')
    },
    confirmClick(type) {//保存
      if (type && type === 'custom') {
        if (!this.customSequence) {
          this.$Error("请填写正确位置");
          return
        }
        if (Number(this.customSequence) <= 0 || !(this.customSequence % 1 == 0)) {
          this.$Error("请填写正确的正整数");
          return
        }
        this[this.customSequenceType] = this.customSequence;
        this.showCustomDig = false;
        return;
      }

      if (!this.BaseInsNum || !this.BasePushNum || !this.PcInsNum || !this.PcPushNum) {
        this.$Error("请填写正确位置");
        return
      }
      // if (Number(this.BaseInsNum) <= 0 || !(this.BaseInsNum % 1 == 0)) {
      //   this.$Error("请填写正确的正整数");
      //   return
      // }
      // if (Number(this.BasePushNum) <= 0 || !(this.BasePushNum % 1 == 0)) {
      //   this.$Error("请填写正确的正整数");
      //   return
      // }
      // if (Number(this.PcInsNum) <= 0 || !(this.PcInsNum % 1 == 0)) {
      //   this.$Error("请填写正确的正整数");
      //   return
      // }
      // if (Number(this.PcPushNum) <= 0 || !(this.PcPushNum % 1 == 0)) {
      //   this.$Error("请填写正确的正整数");
      //   return
      // }
      this.$axios({
        url: `${process.env.VUE_APP_TEAMURL}xlapi/HostManage/HostUserManage/HostUser/SaveNumSetting`,
        method: "POST",
        authorizationStr: this.hostAuthStr,
        data: {
          BaseInsNum: this.BaseInsNum,
          BasePushNum: this.BasePushNum,
          PcInsNum: this.PcInsNum,
          PcPushNum: this.PcPushNum
        },
        success: res => {
          if (res && res.data.status) {
            this.$Success("操作成功");
            this.closeCustomDig()
          } else {
            this.$Error(res.data.msg ? res.data.msg : "操作失败");
          }
        },
        error(err) {
          this.$Error("操作失败");
        },
      });
    }
  }
}
</script>

<style scoped lang="scss">
.custom-title-main {
  padding: 22px 24px;
}

.row-info {
  margin-bottom: 16px;

  .text {
    color: #222;
  }
}

.row-info ::v-deep .el-input {
  width: 64px;
  display: inline-block;
  margin: 0 4px;

  .el-input__inner {
    padding: 0 8px;
    border-radius: 0;
  }
}

.text {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222;
  display: inline-block;
}

.set-sequence-dig-box.el-dialog__wrapper ::v-deep .el-dialog {
  background: #fff !important;

  .el-dialog__header {
    background: #F5F5F5;

    .el-dialog__title {
      color: #222;
    }

    .el-dialog__headerbtn:hover .el-dialog__close {
      background: #e6e6e6;
      color: #222222;
    }
  }

  .el-dialog__body {
    background: #fff;
  }

  .el-dialog__footer {
    background: #fff;

    .cancel-button {
      background: #ffffff;
      color: #666666;
      border-color: #eeeeee;
    }

    .con-button {
      background: #444444;
      color: #ffffff;
      border-color: #eeeeee;

      &:hover {
        background: #222222;
        color: #ffffff;
        border-color: #eeeeee;
      }
    }
  }
}
.row-info {
  display: flex;
  align-items: center;
}
.select-div {
  width: 74px;
  height: 28px;
  line-height: 28px;
  background: #F5F5F5;
  padding: 0 8px;
  box-sizing: border-box;
  margin: 0 4px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #222;
  .icon_arrow_down {
    color: #A5A5A5;
    font-size: 20px;
    position: absolute;
    right: 4px;
    top: 0;
  }
}
.option-div {
  width: 74px;
  .option-item {
    width: 100%;
    height: 36px;
    line-height: 36px;
    position: relative;
    padding: 0 8px;
    box-sizing: border-box;
    color: #666;
    cursor: pointer;
    &:hover {
      background: #F5F5F5;
    }
    i {
      position: absolute;
      right: 8px;
      top: 0;
      font-size: 20px;
      color: #222;
    }
    .icon_arrow_right {
      color: #999;
      right: 4px;
      font-size: 18px;
    }
  }
}
.custom-sequence {
  margin: 24px;
  width: calc(100% - 48px);
}
.set-sequence-dig-box ::v-deep .el-loading-mask {
  background-color: rgba(255, 255, 255, 0.9);
}
.set-sequence-dig-box ::v-deep .el-loading-mask .el-loading-spinner .path {
  stroke: #333333;
}
</style>
<style>
body .option-popover-w {
  min-width: 74px;
  padding: 0;
}
</style>
