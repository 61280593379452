<template>
  <el-dialog
      :append-to-body="true"
      :close-on-press-escape="false"
      class="calendarbox"
      width="1200px"
      :visible="true"
      :before-close="handleClose"
      v-loading="!iframeCalendar"
  >
    <div slot="title" class="calendarbox-heads">
      <ul class="el-dropdown-link" >
        <li
            v-for="item in dropdownList"
            :key="item.dropdownType"
            :class="{'dropdown-active':item.active}"
            class="flex"
            @click="handlerSetdropdownType( item )"
        >
          {{ item.menuName }}
        </li>
        <i class="backdrop-item" :style="{transform:`translateX(${ dropdownType * 80 }px)`}"></i>
      </ul>
      <i class="iconfont iconicon_shezhi" v-if="isSetwinConfig"  @click="isTools=!isTools"></i>
    </div>
    <iframe
        width="100%"
        class="iframe-calendar"
        height="800px"
        id="iframeCalendar"
        :src="iframeSrc"
        v-if="iframeSrc"
    ></iframe>
  </el-dialog>
</template>

<script>
export default {
  name: "calendarbox",
  props:{
    iframeSrc:{
      type:String,
      default:null
    }
  },
  data(){
    return {
      dropdownList:[
        {menuName:"月视图",dropdownType:0,active:true,index:0},
        {menuName:"订单视图",dropdownType:1,active:false,index:1},
        {menuName:"列表模式",dropdownType:2,active:false,index:0}
      ],
      iframeCalendar:true,
      dropdownType:0,
      isTools:false,
      isSetwinConfig:false
    }
  },
  methods:{
    //是否存在全局设置
    handlerSetwinConfig( isSetwinConfig ){
      this.isSetwinConfig = isSetwinConfig;
    },
    handlerSetdropdownType( item ){
      this.iframeCalendar = false;
      this.dropdownType = item.dropdownType;
      this.dropdownList.forEach( it => it.dropdownType === item.dropdownType ? it.active = true : it.active = false);
      let config = {
        isTools:this.isTools,
        dropdownType:this.dropdownType,
      }
        try {
          document
              .getElementById("iframeCalendar")
              .contentWindow.handlerDefultApi(config);
          this.$forceUpdate();
        } catch (e) {
          console.log(e);
        }
      this.iframeCalendar = true;
    },
    handleClose(){
      this.$emit("close")
    }
  },
  mounted() {
    window.handlerSetwinConfig = this.handlerSetwinConfig
  }
  // components:{ calendar }
}
</script>

<style scoped>
.iframe-calendar {
  border: none;
}
.calendarbox-heads{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iconicon_shezhi{
  position: absolute;
  top: 19px;
  right: 52px;
  font-size: 20px;
  color: #909399;
  cursor: pointer;
}
>>> .el-dialog__body{
  padding: 16px 24px 30px ;
  background: #fff;
}
.backdrop-item{
  display: block;
  width: 80px;
  height: 85%;
  background: #FFFFFF;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  position: absolute;
  transition: all .35s ease-out;
  z-index: 1;
}
.dropdown-active{
  font-size: 14px !important;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500 !important;
  color: #333333 !important;
}
.calendarbox >>> .el-dropdown-selfdefine{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.calendarbox >>>.el-icon-arrow-down:before{
  display: block;
  content: '';
  width: 16px;
  height: 16px;
  /*background: url("../../../projects/projectA/assets/image/switch.png") no-repeat center;*/
  background-size: contain;
  position: relative;
  left: 5px;
}
.el-dropdown-link{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 244px;
  height: 30px;
  background: #E6E7EB;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 2px;
  position: relative;
}
.el-dropdown-link li{
  width: 100%;
  cursor: pointer;
  flex: 1;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666F80;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendarbox >>> .el-dialog{
  margin: auto !important;
}
.calendarbox >>> .el-dialog__header{
  background: #FAFBFC;
  border-bottom: 1px solid #F0F2F5;
  border-radius: 4px 4px 0 0 ;
  padding: 20px;
}
.calendarbox >>>.el-dialog{
  border-radius: 4px ;
}
.calendarbox >>> .el-icon-close{
  font-size: 21px;
  font-weight: bold;
}
</style>
