<!--
 * @Author: tangshuo
 * @Date: 2021-10-13 14:38:41
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-10-13 15:52:19
 * @Remarks:
-->
<template>
  <ConDig
    title="采集到资料夹"
    width="446px"
    :modal="true"
    :isShowDig="showAddFolder"
    @closeDig="handleClose"
    @open="initData"
    :appendToBody="true"
    @cancelClick="handleClose"
    @confirmClick="addFolder"
  >
    <main slot="dialogMain" class="add-folder-main">
      <el-image
        class="folder-cover"
        :class="{ 'folder-team': addParam && addParam.type == 4 }"
        :src="addParam.coverPath ? addParam.coverPath : 'images/home/no1.png'"
        fit="cover"
      ></el-image>
      <el-input
        v-model="folderRemark"
        placeholder="输入备注信息"
        class="folder-remark con-input"
        type="textarea"
        :autosize="{ minRows: 7, maxRows: 7 }"
        resize="none"
      ></el-input>
    </main>
  </ConDig>
</template>

<script>
import { mapActions, mapState } from "vuex";
import folder from "@/mixins/folder/folder";
export default {
  mixins: [
    folder
  ],
  data() {
    return {
      isEdit: false,
      isLoading: false,
      folderRemark: "",
      baseUserId: sessionStorage.getItem("baseUserId"),
      hostAuthStr: sessionStorage.getItem("hostAuthStr"), //金刚系统所需请求头
    };
  },
  props: {
    showAddFolder: {
      default: false,
    },
  },
  computed: {
    ...mapState({
      addParam: (state) => state.addParam,
    }),
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions({
      getFolderApi: "getFolderApi",
    }),
    initData() {
      this.isLoading = false;
      if (this.addParam.id) {
        //编辑
        this.isEdit = true;
        this.folderRemark = this.addParam.remark;
      } else {
        this.isEdit = false;
        this.folderRemark = "";
      }

	  this.baseUserId= sessionStorage.getItem("baseUserId")
	  this.hostAuthStr= sessionStorage.getItem("hostAuthStr") //金刚系统所需请求头
    },
    /**
     * 监听键盘事件
     */
    keydown() {
      let key = window.event.keyCode;
      if (key == 9) {
        this.handleClose();
      }
    },
    /**
     * 添加到资料夹
     */
    async addFolder() {


      if (!this.hostAuthStr) {
        try {
          await this.getHostHead(); // 获取请求头
        } catch (error) {
          console.log(error, 'getHostHead');
        }
      }

      try {
        await this.getFolderContent(); // 只获取了资料夹总数量 没有获取资料夹具体数据
      } catch (error) {
        console.log(error, 'getFolderApi');
      }

      const isFolderObj = this.switchExistsFolder();
      if (isFolderObj.isExists) {
        this.$Error(`该${isFolderObj.name}已存在资料夹中`);
        return;
      }

      if (this.isLoading) {
        return;
      }

      let addParam = {
        Id: this.addParam.id ? this.addParam.id : "",
        Type: this.addParam.type ? this.addParam.type : "",
        Source: this.addParam.source ? this.addParam.source : "",
        Works: this.addParam.works ? this.addParam.works : "",
        Remark: this.folderRemark,
        FromBaseUserId: this.addParam.fromBaseUserId
          ? this.addParam.fromBaseUserId
          : this.baseUserId,
        FromSource: this.addParam.fromSource ? this.addParam.fromSource : "",
        Url: this.addParam.url ? this.addParam.url : "",
        Pid: this.addParam.pid ? this.addParam.pid : "",
      };
      this.isLoading = true;
      this.$axios({
        url:
          process.env.VUE_APP_TEAMURL +
          "xlapi/HostManage/ShareWork/Folder/FolderIn",
        method: "POST",
        authorizationStr: this.hostAuthStr,
        data: addParam,
        success(res) {
          this.isLoading = false;
          if (res.data.status) {
            this.$Success(res.data.msg ? res.data.msg : "操作成功");
            this.getFolderApi(this.hostAuthStr);
            this.handleClose();
          } else {
            this.$Error(res.data.msg ? res.data.msg : "采集到资料夹失败");
          }
        },
        error() {
          this.isLoading = false;
          this.$Error("采集到资料夹失败");
        },
      });
    },
    /**
     * 获取金刚所需请求头
     */
    getHostHead() {
      return new Promise((resolve) => {
        this.$axios({
          url:
            process.env.VUE_APP_TEAMURL +
            "xlapi/HostManage/HostUserManage/HostUserLogin/GetHostUserIdentity",
          method: "POST",
          data: {
            BranchId: sessionStorage.getItem("BranchId"),
            BranchSource: "lh",
            userid: sessionStorage.getItem("UserId"),
          },
          success(res) {
            if (res.data.AuthorizationStr) {
              this.hostAuthStr = res.data.AuthorizationStr;
              let baseUserId = this.hostAuthStr.split("|")[0];
              sessionStorage.setItem("hostAuthStr", this.hostAuthStr);
              sessionStorage.setItem("baseUserId", baseUserId);
              this.baseUserId = baseUserId;
              resolve(res.data.AuthorizationStr);
            } else {
              this.$Error(
                res.data.msg ? res.data.msg : `获取金刚系统所需请求头失败!`
              );
            }
          },
          error() {
            this.$Error(`获取金刚系统所需请求头失败`);
          },
        });
      });
    },
    saveAround() {},
    handleClose() {
      this.$emit("closeAddFolder");
    },
  },
};
</script>

<style scoped lang="scss">
.add-folder-main {
  display: flex;
  padding: 24px;
  .folder-cover {
    width: 90px;
    height: 90px;
    margin-right: 16px;
  }
  .folder-team {
    height: 150px;
  }
  .folder-remark {
    width: 294px;
    box-sizing: border-box;
    ::v-deep .el-textarea__inner {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 20px;
      color: #222222;
      background: #f5f5f5;
       @include themify($themes) {
        color: themed("input-color");
        background: themed("search-his-bg1");
      }
    }
  }
}
</style>
