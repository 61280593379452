/*
 * @Author: tangshuo
 * @Date: 2021-01-26 18:27:53
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-08-25 19:04:13
 * @Remarks: 酒店操作相关api
 */

import config from "@/assets/js/config/config";
import { httpServe } from '@/assets/js/utils/request';


/**
 * 获取当前店铺酒店列表
 */
export function getHotelList(params) {
    // SerchName:搜索关键字
    // PageIndex: 页码
    // PageSize: 每页数量
    // CityIds: 城市[]
    // LabelIds: 标签[]
    // Type: 酒店类型 1官方 0本店
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/HotelManage/HotelNew/GetHotelList`,
        method: 'POST',
        data: params
    });
}

/**
 * 获取当前酒店总数
 */
export function GetHotelCount(params) {
    // SerchName:搜索关键字
    // PageIndex: 页码
    // PageSize: 每页数量
    // CityIds: 城市[]
    // LabelIds: 标签[]
    // Type: 酒店类型 1官方 0本店
    return httpServe({
        url: `${config.hostApi}xlapi/HotelManage/HotelNew/GetHotelCount`,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        method: 'POST',
        data: params
    });
}

/**
 * 获取店铺案例文件夹列表
 */
export function getLabelHotelList(params) {
    // SerchName:搜索关键字
    // PageIndex: 页码
    // PageSize: 每页数量
    // Type: 酒店类型 1官方 0本店
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/HotelManage/HotelNew/GetLabelHotelList`,
        method: 'POST',
        data: params
    });
}

/**
 * 获取酒店标签城市查询条件
 */
export function getHotelSearchModel(params) {
    // Type: 类型 1官方 0本店
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/HotelManage/HotelNew/GetHotelSearchModel`,
        method: 'GET',
        data: params
    });
}

/**
 * 获取当前店铺酒店标签列表
 */
export function getHotelLabelList(params) {
    // TitleName:搜索关键字
    // PageIndex: 页码
    // PageSize: 每页数量
    // BranchId: 店铺id
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/HotelManage/HotelLabel/GetHotelLabelList`,
        method: 'POST',
        data: params
    });
}

/**
 * 保存酒店标签
 */
export function setHotelLabel(params) {
    // HotelId: 酒店id
    // BaseUserId: 绑定的官方酒店
    // LabelIds: 酒店标签列表
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/HotelManage/HotelLabel/SetHotelLabel`,
        method: 'POST',
        data: params
    });
}

/**
 * 编辑标签
 */
export function saveHotelLabel(params) {
    // Id: 标签id
    // TitleName: 标签名称
    // CoverImg: 标签封面
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/HotelManage/HotelLabel/SaveHotelLabel`,
        method: 'POST',
        data: params
    });
}

/**
 * 是否绑定了订单
 */
export function setHotelTop(params) {
    // HotelId: 酒店id
    // IsTop:是否精选(置顶)
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/HotelManage/HotelNew/SetHotelTop`,
        method: 'POST',
        data: params
    });
}

/**
 * 是否绑定了订单
 */
export function canReplaceHotel(params) {
    // HotelId: 酒店id
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HotelInfo/Hotel/CanReplaceHotel`,
        method: 'POST',
        data: params
    });
}
/**
 * 删除酒店
 */
export function deleteBranchHotel(params) {
    // HotelId: 酒店id
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HotelInfo/Hotel/DeleteBranchHotel`,
        method: 'POST',
        data: params
    });
}
/**
 * 替换并删除酒店
 */
export function replaceHotelByHotelId(params) {
    // HotelId: 酒店id
    // ReplaceHotelId: 要替换新的酒店Id
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HotelInfo/Hotel/ReplaceHotelByHotelId`,
        method: 'POST',
        data: params
    });
}
