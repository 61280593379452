/*
 * @Author: tangshuo
 * @Date: 2021-01-18 20:16:19
 * @LastEditors: tangshuo
 * @LastEditTime: 2022-03-11 17:07:34
 * @Remarks:
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import tool from './assets/js/utils/tool' //本地工具方法
import component from '@/components/common/index'; //基础组件库
import ElementUI from 'element-ui';
import waterfall from 'vue-waterfall2'
import "@/assets/js/utils/monitor.js";

import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/scss/common/common.scss';
import lrz from 'lrz'
import jquery from 'jquery'
import VueCropper from 'vue-cropper'
import SlsWebLogger from 'js-sls-logger'
import lifecycle from 'page-lifecycle';
import directive from '@/directives'
import * as echarts from 'echarts';

Vue.config.productionTip = false

const opts = {
    host: 'cn-chengdu.log.aliyuncs.com',
    project: 'yowedlog',
    logstore: process.env.VUE_APP_LOGSTORE,
    time: 3,
    count: 10,
}

//初始化日志对象
const logger = new SlsWebLogger(opts)
Vue.prototype['$logger'] = logger;

window.jquery = window.$ = jquery; //全局引入jquery
window.lifecycle = lifecycle;
//组件引入
Vue.use(ElementUI);
Vue.use(component);
Vue.use(waterfall);
Vue.use(VueCropper);

/**Vue原型方法挂载**/
let methods = {
    ...tool,
};
Object.keys(methods).forEach(res => {
    Vue.prototype[res] = methods[res]
});

Vue.prototype.$bus = new Vue() // event Bus 用于无关系组件间的通信。
Vue.prototype.$echarts = echarts;

//全局前置导航守卫
router.beforeEach((to, from, next) => { //全局前置守卫按照创建顺序调用
    let paths = [],
        defaultPath = "",
        shoppingMenu;
    let testRouter = ['/init', '/init2', "/login", "/detail", "/functionSet", '/custormDetail', '/visitorCaseDetails', '/newCaseDetails'
    ];

    if (testRouter.find(r => r.toLowerCase() === to.path.toLowerCase())) {
        next();
        return
    }

    console.log('不要删当前：',to.path.toLowerCase());
    //进入网盘
    if (to.path.toLowerCase() === '/disk') {
		const ismac = localStorage.getItem("ismac") != undefined && localStorage.getItem("ismac") == '1' ? '1' : '';
        let jwtoken = sessionStorage.getItem("Authorization");
        let locations = `${process.env.VUE_APP_PAGEURL}Function/Networks/index.html?jwtoken=${jwtoken}&ismac=${ismac}`;
        window.location = locations;
        return;
    }
    if (to.path.toLowerCase() === '/') {
        if (sessionStorage.getItem('UserId')) {
            next({
                path: '/case'
            });
        }
        else {
            // console.log("身份验证失败,禁止请求")
            if (process.env.NODE_ENV === 'development') {
                //开发环境
                next({
                    path: '/login'
                });
            }
            else {
                //发布环境
                window.location = process.env.VUE_APP_PAGEURL;
            }
        }
        return
    }
    if (store.state.authList[0] && store.state.authList[0].path) {
        defaultPath = store.state.authList[0].path.toLowerCase();
    }
    let pageAuthCode = store.state.pageAuthCode;

    let auth = store.state.authList.filter((a) =>
        pageAuthCode.find((p) => p === a.value)
    );
    if (auth.length > 0) {
        defaultPath = auth[0].path.toLowerCase();
    }
    store.state.branchType = sessionStorage.getItem("BranchType_B");
    let UserShowSetInfo = store.state.UserShowSetInfo; // 获取金刚 案例展示版本 、新版 /case 旧版 /teamcase

    //商城根菜单
    shoppingMenu = store.state.authList.find(a => a.value && a.value.toLowerCase() === 'shopping');

    store.state.authList.forEach(a => {
        a.path ? paths.push(a.path.toLowerCase()) : '';
        paths = [...paths, ...a.ChildNodes.map(c => c.path ? c.path.toLowerCase() : '')];
    });

    let navList = store.state.headList; // 除后台之外 状态为显示 的导航栏
    let navAll = ['/case','/company','/hotel','/team','/banquet_hall', '/article','/inspira','/teamcase','/discover'];
    let navAllList = store.state.navAllList ? store.state.navAllList : navAll; // 除后台之外的导航栏 '/case,/company,/hotel,/team,/banquet_hall,/article,/inspira,/teamcase','/discover'

    //导航栏最多只有 灵感 后台的情况
    if (
        to.path.toLowerCase() === '/nonavindex' &&
        sessionStorage.getItem('UserId')
    ) {
        next();
        return;
    }

    //跳转导航栏的页面 导航栏变更的情况
    if (
        navAllList.find(c => c.toLowerCase() === to.path.toLowerCase()) &&
        sessionStorage.getItem('UserId')
    ) {
        if (navList.length <= 0) {
            next({
                path: '/noNavIndex'
            });
            return;
        }
        if (
            navList.length > 0 &&
            !navList.find(n => ('/' + n.Key.toLowerCase()) === to.path.toLowerCase())
        ) {
            if (to.path.toLowerCase() !== '/teamcase') {
                if (navList.length === 1 && navList[0].Key.toLowerCase() === 'inspira') {
                    next({
                        path: '/noNavIndex'
                    });
                    return;
                }

                if (navList.length > 1 && navList[0].Key.toLowerCase() === 'inspira') {
                    next({
                        path: '/' + navList[1].Key.toLowerCase()
                    });
                    return;
                }

                next({
                    path: '/' + navList[0].Key.toLowerCase()
                });
                return;
            }

            if (to.path.toLowerCase() === '/teamcase' && !navList.find(n => n.Key.toLowerCase() === 'case')) {
                next({
                    path: '/noNavIndex'
                });
                return;
            }
        }
    }

    //店铺类型不为婚庆公司时进入金刚案例页面
    if (to.path.toLowerCase() === '/case' && store.state.branchType != '0') {

        // ShowRemark  1.旧版   2.新版
        if (
            UserShowSetInfo &&
            UserShowSetInfo.ShowRemark == '1'
        ) {
            // next({
            //     path: "/teamcase"
            // });
            window.location = `${process.env.VUE_APP_PAGEURL}teamcase`;
            return;
        }
    }
    //店铺类型为婚庆公司时进入婚庆案例页面
    if (to.path.toLowerCase() === '/teamcase' && store.state.branchType === '0') {
        next({
            path: "/case"
        });
        return;
    }

    //无需验证权限身份
    if (testRouter.find(r => r.toLowerCase() === to.path.toLowerCase()) || to.path.toLowerCase() === '/' || to.path.toLowerCase() ===
        '' || to.name === 'store' || to.path.indexOf('/back/') > 0) {
        next();
    } else if (sessionStorage.getItem('UserId')) {
        //特殊判断集市搜索页面 有集市页面(/shoppingmall)权限可进入
        if ((to.path.toLowerCase() === '/indexsearch' || to.path.toLowerCase() === '/boothsearch') && paths.find(p => p ===
            '/shoppingmall')) {
            next();
            return;
        }
        //特殊判断店铺主页 有商城权限页面(/shopping)权限可进入
        else if ((to.path.toLowerCase() === '/storehomepage' || to.path.toLowerCase() === '/mine') && paths.find(p => p ===
            '/shopping')) {
            next();
            return;
        }
        //特殊判断灵感
        else if (to.path.indexOf("/Function/Inspiration") > -1 && paths.find(p => p === '/inspira')) {
            next();
            return;
        } else if (to.path.toLowerCase() === '/relation' && paths.find(p => p === '/team')) {
            paths.push('/relation')
        }
        else if (to.path.toLowerCase() === '/teamcase' && paths.find(p => p === '/case')) {
            paths.push('/teamcase')
        }

        //身份有效 验证权限
        if (paths.find(p => p === to.path.toLowerCase())) {
            //获取页面按钮权限
            //从权限信息中找到当前页面的菜单id

            let module, pModule = null,
                curPuth = "";
            //特殊判断商城模块
            if (to.path.toLowerCase() === '/shopping') {
                curPuth = "";
                if (shoppingMenu.ChildNodes && shoppingMenu.ChildNodes.find(s => s.value && s.value.toLowerCase() ===
                    'shoppingmall')) {
                    curPuth = '/shoppingmall';
                } else if (shoppingMenu.ChildNodes.length > 0) {
                    curPuth = shoppingMenu.ChildNodes[0].path.toLowerCase();
                }
            } else {
                curPuth = to.path.toLowerCase();
            }
            store.state.authList.forEach(menu => {
                if (menu.path && menu.path.toLowerCase() === curPuth) {
                    module = menu;
                } else if (menu.ChildNodes.find(m => m.path && m.path.toLowerCase() === curPuth)) {
                    module = menu.ChildNodes.find(m => m.path && m.path.toLowerCase() === curPuth);
                    pModule = menu;
                }
            });
            //婚礼相关使用金刚的权限
            if (to.path.toLowerCase() === '/relation') {
                module = store.state.authList.find(m => m.path && m.path.toLowerCase() === '/team');
            }
            //金刚案例使用案例的权限
            if (to.path.toLowerCase() === '/teamcase') {
                module = store.state.authList.find(m => m.path && m.path.toLowerCase() === '/case');
            }
            if (!module || !module.id) return;
            if (store.state.authButton && store.state.authButton[(pModule && pModule.value ? (pModule.value.toLowerCase() +
                '_') : '') + (module.value ? module.value.toLowerCase() : '')]) {

                if (to.path.toLowerCase() === '/shopping') {
                    next({
                        path: curPuth
                    });
                } else {
                    next();
                }
                return;
            }
            methods.$axios({
                url: "NewWeddingApi/SystemModule/Module/Module/GetModuleButtonList",
                method: "POST",
                data: {
                    moduleId: module.id
                },
                success(res) {
                    if (res.data.status) {
                        let aa = {
                            ...store.state.authButton
                        }
                        aa[(pModule && pModule.value ? (pModule.value.toLowerCase() + '_') : '') + (module.value ? module.value.toLowerCase() :
                            '')] = res.data.data;
                        store.state.authButton = aa;
                        if (to.path.toLowerCase() === '/shopping') {
                            next({
                                path: curPuth
                            });
                        } else {
                            next();
                        }
                    }
                },
                error(err) {
                    console.log(err)
                }
            });
        } else {
            if (defaultPath) {
                next({
                    path: defaultPath
                });
            } else {
                // console.log("身份验证失败,禁止请求")
                if (process.env.NODE_ENV === 'development') {
                    //开发环境
                    next({
                        path: '/login'
                    });
                }
                else {
                    //发布环境
                    window.location = process.env.VUE_APP_PAGEURL;
                }
            }
        }
    } else {
        // console.log("身份验证失败,禁止请求")
        if (process.env.NODE_ENV === 'development') {
            //开发环境
            next({
                path: '/login'
            });
        }
        else {
            //发布环境
            window.location = process.env.VUE_APP_PAGEURL;
        }
    }

});

router.afterEach((to, from) => {

    //判断当前页面是否解锁
    let authList = store.state.authList || [];
    try {
        let auth = to.path ? authList.find(a => a.path && a.path.toLowerCase() === to.path.toLowerCase()) : null;

        if (auth) {
            store.state.isLock = !auth.Power;
        } else {
            store.state.isLock = true;
        }

        //导航栏最多只有 灵感 后台的情况
        let noNavIndex = to.name === 'noNavIndex';
        if (noNavIndex) store.state.isLock = false;
    } catch (error) {
        console.log(error);
        store.state.isLock = false;
    }
    //判断当前模块是否未读
    let pageCode = to && to.name ? to.name.toLocaleLowerCase() : '';
    let noReadList = store.state.noReadList || [];
    let noReadModel = noReadList.find(r => r.EffectPath.toLocaleLowerCase() === pageCode);
    // store.state.helpMinData = {}
    if (!store.state.helpMinData) {
        store.state.helpMinData = {}
    }
    if (noReadModel && !store.state.helpMinData.FileId) {
        console.log("当前模块未读，弹出对应提示");
        store.state.helpMinData = {
            FileId: noReadModel.BindFileId,
            FileTitle: noReadModel.FileName,
            isNewModel: true
        };
        setTimeout(() => {
            Vue.prototype.$bus.$emit("openHelpDig1");
            //标记当前模块为已读
            methods.$axios({
                url: "NewWeddingApi/TutorialManage/BranchTutorial/SetTutorialUserRead",
                method: "POST",
                data: {
                    ModuleId: noReadModel.ID
                },
                success(res) {
                    if (res.data.status) {
                        store.state.noReadList = noReadList.filter(r => r.ID != noReadModel.ID);
                        sessionStorage.setItem("noReadList", JSON.stringify(store.state.noReadList))
                    }
                },
                error(err) {
                    console.log(err)
                }
            });
        })
    }
})

// 自定义指令
for (let key in directive) {
    Vue.directive(key, directive[key])
}
//新权限指令
Vue.directive('Auth', {
    // 当被绑定的元素插入到 DOM 中时……
    inserted(el, binding) {
        userAuth(el, binding);
    }
});

function userAuth(el, binding) {
    let type = binding.value.type ? binding.value.type : ''; //当前所需判断权限类型
    let code = binding.value.code ? binding.value.code.toLowerCase() : ''; //当前权限Code
    let pCode = binding.value.pCode ? binding.value.pCode.toLowerCase() : ''; //当前权限父级Code(根菜单不需要传)
    let rCode = binding.value.rCode ? binding.value.rCode.toLowerCase() : ''; //当前权限根级Code(子菜单、根菜单、根菜单下的按钮不需要传)
    let userAuth = store.state.authList; //当前权限基本信息
    let userBtnAuth = store.state.authButton ? store.state.authButton[(rCode ? (rCode + '_') : '') + (pCode ? pCode : '')] :
        null; //当前按钮权限基本信息
	// let ClassName = binding.value.isShow ? 'notclick' : 'notclicks';
	if (!code) {
		// el.parentNode.removeChild(ClassName);
		return;
	}
	let isAdmin = binding.value.isAdmin; //是否为管理员
	if (isAdmin && (pCode === 'menu')) {
		//管理员拥有[菜单]下的所有权限
		// el.parentNode.removeChild(ClassName)
		return;
	}
	if (isAdmin && (code === 'disk')) {
		//管理员[菜单]下的网盘权限
		// el.parentNode.removeChild(ClassName)
		return;
	}

    switch (type) {
        case 'rmenu':
            {
                if (!userAuth.find(ua => ua.value.toLowerCase() === code.toLowerCase())) {
                    el.parentNode.removeChild(el);
                }
                break;
            }
        case 'menu':
            {
                if (code === 'ordermanage' || code === 'mygroup' || code === 'myintegral') {
                    //获取相应子页面权限
                    let rmenu2 = userAuth.find(ua => ua.value.toLowerCase() === pCode.toLowerCase());
                    if (rmenu2 && rmenu2.ChildNodes && rmenu2.ChildNodes.find(m => m.value.toLowerCase() === code.toLowerCase())) {
                        //查询该子页面的按钮权限
                        if (store.state.authButton[pCode + '_' + code]) return;
                        let module;
                        userAuth.forEach(menu => {
                            if (menu.path && menu.value.toLowerCase() === code.toLowerCase()) {
                                module = menu;
                            } else if (menu.ChildNodes.find(m => m.value && m.value.toLowerCase() === code.toLowerCase())) {
                                module = menu.ChildNodes.find(m => m.value && m.value.toLowerCase() === code.toLowerCase());
                            }
                        });
                        if (!module || !module.id) return;
                        methods.$axios({
                            url: "NewWeddingApi/SystemModule/Module/Module/GetModuleButtonList",
                            method: "POST",
                            data: {
                                moduleId: module.id
                            },
                            success(res) {
                                if (res.data.status) {
                                    let btns = res.data.data;
                                    let aa = {
                                        ...store.state.authButton
                                    }
                                    aa[pCode + '_' + code] = btns;
                                    store.state.authButton = aa;
                                }
                            },
                            error(err) {
                                console.log(err)
                            }
                        });
                    } else {
                        el.parentNode.removeChild(el);
                        if (code === 'ordermanage') {
                            let tabs = JSON.parse(sessionStorage.getItem("keepTabName")); //获取菜单信息
                            if (tabs) {
                                store.state.tabName = tabs.name;
                            } else {
                                store.state.tabName = 'collect';
                            }

                        }
                    }
                    return;
                }
                let rmenu = userAuth.find(ua => ua.value.toLowerCase() === pCode.toLowerCase());
                if (!rmenu && rmenu.ChildNodes && rmenu.ChildNodes.find(m => m.value.toLowerCase() === code.toLowerCase())) {
                    el.parentNode.removeChild(el);
                }
                break;
            }
        case 'btn':
            {
                if (!userBtnAuth) {
                    el.parentNode.removeChild(el);
                    //区分"我的"页面
                } else if (!userBtnAuth.find(ua => ua.EnCode.toLowerCase() === code.toLowerCase())) {
                    el.parentNode.removeChild(el);
                }
                break;
            }
        default:
            {
                el.parentNode.removeChild(el);
                break;
            }
    }
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
