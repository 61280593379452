/*
 * @Author: tangshuo
 * @Date: 2020-08-10 15:01:41
 * @LastEditors: tangshuo
 * @LastEditTime: 2022-01-24 15:03:29
 * @Remarks: 金刚相关store
 */
import { getHostUserIdentity } from "@/api/team";
export default {
	namespaced: true,
	state: {
		hostAuthStr: "", //金刚系统头部信息
		baseUserId: "",//金刚系统baseUserId
		teamList: [],
		worksList: [],
		allTeamTypes: [],//所有金刚类型
		teamLabels: [],//商家设置的标签
		// 查看大图
		nowPlayItemId: -1,
		showTeamImgVideoBox: false, // 查看视频 / 图片
		reSourcesArr: [],
	},
	mutations: {
		//设置金刚系统相关信息
		setHostHead(state, data) {
			state.hostAuthStr = data.hostAuthStr;
			state.baseUserId = data.baseUserId;
		},
	},
	getters: {},
	actions: {
		//获取金刚系统相关信息
		getHostUserIdentity(store, params) {
			getHostUserIdentity().then(res => {
				if (res.AuthorizationStr) {
					let hostAuthStr = res.AuthorizationStr;
					let baseUserId = hostAuthStr.split("|")[0];
					sessionStorage.setItem("hostAuthStr", hostAuthStr);
					sessionStorage.setItem("baseUserId", baseUserId);
					store.commit("setHostHead", { hostAuthStr, baseUserId });
				} else {
					store.commit("UserInfo/setTipObj", {
						type: 'err', //弹窗类型
						massge: res.msg,
						isShowTip: true //是否显示弹窗
					}, { root: true });
				}
				params && params.callback ? params.callback() : ''
			})
		},
	}
}
