/**
 * 本店视频 清晰度 VideoSource === 0 本店
 */
export default {
  data() {
    return {
      clarityObj: {
        'FHD': {
          path: '_transcode_FHD.mp4',
          name: '高清', // 超清
          key: 'FHD'
        },
        'HD': {
          path: '_transcode_HD.mp4',
          name: '高清', // 高清
          key: 'HD'
        },
        'SD': {
          path: '_transcode_SD.mp4',
          name: '标清',
          key: 'SD'
        },
      }
    }
  },
  methods: {
    // 获取清晰度
    async getVideoUrlDetail(url) {
      url = url.substring(0, url.lastIndexOf('.'));
      // if (url && url.indexOf('.') !== -1) {
      //   const lastIndex = url.lastIndexOf('.');
      //   const suffix = url.substring(lastIndex, url.length);
      //   console.log(suffix);
      //   if (suffix.indexOf('mp4') === -1) {
      //     return null;
      //   }
      // }
      let urls = {};
      for (let ikey in this.clarityObj) {
        // 当urls 内存在FHD格式时，不再请求HD格式
        if (Object.keys(urls).includes('FHD') && ikey === 'HD') {
          continue
        }
        const item = this.clarityObj[ikey]
        let tempUrl = url + item.path
        const result = await this.getHeadvideo(tempUrl)
        if (result) {
          urls[ikey] = tempUrl
        }
      }
      // FHD 和 HD 不共存
      if (urls.hasOwnProperty('FHD')) {
        delete urls['HD']
      }
      let videoList = [];
      if (Object.keys(urls).length > 0) {
        const list = Object.values(this.clarityObj);
        list.forEach(item => {
          if (urls[item.key]) {
            videoList.push({
              key: item.key,
              name: item.name,
              url: urls[item.key]
            })
          }
        })
      }
      return videoList;
    },
    async getHeadvideo(url) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `${url}?v=${new Date().getTime()}`,
          type: 'head',
          datatype: 'json',
          success(res) {
            console.log(res, 'res');
            resolve(true);
          },
          error(err) {
            console.log(err, 'err')
            resolve(null)
          }
        });
      })
    },
  }
}
