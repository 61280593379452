<!--
 * @Author: tangshuo
 * @Date: 2021-12-21 18:39:09
 * @LastEditors: tangshuo
 * @LastEditTime: 2022-03-14 11:55:21
 * @Remarks: 新增标签
-->
<template>
  <div>
    <ConDig
        :title="title"
        width="480px"
        :isShowDig="showAddLabelDig"
        :loading="isLodding"
        :appendToBody="true"
        confirmText="保存"
        @open="initData"
        @closed="showAddLabelDig = false"
        @closeDig="handleClose"
        @cancelClick="handleClose"
        @confirmClick="addNewColor"
    >
      <main slot="dialogMain" class="add-color-box" v-loading="isLodding">
        <ConInput
            v-model.trim="labelName"
            :size="24"
            maxlength="40"
            showWordLimit
            class="input-name"
            placeholder="标签名称"
        ></ConInput>
        <div class="color-sel-box">
          <div class="row-item">
            <div class="row-title-box">
              <span class="title-text">以文件夹形式展示</span>
              <span class="title-less"
              >若不设置，则默认合集内第一个作品的封面为合集封面</span
              >
            </div>
            <el-switch
                v-model="isShowFolder"
                :active-color="(theme === 'Dark' || theme === 'Plus')?'#ffcc00':'#222222'"
                :inactive-color="(theme === 'Dark' || theme === 'Plus')? '#444' : '#DCDFE6'"
            ></el-switch>
          </div>
          <div
              class="lable-cover-box"
              :class="{
                'verticalBox': $store.state.caseCoverShowWay === 'vertical'
              }"
          >
            <div
                class="img-bg"
                :style="`background: url(${coverPath}) 100% center / cover no-repeat rgba(0, 0, 0, 0.1);`"
                v-if="coverPath"
            ></div>
            <el-image
                fit="cover"
                class="cover-img"
                v-if="coverPath"
                :src="coverPath"
            ></el-image>
            <div class="layer-mask"></div>
            <ConButton
                @click="selCaseCover"
                v-if="coverPath"
                class="cover-btn"
                title="替换封面"
            ></ConButton>
            <ConIcon
                v-else
                @click="selCaseCover"
                class="cover-btn icon_add_big"
                icon="icon_add_big"
            ></ConIcon>
          </div>
          <div class="row-item b-t-e">
            <div class="row-title-box">
              <span class="title-text">该标签/合集对外公开（移动端）</span>
            </div>
            <el-switch
                v-model="isShowOut"
                :active-color="(theme === 'Dark' || theme === 'Plus')?'#ffcc00':'#222222'"
                :inactive-color="(theme === 'Dark' || theme === 'Plus')? '#444' : '#DCDFE6'"
            ></el-switch>
          </div>
          <div class="row-item b-t-e">
            <div class="row-title-box">
              <span class="title-text">所属类型（选填）</span>
            </div>
            <div
                class="w-select-box multiple-select type"
                :class="{
                  'disabled': disabled
                }"
            >
              <el-popover
                  placement="bottom-end"
                  popper-class="type-popover-w"
                  :visible-arrow="false"
                  width="144"
                  trigger="click"
                  v-model="isShowTypeBox"
                  :disabled="disabled"
              >
                <div
                    class="input-div-s color-div"
                    :class="isShowTypeBox ? 'focus' : ''"
                    slot="reference"
                >
                  <div class="label-box-width">
                    {{
                      RelationTypes.length > 0 ?
                          RelationTypes.map(c => c.Name).join('/')
                          :
                          '不限'
                    }}
                  </div>
                </div>
                <div class="list-box small4-scroll">
                  <div
                      class="item"
                      v-for="(item, index) in orderTypeList"
                      :key="'type' + index"
                      @click.stop="selTypeItem(item)"
                      :class="{
                        'active': !!RelationTypes.find(c => c.Id === item.Id)
                      }"
                  >
                    {{item.Name}}
                    <i class="iconfont icon_gou2"></i>
                  </div>
                </div>
              </el-popover>
              <div class="add-btn" @click.stop="selType"><i class="down" :class="isShowTypeBox ? 'up' : ''"></i></div>
            </div>
          </div>
        </div>
        <input
            type="file"
            id="lCoverfile"
            ref="lCoverfile"
            accept="image/png, image/jpeg, image/gif"
            style="opacity: 0; width: 0; height: 0; position: absolute"
            @change="selCaseImg"
        />
      </main>
    </ConDig>
    <!--  裁剪  -->
    <tailoring-cover-dig
        ref="tailoringDig"
        :showTailoringDig="showTailoringDig"
        :imgSrc="tailorImg"
        :ratio="cuttingRatio"
        :notUpload="true"
        @uploadCallback="setLableCover"
        fileId="curFolder"
        @closeDig="
          tailorImg = null;
          cuttingRatio = null;
          showTailoringDig = false;
        "
    ></tailoring-cover-dig>
  </div>
</template>

<script>
import {
  addBranchLabelDetailInfoNew,
  AddBranchLabelDetailInfoNew_DJ,
  upLabelDetailInfoNew
} from "@/api/case";
import TailoringCoverDig from "@/views/case/components/tailoringCoverDig";
import {mapActions, mapState} from "vuex";
export default {
  data() {
    return {
      isShowTypeBox: false,
      isLodding: false,
      labelName: "",
      isShowOut: false, //是否对外公开
      isShowFolder: false, //是否以文件夹显示
      coverImg: "", //封面图
      coverPath: "",
      RelationTypes: [],
      curFile: {}, //选择的文件
      isChange: false,
      branchId: sessionStorage.getItem("BranchId"),
      showTailoringDig: false,
      cuttingRatio: null,
      tailorImg: null,
      ParentId: '', // 父级
      disabled: false,
      cosIp: sessionStorage.getItem("BranchCosCdnUrl_B"),
    };
  },
  props: {
    showAddLabelDig: {
      type: Boolean,
      default: false,
    },
    curLabel: {
      default: () => {},
    },
    /**
     * 是子集 的 编辑 与 添加
     */
    isSubsetOperate: {
      type: String,
      default: '',
    }
  },
  components: {
    TailoringCoverDig
  },
  computed: {
    ...mapState({
      theme: (state) => state.theme,
      orderTypeList: (state) => state.caseR.orderTypeList,
    }),
    title() {
      if (this.isSubsetOperate) {
        return this.isSubsetOperate === 'add' ? '添加标签' : '编辑标签';
      }
      return this.curLabel && this.curLabel.Id ? '编辑标签' : '添加标签';
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions({
      getDicOrderTypeList: "caseR/getDicOrderTypeList",
    }),
    initData() {
      this.ParentId = '';
      this.disabled = false;

      this.isChange = false;
      // 类型
      this.getDicOrderTypeList({
        SearchBranchId: sessionStorage.getItem("BranchId_B"),
      });

      this.labelName = "";
      this.isShowOut = true;
      this.isShowFolder = true;
      this.coverImg = "";
      this.isNewFile = false;
      this.coverPath = '';
      this.RelationTypes = [];

      if (this.isSubsetOperate) this.disabled = true;

      // 编辑主标签 / 子标签
      if (
          this.curLabel &&
          this.isSubsetOperate !== 'add'
      ) {
        this.labelName = this.curLabel.LabelContent;
        this.isShowOut = !this.curLabel.IsHideHost;
        this.isShowFolder = this.curLabel.IsFolderShow;
        this.coverPath = this.curLabel.CoverImg;
        if (
            this.curLabel.CoverImg &&
            this.curLabel.CoverImg.indexOf('http://') === -1 &&
            this.curLabel.CoverImg.indexOf('https://') === -1
        ) {
          this.coverPath = this.cosIp + this.curLabel.CoverImg;
        }
        this.coverImg = this.curLabel.CoverImg;
        this.RelationTypes = this.curLabel.RelationTypeList;
      }

      // 添加子集
      if (
          this.curLabel &&
          this.isSubsetOperate === 'add'
      ) {
        this.ParentId = this.curLabel.parent_Id;
        this.RelationTypes = this.curLabel.RelationTypeList;
      }

    },
    selType() {
      if (this.isSubsetOperate) return;
      this.isShowTypeBox = !this.isShowTypeBox;
    },
    selTypeItem(item) {
      this.isShowTypeBox = true;
      const typeIndex = this.RelationTypes.findIndex(c => c.Id === item.Id);
      if (typeIndex !== -1) {
        this.RelationTypes.splice(typeIndex, 1);
        return;
      }
      if (this.RelationTypes.length >= 3) {
        this.$message('最多选择3个类型');
        return void 0;
      }
      this.RelationTypes.push(item);
      this.$forceUpdate();
    },
    /**
     * 选择了图片后
     */
    selCaseImg() {
      this.fileList = [];
      let file = this.$refs.lCoverfile.files[0];
      const fileUrl = this.$getObjectURL(file);
      // this.curFile = file;
      // this.coverPath = fileUrl;
      // this.isNewFile = true;
      this.setRatio();
      this.tailorImg = fileUrl;
      this.showTailoringDig = true;
    },
    /**
     * 裁剪完成之后的
     * @param data
     */
    setLableCover(data) {
      this.coverPath = this.$getObjectURL(data);
      this.curFile = data;
      this.isNewFile = true;
    },
    setRatio() {
      if (this.$store.state.caseCoverShowWay === 'vertical') {
        this.cuttingRatio = [3,4];
      } else {
        this.cuttingRatio = null;
      }
    },
    /**
     * 弹出文件选择
     */
    selCaseCover() {
      this.$refs.lCoverfile.value = "";
      this.$refs.lCoverfile.click();
    },
    /**
     * 上传图片
     */
    uploadFile(file) {
      return new Promise((resolve, reject) => {
        this.$newUploadFile({
          file: file,
          fileId: "",
          thFile: "", //压缩文件(仅非图片文件有效)
          pathKey: `${this.branchId}/CaseReveal/curFolder/CoverImg/`,
          ysType: 3,
          onProgress(progress) {
            //上传进度
            file.progress = progress;
          },
          onTaskReady(taskId) {
            // console.log("准备就绪,开始上传 ：", taskId);
            file.taskId = taskId;
          },
          success(res) {
            // console.log("上传成功", res);
            let path = res.key;

            if (file) {
              //反写数据
              file.path = path;
            }
            resolve(file);
          },
          error() {
            //更改状态为上传失败
            resolve({});
          },
        });
      });
    },
    /**
     * 新增标签
     */
    async addNewColor() {
      if (!this.labelName) {
        this.$Message("请输入标签名称");
        return;
      }

      this.isLodding = true;
      if (this.isNewFile) {
        let coverFile = await this.uploadFile(this.curFile);
        console.log("coverFile", coverFile);
        this.coverImg = coverFile ? coverFile.path : "";
        this.isNewFile = false;
      }

      // 编辑
      if (
        this.curLabel &&
        this.curLabel.Id &&
        this.isSubsetOperate !== 'add'
      ) {
        this.upLabelDetailInfoNew();
        return;
      }

      // 新增
      AddBranchLabelDetailInfoNew_DJ({
        LabelContent: this.labelName,
        CoverImg: this.coverImg,
        CoverSourceImg: this.coverImg,
        IsFolderShow: this.isShowFolder,
        IsHideHost: !this.isShowOut,
        RelationTypes: this.RelationTypes.map(c => c.Id).join(','),
        ParentId: this.ParentId,
      })
        .then((res) => {
          if (res.status) {
            this.$Success("添加成功");
            this.isChange = true;
            this.handleClose();
          } else {
            this.$Error(res.msg ? res.msg : "添加失败");
          }
          this.isLodding = false;
        })
        .catch((err) => {
          console.log(err);
          this.$Error("添加失败");
          this.isLodding = false;
        });
    },
    /**
     * 编辑标签
     */
    upLabelDetailInfoNew() {
      this.isLodding = true;
      upLabelDetailInfoNew({
        Id: this.curLabel.Id,
        LabelContent: this.labelName,
        CoverImg: this.coverImg,
        CoverSourceImg: this.coverImg,
        IsFolderShow: this.isShowFolder,
        IsHideHost: !this.isShowOut,
        RelationTypes: this.RelationTypes.map(c => c.Id).join(',')
      })
        .then((res) => {
          if (res.status) {
            this.$Success("编辑成功");
            this.isChange = true;
            this.handleClose();
          } else {
            this.$Error(res.msg ? res.msg : "编辑失败");
          }
          this.isLodding = false;
        })
        .catch((err) => {
          console.log(err);
          this.$Error("编辑失败");
          this.isLodding = false;
        });
    },
    handleClose() {
      this.isShowTypeBox = false;
      this.$emit("handleClose", this.isChange);
    },
  },
  beforeDestroy() {
  }
};
</script>

<style scoped lang="scss">
.add-color-box {
  padding: 24px;
  .input-name {
    ::v-deep .el-input__inner {
      height: 60px;
      line-height: 60px;
    }
  }
  .color-sel-box {
    width: 100%;
    //height: 378px;
    box-sizing: border-box;
    border-top: none;
    display: flex;
    position: relative;
    flex-direction: column;
    border: 1px solid #eee;
    border-top: 0;
    @include themify($themes) {
      border-color: themed("search-his-bg-h");
    }
    .row-item {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      justify-content: space-between;
      padding: 12px 12px 0;
      box-sizing: border-box;
      .row-title-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title-text {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
          line-height: 20px;
          @include themify($themes) {
            color: themed("input-color");
          }
        }
        .title-less {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 16px;
          margin-top: 4px;
          @include themify($themes) {
            color: themed("search-item-color");
          }
        }
      }
    }
    .b-t-e {
      padding-bottom: 12px;
      border-top: 1px solid #eee;
      @include themify($themes) {
        border-color: themed("search-his-bg-h");
      }
    }
    .lable-cover-box {
      width: calc(100% - 24px);
      height: 255px;
      background: #f5f5f5;
      margin: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      @include themify($themes) {
        background: themed("checked-border");
      }
      &.verticalBox {
        .cover-img {
          width: 255/4*3px;
        }
        .img-bg {
          display: block;
        }
      }
      .img-bg {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background: #f5f7fa;
        filter: blur(50px);
      }
      .layer-mask {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(0,0,0,0.1);
      }
      .cover-img {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .cover-btn {
        z-index: 1;
      }
    }
  }
}
.theme-Min .add-color-box .lable-cover-box .cover-btn.icon_add_big {
  color: #222222;
}
.theme-Dark,
.theme-Plus {
  .con-button {
    background: #fff;
    border: none;
  }
}
.multiple-select {
  height: 36px;
  display: flex;
  position: relative;
  > span {
    width: 100%;
  }
  .add-btn {
    position: absolute;
    right: -8px;
    top: 3px;
    z-index: 1;
    width: 30px;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .down {
      display: block;
      width: 30px;
      height: 30px;
      margin: auto;
      background: url("../../../../public/images/case/Choice-icon.png")
      no-repeat center;
      -webkit-background-size: 35%;
      background-size: 35%;
      transform: rotate(0deg);
      transition: all 0.5s;
      &.up {
        transform: rotate(180deg);
      }
    }
  }
  .input-div-s {
    width: 100%;
    height: 36px;
    line-height: 36px;
    padding: 0 22px 0 8px;
    box-sizing: border-box;
    text-align: right;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    .placeholder {
      color: #ccc;
    }
    .label-box-width {
      overflow: hidden;
      height: 36px;
      line-height: 36px;
    }
    .sel-l {
      height: 24px;
      background: #EEEEEE;
      padding: 0 2px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      margin-right: 4px;
      display: inline-flex;
      align-items: center;
      i {
        font-size: 18px;
        color: #999;
        margin-left: 4px;
      }
      &.color-sel {
        width: 48px;
        border: 1px solid;
        padding: 0;
        justify-content: center;
        i {
          color: #fff;
          font-size: 18px;
        }
      }
    }
  }
}
.list-box {
  width: 100%;
  height: 232px;
  overflow: auto;
  background: #ffffff;
  @include themify($themes) {
    background: themed("price-bg");
  }

  .item {
    width: 100%;
    height: 36px;
    line-height: 36px;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    @include themify($themes) {
      color: themed("hotel-color");
    }
    .icon_gou2 {
      display: none;
      font-size: 20px;
      position: absolute;
      right: 0;
      color: #666;
    }
    &:hover,
    &.active {
      background: #f5f5f5;
      color: #222222;
      @include themify($themes) {
        background: themed("search-his-bg");
        color: themed("input-color");
      }
    }
    &.active {
      .icon_gou2 {
        display: inline;
        @include themify($themes) {
          color: themed("search-btn-bg");
        }
      }
      background: #f5f5f5;
      color: #222222;
      @include themify($themes) {
        background: themed("search-his-bg");
        color: themed("search-btn-bg");
      }
    }
  }
}
.w-select-box {
  width: 68%;
}
.w-select-box.disabled {
  cursor: not-allowed;
  color: #c0c4cc;
  .input-div-s,
  .add-btn {
    cursor: not-allowed;
  }
}
.theme-Dark,
.theme-Plus {
  .label-box-width {
    color: #999;
  }
}
</style>
<style lang="scss">
.el-popover.type-popover-w {
  border-radius: 0;
  border: 0;
  padding: 8px 0;
  background: #ffffff;
  @include themify($themes) {
    background: themed("price-bg");
  }
}
</style>
