<!--
 * @Author: ZBH
 * @Date: 2021-01-21 09:34:13
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-08-09 20:40:07
 * @Remarks: 
-->
<template>
	<div class="append-text-wrap">
		<el-input
			class="con-input"
			:class="{ 'con-input-err': isError, 'no-border-input': isNoBorder }"
			:style="{ width: width ? width + 'px' : '100%', fontSize: size + 'px', height: height ? height + 'px' : 'auto', lineHeight: height ? height + 'px' : 'auto' }"
			:maxlength="maxlength"
			@focus="focus"
			@blur="blur"
			@change="inputChange"
			:type="type"
			v-model="inputValue"
			:show-word-limit="showWordLimit"
			:placeholder="placeholder"
			:clearable="clearable"
			:disabled="disabled"
			:autosize="autosize"
		>
			<i v-if="suffixIcon" class="suffixI  iconfont" :class="suffixIcon" slot="suffix"></i>
			<i v-if="prefixIcon" class="prefixI  iconfont" :class="prefixIcon" slot="prefix"></i>
		</el-input>
		<div class="append-text">{{ appendValue }}</div>
		<div class="append-isError" v-if="isError">{{ isError }}</div>
	</div>
</template>
<script>
export default {
	name: 'my-input',
	data() {
		return {
			inputValue: '',
			iconf: 'iconfont'
		};
	},
	props: {
		autosize: {
			type: Object,
			default() {
				return {};
			}
		},
		type: {
			type: String,
			default: 'text' //带图标 使用slot name=suffix
		},
		value: {
			default: ''
		},
		showWordLimit: {
			type: Boolean,
			default: false //是否显示字数统计
		},
		size: {
			type: Number,
			default: 14
		},
		height: {
			type: Number,
			default: 0
		},
		width: {
			type: Number,
			default: 0
		},
		placeholder: {
			type: String,
			default: '请输入内容' //无内容提示
		},
		clearable: {
			type: Boolean,
			default: false //是否显示清除按钮
		},
		disabled: {
			type: Boolean, 
			default: false //是否禁用
		},
		maxlength: {
			default: '' //最大长度
		},
		suffixIcon: {
			type: String,
			default: '' //带图标 使用slot name=suffix
		},
		prefixIcon: {
			type: String,
			default: '' //带图标 使用slot name=suffix
		},
		appendValue: {
			type: String,
			default: '' //带图标 使用slot name=suffix
		},
		isError: {
			type: String,
			default: '' //错误
		},
		isNoBorder: {
			type: Boolean,
			default: false //是否禁用
		}
	},
	watch: {
		inputValue(val) {
			this.$emit('input', val);
		},
		value() {
			this.inputValue = this.value;
			console.log(this.value);
		}
	},
	components: {},
	methods: {
		inputChange(e) {
			this.$emit('change', e);
		},
		focus(e) {
			this.$emit('focus', e);
		},
		blur(e) {
			this.$emit('blur', e);
		}
	},
	created(){
		this.inputValue = this.value;
	}
};
</script>
<style scoped lang="scss">
.el-input--prefix {
	::v-deep .el-input__prefix {
		display: flex;
		justify-content: center;
		align-items: center;
		left: 8px;
		.prefixI {
			font-size: 24px;
			color: #666666;
		}
	}
	::v-deep.el-input__inner {
		padding-left: 40px;
		padding-right: 30px;
	}
}
.el-input--suffix {
	::v-deep .el-input__suffix {
		display: flex;
		justify-content: center;
		align-items: center;
		right: 8px;
		.suffixI {
			font-size: 24px;
			color: #666666;
		}
	}
	::v-deep.el-input__inner {
		padding-right: 40px;
	}
}

::v-deep .el-input-group__append,
.el-input-group__prepend {
	background: #ffffff;
	border-left: 0px solid transparent;
	border-right: 1px solid #eeeeee;
	border-top: 1px solid #eeeeee;
	border-bottom: 1px solid #eeeeee;
}
::v-deep .el-input.is-disabled .el-input__inner {
	background-color: #f5f5f5;
	border-color: #eeeeee;
	color: #666666;
	cursor: not-allowed;
}
::v-deep  .el-textarea.is-disabled .el-textarea__inner{
	background-color: #f5f5f5;
	border-color: #eeeeee;
	color: #666666;
	cursor: not-allowed;
}
.append-text-wrap {
	position: relative;
	.append-text {
		position: absolute;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #222222;
		line-height: 20px;
		top: 0;
		right: 9px;
		height: 40px;
		line-height: 40px;
	}
	.append-isError {
		margin-top: 4px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ff3355;
		line-height: 16px;
	}
}
</style>
