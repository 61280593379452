<!-- 滑块导航 -->
<template>
  <ul
      class="tab-wrap"
      :style="boxStyle"
      ref="tabBox">
    <li
        class="slide-tab-item"
        v-for="(item, index) in tabList"
        :key="'tab-' + index"
        @click="tabChange(item, index)"
        :class="{ 'tab-active': activeIndex === index }"
        :style="{
          padding: itemPaddingLF ? itemPaddingLF : '0px',
          width: itemPaddingLF ? 'auto' : 100 / tabList.length + '%',
        }"
    >
      {{ item.value || "" }}
    </li>
    <p
      class="tab-slide"
      :style="barStyle"
    >
      <i class="tab-slide-i"></i>
    </p>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
      barStyle: {}
    };
  },
  props: {
    tabList: {
      //切换数据 value
      type: Array,
      default: () => [],
    },
    curIndex: {
      default: 0,
    },
    width: {
      type: String,
      default: () => "200px",
    },
    height: {
      type: String,
      default: () => "",
    },
    /**
     * item 左右的间距
     * 必传
     */
    itemPaddingLF: {
      type: String,
      default: () => "",
      required: true
    }
  },
  computed: {
    boxStyle() {
      let val = {
        width: this.width
      }
      if (this.height) {
        val.height = this.height;
      }
      return val
    }
  },
  watch: {
    curIndex(val) {
      this.activeIndex = val;
    },
  },
  methods: {
    tabChange(item, index) {
      if (this.activeIndex === index) return;
      this.activeIndex = index;
      this.getSwitchItem();
      this.$emit("tabChange", { item, index});
    },
    getSwitchItem() {
      this.$nextTick(() => {
        if (
            this.tabList.length > 0 &&
            this.$refs['tabBox']
        ) {
          const tabItem = this.$refs['tabBox'].querySelectorAll('.slide-tab-item');
          const actItem = tabItem[this.activeIndex];
          this.barStyle = {
            width: actItem.clientWidth + 'px',
            transform: `translateX(${actItem.offsetLeft - 2}px)`
          }
        }
      })
    },
  },
  created() {},
  mounted() {
    this.activeIndex = this.curIndex;
    this.getSwitchItem();
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.tab-wrap {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: #eeeeee;
  padding: 2px;
  border-radius: 0px;
  margin-right: 12px;
  width: 200px;
  position: relative;
  @include themify($themes) {
    background: themed("switch-bg");
    border-radius: themed("border-radius-min");
  }

  .slide-tab-item {
    box-sizing: border-box;
    cursor: pointer;
    z-index: 1;
    word-break: keep-all;
    color: #999999;
    font-weight: 600;
    text-align: center;
    list-style: none;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .tab-active {
    color: #999999;
    font-weight: 600;
    @include themify($themes) {
      color: themed("input-color");
    }
  }

  .tab-slide {
    height: calc(100% - 4px);
    position: absolute;
    transition: transform 0.3s;
    left: 2px;
    cursor: pointer;
    .tab-slide-i {
      border-radius: 0;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
      background: #fff;
      margin: 0 auto;
      display: inline-block;
      width: 100%;
      height: 100%;
      @include themify($themes) {
        background: themed("price-bg");
        border-radius: themed("border-radius-min");
      }
    }
  }
}
</style>
