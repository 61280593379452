<!--
 * @Author: tangshuo
 * @Date: 2021-01-21 09:34:13
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-10-09 15:21:54
 * @Remarks:
-->
<template>
  <el-tooltip
    ref="tooltip"
    class="item"
    effect="dark"
    :visible-arrow="false"
    :open-delay="300"
    :content="tipText"
    :disabled="!tipText || disabled"
    :placement="placement"
  >
    <i
      v-if="icon"
      class="iconfont"
      @click.stop="iconClick"
      :class="icon + ' ' + sizeCless + ' ' + disabledClass"
    >
      <span class="mark-slot"> <slot name="mark"> </slot></span
    ></i>
  </el-tooltip>
</template>

<script>
export default {
  name: "icon",
  props: {
    placement: {
      // 提示显示位置
      type: String,
      default: "bottom",
    },
    icon: {
      //图标名称
      type: String,
      default: "",
    },
    tipText: {
      //提示文字
      default: "",
    },
    size: {
      //图标大小 max大(32-24) min小(24-16)
      type: String,
      default: "max",
    },
    disabled: {
      //图标是否禁用
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sizeCless() {
      return this.size === "min" ? "min-icon" : "max-icon";
    },
    disabledClass() {
      return this.disabled ? "disabled-icon" : "";
    },
  },
  methods: {
    iconClick() {
      if (this.disabled) {
        return;
      }
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/common/icon.scss";
.iconfont {
  position: relative;
  .mark-slot {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 16px;
    line-height: 16px;
  }
}
</style>
