import {BaseCollectList} from "@/api/discover";
export default {
    namespaced: true,
    state: {
        collectTotal: 0, // 收藏多少条
        collectTypeData: [], // 收藏夹类型列表
    },
    mutations: {
        // 获取收藏夹类型列表
        setDiscoverCollect(state, data) {
            state.collectTotal = data.collectTotal || 0;
            state.collectTypeData = data.collectTypeData;
        },
    },
    getters: {},
    actions: {
        // 获取收藏夹类型列表
        BaseCollectList(store, params) {
            return new Promise(resolve => {
                BaseCollectList({
                    PageIndex: 1,
                    PageSize: 100,
                    IsShowCount: true,
                }).then(res => {
                    if (res.status && res.data) {
                        const data = res.data || [];
                        const total = res.total || 0;
                        store.commit("setDiscoverCollect", {
                            collectTotal: total || 0,
                            collectTypeData: data
                        });
                    } else {
                        store.commit("UserInfo/setTipObj", {
                            type: 'err', //弹窗类型
                            massge: res.msg,
                            isShowTip: true //是否显示弹窗
                        }, {
                            root: true
                        });
                    }

                    resolve(res);
                }).catch(() => {
                    resolve(null);
                })
            })
        },
    }
}
