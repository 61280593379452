<!--
 * @Author: tangshuo
 * @Date: 2021-12-21 14:59:08
 * @LastEditors: tangshuo
 * @LastEditTime: 2022-03-11 18:01:14
 * @Remarks: 标签管理
-->
<template>
  <ConDig
    title="作品标签管理"
    width="480px"
    :isShowDig="showManageDig"
    :loading="isLodding"
    :appendToBody="true"
    :isShowFooter="false"
    @open="initData"
    @closeDig="handleClose"
    @cancelClick="handleClose"
  >
    <main slot="dialogMain" class="color-manage-box" v-loading="isLoading">
      <el-button
        class="con-button add-btn"
        icon="iconfont icon_add_big"
        @click="
          curLabel = null;
          showAddLabelDig = true;
        "
      >新增标签</el-button>
      <div class="search-type-list">
        <div ref="moreBtnBox">
          <span
              ref="minTypeItem"
              class="type-item"
              :class="{
                'active': curType === item.Id
              }"
              v-for="(item, index) in celebrateTypeList"
              :key="'type-s-' + index"
              @click="tabType(item, index)"
          >{{item.Name}}({{item.labelNum}})</span>
        </div>
      </div>
      <div class="box" v-if="labelList.length > 0">
        <label-draggable
            :list="labelList"
            :curType="curType + ''"
            :getIconGroup="setIconGroup"
            @refreshSort="refreshSort"
            @icon-click="iconClick"
        >
          <template v-slot:label-content="{ info }">
            <span class="item-name">
              #{{ info.LabelContent }}
            </span>
            <span class="item-tip" v-if="info.IsFolderShow">以文件夹展示</span>
            <span class="item-tip" v-if="info.IsHideHost">未公开</span>
            <label-draggable-popover
                :ref="'tips-'+ info.Id"
                className="tips-label"
                trigger="click"
                :info="info"
                :visible-arrow="true"
                :customContent="true"
                :custom="true"
                :key="'tips' + info.Id"
                v-if="info.FileCount && info.ChildList && info.ChildList.length > 0"
                @hide="$bus.$emit('label-draggable-close-popover', [info.Id.toString()]);"
            >
              <div class="tips-i" slot="content">
                <div class="title">
                  有{{info.FileCount}}个案例直属于该标签，您可以批量将其
                </div>
                <div class="title">
                  添加至
                  <label-draggable-popover
                      :info="info"
                      :custom="true"
                      :allChild="true"
                      :list="replaceLabelList"
                      @moveChange="(data) => replaceChange(data,info)"
                      :key="'replaceBabel' + info.Id"
                  >
                    <span class="select-span" slot="custom">选择标签 <i class="iconfont icon_arrow_down"></i></span>
                  </label-draggable-popover>
                  便于查找案例
                </div>
              </div>
              <i
                  class="el-icon-warning-outline"
                  slot="custom"
                  @click.stop="replaceLabelHandler(info)"
              ></i>1
            </label-draggable-popover>
          </template>
          <template v-slot:label-type="{ info }">
            <div class="type-div" v-if="info.RelationTypeList && info.RelationTypeList.length > 0">
              {{info.RelationTypeList.map(c => c.Name).join('/')}}
            </div>
          </template>
          <template v-slot:move-popover="{ info }">
            <label-draggable-popover
                :info="info"
                :isMove="true"
                :list="oldLabelList"
                :key="'move' + info.Id"
                @moveChange="(data) => moveChange(data,info)"
            ></label-draggable-popover>
          </template>
        </label-draggable>
        <div class="no-data" v-if="!isLoading && labelList.length <= 0">
          <img :src="theme === 'Dark' || theme === 'Plus' ? '/images/team/img_null_search_dark.png' : '/images/team/img_null_search.png'" alt="">
          <p>没有内容哦</p>
        </div>
      </div>
      <ConTip
        :type="tipsObj.type"
        :isShowTip="showTipDig"
        :cancelText="tipsObj.cancelText"
        :confirmText="tipsObj.confirmText"
        :confirmType="tipsObj.confirmType"
        :tipText="tipsObj.tipText"
        @confirmClick="tipsConfirm"
        @closeTipDig="closeTip"
        @cancelClick="closeTip"
      >
        <div class="replace-del-div" slot="main" v-if="tipsObj.operateType === 'del-replace'">
          <label-draggable-popover
              v-if="curLabel && curLabel.Id"
              ref="del-replace-popover"
              :info="curLabel"
              :custom="true"
              :allChild="true"
              :delReplace="true"
              :selValue="selValue"
              :list="replaceLabelList"
              @moveChange="(data) => delReplaceChange(data,curLabel)"
              :key="'delReplaceBabel' + curLabel && curLabel.Id ? curLabel.Id : '--'"
          >
            <div
                class="select-replace"
                :class="{
                  'active': tipsObj.data && tipsObj.data.Id
                }"
                slot="custom"
            >
              <span class="text">{{tipsObj.data && tipsObj.data.Id ? tipsObj.data.name : "请选择要替换的标签"}}</span>
              <i class="iconfont icon_arrow_down"></i>
            </div>
          </label-draggable-popover>
          <p class="tip-p">再删除或直接删除！</p>
          <div class="tips-box">
            1、若不替换直接删除，相关案例的该标签信息将会丢失 <br/>
            2、若该标签下有子标签，子标签也将一并被删除，请知晓
          </div>
        </div>
      </ConTip>
      <AddLabel
        :isSubsetOperate="isSubsetOperate"
        :showAddLabelDig="showAddLabelDig"
        :curLabel="curLabel"
        @handleClose="closeAddDig"
      ></AddLabel>
    </main>
  </ConDig>
</template>

<script>
import {
  GetBranchLabelList_DJ,
  ReplaceBranchLabelDetailInfoNew_DJ,
  RemoveLabelInfoByBranchId_DJ,
  DeleteBranchLabelDetailInfoNew_DJ,
  lableDoSort,
  operateBranchLabelInfo,
} from "@/api/case";
import AddLabel from "../addLable.vue";
import { mapState } from "vuex";
import LabelDraggable from "@/views/case/components/label-mange/label-draggable.vue";
import LabelDraggablePopover from "@/views/case/components/label-mange/label-draggable-popover.vue";
export default {
  data() {
    return {
      showAddLabelDig: false, //是否显示新增(编辑)颜色弹窗
      isLodding: false,
      showTipDig: false, //是否显示删除提示
      labelList: [],
      curLabel: null, //当前操作颜色
      cosIp: sessionStorage.getItem("BranchCosCdnUrl_B"),
      celebrateTypeList: [], // 类型
      curType: '',
      oldLabelList: [],
      isLoading: true,
      isSubsetOperate: '', // 是子集 的 编辑 与 添加
      btnLoading: false,
      // 提示框
      tipsObj: {
        cancelText: '再想想',
        confirmText: '删除',
        confirmType: 'err',
        tipText: '',
        type: 'warn',
        operateType: '',
        data: {}
      },
      replaceLabelList: [], // 替换时的标签数组
      selValue: [],
    };
  },
  props: {
    showManageDig: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LabelDraggablePopover,
    LabelDraggable,
    AddLabel,
  },
  computed: {
    ...mapState({
      allLabelList: state => state.caseR.allLabelList, // 标签 all 展开成一级
      theme: state => state.theme
    }),
  },
  created() {},
  methods: {
    /**
     * 移动
     */
    moveChange(data,item) {
      const id = data && data.length > 0 ? data[data.length - 1] : 0;

      if (id && id === item.ParentId) {
        this.$message('该标签就在选中的标签下，无需移动');
        return;
      }

      if (id && id === item.Id) {
        this.$message('不能移动到当前标签下，请重新选择');
        return;
      }

      if (item.FileCount > 0) {
        this.tipsObj = {
          cancelText: '再想想',
          confirmText: '确认',
          confirmType: 'con',
          tipText: '是否将相关案例统一移动至其他标签下?',
          type: 'warn',
          operateType: 'move',
          data: {
            LabelId: item.Id,
            ReplaceId: id
          }
        }
        this.showTipDig = true;
        return;
      }
      this.moveBabel(item.Id,id);
    },
    /**
     * 替换
     * @param data
     * @param item
     */
    replaceChange(data,item) {
      const id = data && data.length > 0 ? data[data.length - 1] : 0;
      if (id && id === item.Id) {
        this.$message('不能替换到当前标签下，请重新选择');
        return;
      }
      if (item.FileCount > 0) {
        this.tipsObj = {
          cancelText: '再想想',
          confirmText: '确认',
          confirmType: 'con',
          tipText: '是否将相关案例批量替换至其他标签下?',
          type: 'warn',
          operateType: 'replace',
          data: {
            LabelId: item.Id,
            ReplaceId: id
          }
        }
        this.showTipDig = true;
        return;
      }
      this.replaceBabel(item.Id,id);
    },
    /**
     * 该标签有 案例 需要批量替换
     */
    replaceLabelHandler(data) {
      this.replaceLabelList = this.oldLabelList;
      // const typeArr = data.RelationTypeList || [];
      // this.replaceLabelList = this.oldLabelList.filter(c => {
      //   if (
      //       (
      //           !c.RelationTypeList ||
      //           c.RelationTypeList.length <= 0
      //       ) ||
      //       (
      //           c.RelationTypeList &&
      //           c.RelationTypeList.filter(c => typeArr.find(l => l.Id === c.Id)).length > 0
      //       )
      //   ) {
      //     return c
      //   }
      // })
      if (this.replaceLabelList.length <= 0) {
        this.$message('可替换的标签列表为空');
        return;
      }

      this.$bus.$emit('label-draggable-close-popover', []);
    },
    // 点击icon图标
    iconClick(e) {
      const  { type = '', data = {} } = e;
      this.isSubsetOperate = '';

      // 添加子集
      if (type === 'add') {
        this.isSubsetOperate = 'add';
        this.curLabel = data;
        this.curLabel.parent_Id = data.Id; // 父级
        this.showAddLabelDig = true;
        return;
      }

      // 编辑
      if (type === 'edit') {
        // 子集
        if (data && data.NowLevel > 0) {
          this.isSubsetOperate = 'edit';
        }
        this.curLabel = data;
        this.showAddLabelDig = true;
        return;
      }

      // 删除
      if (type === 'del') {
        this.curLabel = data;
        // 该标签下 或者 子级标签 有案例的情况
        if (data.FileAllCount > 0) {
          this.selValue = [];
          this.replaceLabelList = this.oldLabelList;
          // const typeArr = data.RelationTypeList || [];
          // this.replaceLabelList = this.oldLabelList.filter(c => {
          //   if (
          //       (
          //           !c.RelationTypeList ||
          //           c.RelationTypeList.length <= 0
          //       ) ||
          //       (
          //           c.RelationTypeList &&
          //           c.RelationTypeList.filter(c => typeArr.find(l => l.Id === c.Id)).length > 0
          //       )
          //   ) {
          //     return c
          //   }
          // })
          if (this.replaceLabelList.length <= 0) {
            this.$message('可替换的标签列表为空');
            return;
          }

          this.tipsObj = {
            cancelText: '再想想',
            confirmText: '删除',
            confirmType: 'err',
            tipText: '该标签下有案例，您可以将相关案例的标签替换为',
            type: 'warn',
            operateType: 'del-replace',
            data: {}
          }
        } else {
          this.tipsObj = {
            cancelText: '再想想',
            confirmText: '删除',
            confirmType: 'err',
            tipText: `若该标签下有子标签，子标签也将一并被删除<br/>确认要删除吗？`,
            type: 'warn',
            operateType: 'del',
            data: {}
          }
        }
        this.showTipDig = true;
      }
    },
    /**
     * 删除时 选择需要替换到哪个标签
     * @param data
     * @param item
     */
    delReplaceChange(data,item) {
      this.selValue = data;
      const id = data && data.length > 0 ? data[data.length - 1] : 0;

      if (id && id === item.Id) {
        this.$message('不能替换到当前标签下，请重新选择');
        return;
      }
      const row = this.allLabelList.find(c => c.Id === id);

      if (
          row.value &&
          row.value.find(c => c === item.Id)
      ) {
        this.$message('不能替换到当前标签下的子标签，请重新选择');
        return;
      }

      this.tipsObj.data = row ? row : {};
      this.closeDelReplacePopover(this.curLabel && this.curLabel.Id ? this.curLabel.Id : '');
    },
    /**
     * 直接删除标签
     */
    delLabelHttp() {
      if (this.btnLoading) {
        return;
      }
      this.btnLoading = true;
      if (!this.curLabel || !this.curLabel.Id) {
        this.$Error("数据异常,请刷新重试");
        this.showTipDig = false;
        return;
      }
      DeleteBranchLabelDetailInfoNew_DJ({
        LabelId: this.curLabel.Id,
      }).then((res) => {
        this.btnLoading = false;
        if (res.status) {
          this.getFileALBQInfo('refresh');
          this.isChange = true;
        } else {
          this.$Error(res.msg ? res.msg : "删除失败");
        }
        this.showTipDig = false;
      }).catch((err) => {
        this.btnLoading = false;
        console.log(err);
        this.$Error("删除失败");
        this.showTipDig = false;
      });
    },
    /**
     * 删除时 先替换标签
     * @param LabelId
     * @param ReplaceId
     */
    delReplaceLabelHttp(LabelId, ReplaceId) {
      if (this.btnLoading) {
        return;
      }
      this.btnLoading = true;
      const params = {
        LabelId: LabelId,
        ReplaceId: ReplaceId
      }
      DeleteBranchLabelDetailInfoNew_DJ(params)
          .then(res => {
            this.showTipDig = false;
            this.btnLoading = false;
            if (res && res.status) {
              this.getFileALBQInfo('refresh');
              this.isChange = true;
            } else {
              this.$Error(res.msg || "替换失败");
            }
          })
          .catch((err) => {
            this.showTipDig = false;
            this.btnLoading = false;
            this.$Error("替换失败");
          });
    },
    closeDelReplacePopover(id) {
      this.$nextTick(() => {
        if (this.$refs['del-replace-popover']) {
          this.$refs['del-replace-popover'].closePopover(id + '');
        }
      })
    },
    /**
     * 替换标签
     */
    replaceBabel(LabelId,ReplaceId) {
      if (this.btnLoading) {
        return;
      }
      this.btnLoading = true;
      const params = {
        LabelId: LabelId,
        ReplaceId: ReplaceId
      }
      ReplaceBranchLabelDetailInfoNew_DJ(params)
        .then(res => {
          this.btnLoading = false;
          if (res && res.status) {
            this.getFileALBQInfo('refresh');
            this.isChange = true;
          } else {
            this.$Error(res.msg || "替换失败");
          }
        })
        .catch((err) => {
          this.btnLoading = false;
          this.$Error("替换失败");
        });
    },
    /**
     * 移动标签
     */
    moveBabel(LabelId,ReplaceId) {
      if (this.btnLoading) {
        return;
      }
      this.btnLoading = true;
      const params = {
        ReplaceId: ReplaceId,
        LabelId: LabelId,
      }
      RemoveLabelInfoByBranchId_DJ(params)
        .then(res => {
          this.btnLoading = false;
          if (res && res.status) {
            this.isChange = true;
            this.getFileALBQInfo('refresh');
          } else {
            this.$Error(res.msg || "移动失败");
          }
        }).catch((err) => {
          this.btnLoading = false;
          this.$Error("移动失败");
        });
    },
    async initData() {
      this.curType = '';
      this.isChange = false;
      await this.getDicOrderTypeList();
      this.getFileALBQInfo();
    },
    /**
     * 切换类型
     */
    tabType(item, index) {
      this.curType = item ? item.Id : '';
      let list = [];
      if (item.Id !== '') {
        this.oldLabelList.forEach(c => {
          if (
              c.RelationTypeList &&
              !!c.RelationTypeList.find(l => l.Id === item.Id)
          ) {
            list.push(c);
          }
        })
      } else {
        list = this.$Clone(this.oldLabelList);
      }
      this.labelList = list;
      this.isMoveBoxScroll(index);
      this.$forceUpdate();
    },
    isMoveBoxScroll(index) {
      const typeItem = this.$refs.minTypeItem[index];
      const moreBtnBox = this.$refs.moreBtnBox;
      if (!typeItem || !moreBtnBox) {
        return;
      }
      const itemInfo = typeItem.getBoundingClientRect();
      const boxInfo = moreBtnBox.getBoundingClientRect();
      if (!itemInfo || !boxInfo) {
        return;
      }
      // 翻左边
      if (
          itemInfo.left < boxInfo.left + 40
      ) {
        if (
            index <= this.celebrateTypeList.length - 1 - 2 && index > 0
        ) {
          const typeItem_1 = this.$refs.minTypeItem[index - 1];
          this.$refs.moreBtnBox.scrollLeft = this.$refs.moreBtnBox.scrollLeft - typeItem.clientWidth - typeItem_1.clientWidth;
        } else {
          this.$refs.moreBtnBox.scrollLeft -= typeItem.clientWidth;
        }
      } else if (
          itemInfo.left + itemInfo.width > boxInfo.left + boxInfo.width - 40
      ) { // 翻右边
        console.log(index)
        if (
            index >= 1 && index < this.celebrateTypeList.length - 2
        ) {
          const typeItem_1 = this.$refs.minTypeItem[index + 1];
          this.$refs.moreBtnBox.scrollLeft += typeItem.clientWidth + typeItem_1.clientWidth;
        } else {
          this.$refs.moreBtnBox.scrollLeft += typeItem.clientWidth;
        }
      }
    },
    /**
     * 数据格式化
     * @param list
     */
    dataFormat(list) {
      if (list && list.length > 0) {
        list.forEach(c => {
          if (!c.ChildList || c.ChildList.length <= 0) {
            delete c.ChildList;
          } else {
            if (c.NowLevel === 0) {
              c.ChildList && c.ChildList.length > 0 ? c.list = c.ChildList : null;
            } // 没有第三级的情况
            this.dataFormat(c.ChildList);
          }
        })
      }
    },
    /**
     * 获取当前的
     */
    getTypeLabelNum() {
      this.celebrateTypeList.forEach(type => {
        type.labelNum = 0;
        if (type.Id) {
          this.oldLabelList.forEach(label => {
            if (
                label &&
                label.RelationTypeList &&
                label.RelationTypeList.find(c => c.Id === type.Id)
            ) {
              type.labelNum = type.labelNum + 1;
            }
          })
        } else {
          type.labelNum = this.oldLabelList.length
        }
      })
      this.$forceUpdate();
    },
    /**
     * 提示信息
     */
    tipsConfirm() {
      // 确认删除标签
      if (this.tipsObj.operateType === 'del') {
        this.delLabelHttp();
        return;
      }

      // 删除并替换标签
      if (this.tipsObj.operateType === 'del-replace') {
        this.delReplaceLabelHttp(this.curLabel.Id,this.tipsObj.data.Id);
        return;
      }

      // 确认移动
      if (this.tipsObj.operateType === 'move') {
        this.moveBabel(this.tipsObj.data.LabelId,this.tipsObj.data.ReplaceId);
        return;
      }

      // 确认替换
      if (this.tipsObj.operateType === 'replace') {
        this.replaceBabel(this.tipsObj.data.LabelId,this.tipsObj.data.ReplaceId);
        return;
      }
    },
    /**
     * 关闭新增标签弹窗
     */
    closeAddDig(isChange = false) {
      this.isSubsetOperate = '';
      if (isChange) {
        //刷新数据
        this.isChange = true;
        this.getFileALBQInfo('refresh');
      }
      this.showAddLabelDig = false;
    },
    /**
     * 获取标签列表
     */
    getFileALBQInfo(type) {
      this.labelList = [];
      this.isLoading = true;
      GetBranchLabelList_DJ({
        IsSearchFileCount: true
      }).then((res) => {
        this.isLoading = false;
        if (res.status) {
          let labelList = res.data || [];
          this.dataFormat(labelList);

          this.labelList = labelList;
          this.oldLabelList = this.$Clone(labelList);

          this.$store.commit('caseR/setBranchLables', this.oldLabelList);

          this.getTypeLabelNum(); // 获取每个类型下多少个标签

          if (type && type === 'refresh') {
            const itemType = this.celebrateTypeList.find(c => c.Id === this.curType);
            const itemTypeIndex = this.celebrateTypeList.findIndex(c => c.Id === this.curType);
            this.tabType(itemType, itemTypeIndex);
          }
          this.$forceUpdate();
        } else {
          this.$Error(res.msg ? res.msg : "获取标签失败");
        }
      })
      .catch((err) => {
        this.isLoading = false;
        console.log(err);
        this.$Error("获取标签失败");
      });
    },
    /**
     * 获取字典列表(庆典类型)
     */
    getDicOrderTypeList() {
      return new Promise(resolve => {
        this.$axios({
          url: "NewWeddingApi/SystemModule/Dic/Dic/GetDicOrderTypeList",
          method: "POST",
          data: {
            SearchBranchId: sessionStorage.getItem("BranchId_B"),
            IsSearchPCShow: true
          },
          success(res) {
            resolve()
            if (res && res.data.status) {
              const data = res.data.data && res.data.data.length > 0 ? res.data.data : [];
              const list = data.filter(c => c.Isused);
              this.celebrateTypeList = [
                {
                  Name: '全部',
                  Id: ''
                },
                ...list
              ]
            } else {
              this.$Error(res.msg || "庆典类型获取失败");
            }
          },
          error(err) {
            resolve()
            this.$Error("庆典类型获取失败");
            console.log(err);
          },
        });
      })
    },
    /**
     * 刷新排序后的数据
     * @param list
     */
    refreshSort(list) {
      this.isChange = true;
      this.labelList = this.$Clone(list);
      this.oldLabelList = this.$Clone(list);
    },
    setIconGroup(data) {
      const { ChildList = [] } = data
      return [
        { type: 'edit', icon: 'iconfont icon_edit', text: '编辑' },
        { type: 'add', icon: 'iconfont icon_tjzj', text: '添加子标签' },
        ...( !ChildList.length ? [{ type: 'move', icon: 'iconfont icon_move', text: '移动标签' }] : [] ),
        { type: 'del', icon: 'iconfont icon_delete', text: '删除' }
      ]
    },
    handleClose() {
      if (this.isChange) {
        this.getFileALBQInfo();
      }
      this.$emit("handleClose", this.isChange);
    },
    /**
     * 关闭提示
     */
    closeTip() {
      this.$bus.$emit('label-draggable-close-popover');
      // this.curLabel && this.curLabel.Id ? this.closeDelReplacePopover(this.curLabel.Id) : null;
      this.showTipDig = false;
    },
  },
};
</script>

<style scoped lang="scss">
.item-name {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 600;
  color: #666666;
  line-height: 20px;
  height: 20px;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include themify($themes) {
    color: themed("hotel-color");
  }
}
.item-tip {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  padding: 0 4px;
  margin-left: 4px;
  @include themify($themes) {
    color: themed("search-item-color");
    border-color: themed("search-his-bg-h");
    border-radius: themed("border-radius-min");
  }
}
.type-div {
  color: #999;
  font-size: 12px;
  padding: 0 18px;
}

.search-type-list {
  position: relative;
  scroll-behavior: smooth;
  border-bottom: 1px solid #EEEEEE;
  margin-bottom: 20px;
  height: 40px;
  @include themify($themes) {
    border-color: themed("search-his-bg-h");
  }
  div {
    width: calc(100% - 48px);
    height: 40px;
    margin: 0 24px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    &::-webkit-scrollbar {
      height: 0;
    }
  }
  .type-item {
    display: inline-block;
    font-size: 14px;
    color: #999999;
    height: 40px;
    line-height: 40px;
    padding: 0 8px;
    cursor: pointer;
    position: relative;
    &.active {
      font-weight: 600;
      color: #222222;
      @include themify($themes) {
        color: themed("input-color");
      }
    }
    &.active::after {
      content: '';
      height: 2px;
      width: 100%;
      background: #222;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
  }
}
.color-manage-box {
  padding: 16px 0;
  height: 540px;
  .add-btn {
    margin-left: 24px;
    margin-bottom: 16px;
  }
  .box {
    height: 430px;
  }
  .color-item-box {
    width: 100%;
    height: 430px;
    overflow: auto;
    padding: 0 16px;
    box-sizing: border-box;
    .label-item-div {
      min-height: 40px;
    }
    .color-item {
      width: 100%;
      min-height: 40px;
      background: #fff;
      box-sizing: border-box;
      @include themify($themes) {
        background: themed("main-bg");
      }
      .second-level .color-item-step {
        padding-left: 28px;
        //padding-right: 20px;
      }
      .three-level .color-item-step {
        padding-left: 48px;
      }
      .color-item-step {
        width: 100%;
        padding: 8px 4px 8px 0;
        box-sizing: border-box;
        position: relative;
        &:hover {
          background: #eeeeee;
          @include themify($themes) {
            background: themed("hover-bg");
          }
          .btns-box {
            opacity: 1;
          }
        }
        &.haveType {
          height: 56px;
          .type-div {
            display: block;
          }
        }
      }
      .icon_arrow_right {
        color: #999;
        font-size: 20px;
        transform: rotate(0deg);
        transition: transform .3s ease-in-out;
      }
      .icon_arrow_right.down {
        transform: rotate(90deg);
      }
      .type-div {
        display: none;
        color: #999;
        font-size: 12px;
        margin-top: 2px;
        padding: 0 18px;
      }
      .info {
        //height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &.pd-l {
          padding-left: 18px;
        }
      }

      .btns-box {
        //position: absolute;
        //right: 0;
        opacity: 0;
      }
      .btn-div {
        display: flex;
        position: absolute;
        right: 0;
      }
    }
  }
}
.no-data {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    margin-top: 20px;
    font-size: 18px;
    color: #999;
  }
  img {
    width: 140px;
  }
}
.replace-list {
  position: relative;
  .title-div {
    width: 120px;
    color: #222222;
    line-height: 20px;
    padding-left: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
  }
  .left {
    background: #fff;
    width: 120px;
    padding: 8px 0;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  }
  .second-div {
    width: 120px;
    max-height: 300px;
    overflow-y: auto;
    background: #fff;
    position: absolute;
    left: 130px;
    top: 0;
    padding: 8px 0;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  .item {
    position: relative;
    box-sizing: border-box;
    padding-left: 28px;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    &:hover {
      background: #F5F5F5;
      color: #222222;
    }
    .iconfont {
      position: absolute;
      right: 4px;
      color: #ccc;
      font-size: 20px;
    }
    .icon_gou2 {
      position: absolute;
      left: 4px;
      color: #222222;
    }
  }
}
.replace-first-level {
  padding: 8px 0;
  background: #fff;
  max-height: 300px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.select-replace {
  width: 240px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #EEEEEE;
  padding-left: 8px;
  padding-right: 4px;
  color: #999999;
  margin: 8px auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  @include themify($themes) {
    border-color: themed('cancel-btn-border');
  }
  .text {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &.active {
    color: #222;
    @include themify($themes) {
      color: themed("input-color");
    }
  }
  i.iconfont {
    color: #AEAEAE;
    font-size: 18px;
  }
}
.el-icon-warning-outline {
  margin-left: 6px;
  color: #ccc;
  font-size: 14px;
  @include themify($themes) {
    color: themed("font-color-disable");
  }
  &:hover {
    color: #333;
    @include themify($themes) {
      color: themed("input-color");
    }
  }
}
.select-span {
  height: 20px;
  background: #444444;
  color: #999999;
  line-height: 20px;
  cursor: pointer;
  padding: 0 4px;
}
.replace-del-div {
  .tip-p {
    font-size: 16px;
    font-weight: 600;
    color: #222222;
    line-height: 24px;
    margin-top: 8px;
    text-align: center;
    @include themify($themes) {
      color: themed("input-color");
    }
  }
  .tips-box {
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    background: #EEEEEE;
    margin: 8px auto 0;
    padding: 8px;
    overflow: hidden;
    @include themify($themes) {
      color: themed("hotel-color");
      background: themed("hover-bg");
    }
  }
}
.tips-i {
  background: #333333;
  padding: 12px;
  color: #fff;
}
</style>
