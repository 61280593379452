/**
 * 长按指令
 */
export default {
    bind: function(el, binding, vNode) {
        // 确保传入的值是一个对象
        let args = {
            cb: function () {},
            interval: 1000
        }
        if (Object.prototype.toString.call(binding.value) !== '[object Object]') {
            // 获取组件名称
            const compName = vNode.context.name;
            let warn = `[longPress:] provided expression '${binding.expression}' is not a object, but has to be `;
            if (compName) { warn += `Found in component '${compName}' `}

            console.warn(warn);
        } else {
            Object.assign(args, binding.value)
        }

        // 定义变量
        let pressTimer = null;

        // 定义函数处理程序
        // 创建计时器（ 1秒后执行函数 ）
        let start = (e) => {

            if (e.type === 'click' && e.button !== 0) {
                return;
            }

            if (pressTimer === null) {
                pressTimer = setTimeout(() => {
                    // 执行函数
                    handler();
                }, args.interval)
            }
        }

        // 取消计时器
        let cancel = () => {

            // 检查计时器是否有值
            if ( pressTimer !== null ) {
                clearTimeout(pressTimer);
                pressTimer = null;
            }
        }

        // 运行函数
        const handler = (e) => {
            // 执行传递给指令的方法
            args.cb && args.cb(e)
        }

        el.start = start;
        el.cancel = cancel;

        // 添加事件监听器
        el.addEventListener("mousedown", start);
        el.addEventListener("touchstart", start);

        // 取消计时器
        el.addEventListener("click", cancel);
        el.addEventListener("mouseout", cancel);
        el.addEventListener("touchend", cancel);
        el.addEventListener("touchcancel", cancel);
    },
    // 指令与元素解绑的时候，移除事件绑定
    unbind(el) {
        el.removeEventListener('mousedown', el.start);
        el.removeEventListener('touchstart', el.start);

        el.removeEventListener('click', el.start);
        el.removeEventListener('mouseout', el.cancel);
        el.removeEventListener('touchend', el.cancel);
        el.removeEventListener("touchcancel", el.cancel);
    },
}
