<template>
  <draggable
      :forceFallback='true'
      v-model="labelList"
      :options="{
        animation: 500,
        ghostClass: 'sortable-ghost',
        chosenClass: 'sortable-chosen',
        handle: '.paixu-icon',
      }"
      :draggable="'.' + draggableGroupName"
      @change="change"
      @refreshSort="refreshSort"
      :class="[level === 0 ? 'color-item-box small-scroll' : '']"
  >
    <div
        class="level-item"
        :class="draggableGroupName"
        v-for="(item,i) in labelList"
        :key="item.Id + '-loop-group'"
        :style="{
          'padding-left': level * 18 + 'px'
        }"
    >
      <div class="content" @click="expand(item)">
        <div class="title-level-div">
          <div class="left">
            <div
                class="arrow-icon"
                :class="{
                  'padding': level < 2 && (!item.ChildList || !item.ChildList.length),
                  'padding-left': level >= 2
                }"
            >
              <i
                  class="iconfont icon_arrow_right"
                  :class="{'down': expandId === item.Id}"
                  v-if="item.ChildList && item.ChildList.length"
              />
            </div>
            <div class="label-content">
              <slot name="label-content" v-bind:info="item"></slot>
            </div>
          </div>
          <div class="right">
            <div class="icon-group"
                 v-bind:_="_ = getIconGroup(item)"
            >
              <template v-for="(it, ix) in _">
                <template v-if="it.type !== 'move'">
                  <ConIcon
                      :icon="it.icon"
                      :tip-text="it.text"
                      @click="iconClick(it.type, item)"
                  ></ConIcon>
                </template>
                <slot name="move-popover" v-bind:info="item" v-else></slot>
              </template>
            </div>
            <ConIcon
                class="paixu-icon"
                icon="iconfont paixu"
                tipText="排序"
                v-if="!curType"
            ></ConIcon>
          </div>
        </div>
        <slot name="label-type" v-bind:info="item"></slot>
      </div>
      <template v-if="item.ChildList && item.ChildList.length && expandId === item.Id">
        <label-draggable
            :ref="'item-' + item.Id"
            :list="item.ChildList"
            :get-icon-group="getIconGroup"
            :level="item.NowLevel + 1"
            :pid="item.Id"
            :curType="curType"
            @refreshSort="(list) => refreshSort(item,list)"
            @icon-click="(e) => $emit('icon-click', e)"
        >
          <template v-slot:label-content="{ info }">
            <slot name="label-content" v-bind:info="info">{{ info.LabelContent }}</slot>
          </template>
          <template v-slot:move-popover="{ info }">
            <slot name="move-popover" v-bind:info="info"></slot>
          </template>
        </label-draggable>
      </template>
    </div>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import tools from "@/assets/js/config/throttle";
import {lableDoSort} from "@/api/case";

export default {
  name: "label-draggable",
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    // 切换标签类型
    curType: {
      type: String,
      default: '',
    },
    level: {
      type: Number,
      default: 0
    },
    pid: {
      type: Number,
      default: 0
    },
    getIconGroup: {
      type: Function,
      default() {
        /* 获取可操作的按钮组的方法
         * exmple: ['edit', 'add', 'move', 'del']
         * exmple: [
         *  { type: 'edit', icon: 'iconfont icon_edit', text: '编辑' }
         * ]
         */
        return function () {
          return []
        }
      }
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      expandId: undefined,
      closePopover: false,
      moveComponents: {},
      labelList: [],
      isUnfold: ''
    }
  },
  computed: {
    draggableGroupName() {
      return 'level-' + (this.level + 1) + '-item'
    }
  },
  watch: {
    list: {
      handler() {
        this.labelList = this.$Clone(this.list);
        if (this.level === 0) {
          this.computeMoveComponents()
        }
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    expand(data) {
      this.expandId = data.Id === this.expandId ? undefined : data.Id
    },
    // 计算所有带有移动功能的组件
    computeMoveComponents() {
      const components = {}
      const loopFunc = (list) => {
        list.reduce((obj, item) => {
          if (!(item.ChildList && item.ChildList.length)) {
            obj[item.Id] = false
          } else {
            loopFunc(item.ChildList)
          }
          return obj
        }, components)
      }
      loopFunc(this.labelList)

      this.moveComponents = components
    },
    iconClick(type, data) {
      this.$emit('icon-click', {type, data})
    },
    /**
     * 刷新排序后的数据
     * @param item
     * @param list
     */
    refreshSort(item,list) {
      if (
          item.ChildList &&
          item.ChildList.length > 0
      ) {
        item.ChildList = list
      }
      this.$emit('refreshSort', this.labelList);
    },
    /**
     * 排序
     * @param evt
     */
    change(evt) {
      let changeId = evt.moved.element.Id;
      let firstId = 0;
      let secondId = 0;
      if (evt.moved.newIndex > 0) {
        firstId = this.labelList[evt.moved.newIndex - 1].Id;
      }
      if (evt.moved.newIndex < this.labelList.length - 1) {
        secondId = this.labelList[evt.moved.newIndex + 1].Id;
      }
      lableDoSort({
        ChangeId: changeId,
        FirstId: firstId,
        SecondId: secondId,
      })
        .then((res) => {
          if (res.status) {
            this.$emit('refreshSort', this.labelList);
            this.$forceUpdate();
          } else {
            this.$Error(res.msg ? res.msg : "排序失败");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Error("排序失败");
        });
    },
    listenerPopoverStateChange({ type, id } = {}) {
      if (['after-enter', 'after-leave'].includes(type)) {
        this.moveComponents[id] = type === 'after-enter'
      }
    }
  },
  created() {
    if (this.level === 0) {
      const listenerScroll = () => {
        const keys = Object.keys(this.moveComponents)
        const values = Object.values(this.moveComponents)
        const status = values.some(i => !!i)
        // 需要关闭浮窗
        if (status) {
          this.$bus.$emit('label-draggable-close-popover', values.reduce((arr, item, index) => {
            if (item) {
              arr.push(keys[index])
            }
            return arr
          }, []))
        }
      }
      // 监听主区域的滚动，以及 父子之间的传值
      this.$on('hook:mounted', () => {
        this.$el.addEventListener('scroll', tools.throttle(listenerScroll))
        this.$bus.$on('label-draggable-popover-status-change', this.listenerPopoverStateChange)
      })
      this.$on('hook:beforeDestroy', () => {
        this.$el.removeEventListener('scroll', tools.throttle(listenerScroll))
        this.$bus.$off('label-draggable-popover-status-change', this.listenerPopoverStateChange)
      })
    }
  },
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
.content {
  padding: 8px 0;
  cursor: pointer;
  @include themify($themes) {
    background: themed("main-bg");
  }
  &:hover {
    background: #eeeeee;
    @include themify($themes) {
      background: themed("hover-bg");
    }
    .title-level-div .right .icon-group {
      opacity: 1;
    }
  }
  .title-level-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    line-height: 28px;
    overflow: hidden;
    .left {
      flex: 1;
      display: flex;
      align-items: center;

      .arrow-icon {
        &.padding {
          padding: 0 9px;
        }
        &.padding-left {
          padding-left: 8px;
        }
        .icon_arrow_right {
          font-size: 20px;
          color: #999;
          display: inline-block;
          width: 100%;
          height: 100%;
          transform: rotate(0deg);
          transition: transform .3s ease-in-out;

          &.down {
            transform: rotate(90deg);
          }
        }
      }
    }
    .right {
      position: absolute;
      right: 0;
      display: flex;
      align-items: stretch;
      justify-content: flex-end;
      .icon-group {
        opacity: 0;
        align-items: center;
        justify-content: flex-end;
        background: #eeeeee;
        @include themify($themes) {
          background: themed("hover-bg");
        }
      }
    }
  }
}
.label-content {
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
