
import config from "@/assets/js/config/config";
import { httpServe } from '@/assets/js/utils/request';

/**
 * 获取收藏夹类型列表
 */
export function BaseCollectList(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/NewWeddingManage/BaseCollect/BaseCollectList`,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        method: 'POST',
        data: params
    });
}

/**
 * 删除收藏夹
 */
export function BaseCollectDel(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/NewWeddingManage/BaseCollect/BaseCollectDel`,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        method: 'POST',
        data: params
    });
}

/**
 * 获取收藏夹内容明细
 */
export function GetBaseCollectSubDetailList(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/NewWeddingManage/BaseCollect/GetBaseCollectSubDetailList`,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        method: 'POST',
        data: params
    });
}

/**
 * 收藏夹排序
 */
export function BaseCollectSort(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/NewWeddingManage/BaseCollect/BaseCollectSort`,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        method: 'POST',
        data: params
    });
}

/**
 * 新增、编辑收藏夹
 */
export function SaveCollectModel(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/NewWeddingManage/BaseCollect/SaveCollectModel`,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        method: 'POST',
        data: params
    });
}

/**
 * 获取标签列表
 */
export function GetSquareLabelList(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/BaseSquareManage/BaseSquareScreen/GetSquareLabelList`,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        method: 'POST',
        data: params
    });
}

/**
 * 加入收藏夹
 */
export function SaveBaseCollectSubModel(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/NewWeddingManage/BaseCollect/SaveBaseCollectSubModel`,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        method: 'POST',
        data: params
    });
}

/**
 * 获取广场是否存在最新显示数据
 */
export function GetBaseSquareListIsShowDot(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/BaseSquareManage/BaseSquareScreen/GetBaseSquareListIsShowDot`,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        method: 'GET',
        data: params
    });
}

/**
 * 获取指定机构和当前机构的关系信息
 */
export function GetBaseSettingModel(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/BaseSettingManage/BaseSetting/GetBaseSettingModel`,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        method: 'GET',
        data: params
    });
}


/**
 * 获取内容资讯  视频/图片
 */
export function UploadConsultFileDetail(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/InternalConsultManage/InternalConsult/UploadConsultFileDetail`,
        method: 'POST',
        data: params
    });
}
