var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ConDig',{attrs:{"title":"作品标签管理","width":"480px","isShowDig":_vm.showManageDig,"loading":_vm.isLodding,"appendToBody":true,"isShowFooter":false},on:{"open":_vm.initData,"closeDig":_vm.handleClose,"cancelClick":_vm.handleClose}},[_c('main',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"color-manage-box",attrs:{"slot":"dialogMain"},slot:"dialogMain"},[_c('el-button',{staticClass:"con-button add-btn",attrs:{"icon":"iconfont icon_add_big"},on:{"click":function($event){_vm.curLabel = null;
        _vm.showAddLabelDig = true;}}},[_vm._v("新增标签")]),_c('div',{staticClass:"search-type-list"},[_c('div',{ref:"moreBtnBox"},_vm._l((_vm.celebrateTypeList),function(item,index){return _c('span',{key:'type-s-' + index,ref:"minTypeItem",refInFor:true,staticClass:"type-item",class:{
              'active': _vm.curType === item.Id
            },on:{"click":function($event){return _vm.tabType(item, index)}}},[_vm._v(_vm._s(item.Name)+"("+_vm._s(item.labelNum)+")")])}),0)]),(_vm.labelList.length > 0)?_c('div',{staticClass:"box"},[_c('label-draggable',{attrs:{"list":_vm.labelList,"curType":_vm.curType + '',"getIconGroup":_vm.setIconGroup},on:{"refreshSort":_vm.refreshSort,"icon-click":_vm.iconClick},scopedSlots:_vm._u([{key:"label-content",fn:function(ref){
            var info = ref.info;
return [_c('span',{staticClass:"item-name"},[_vm._v(" #"+_vm._s(info.LabelContent)+" ")]),(info.IsFolderShow)?_c('span',{staticClass:"item-tip"},[_vm._v("以文件夹展示")]):_vm._e(),(info.IsHideHost)?_c('span',{staticClass:"item-tip"},[_vm._v("未公开")]):_vm._e(),(info.FileCount && info.ChildList && info.ChildList.length > 0)?_c('label-draggable-popover',{key:'tips' + info.Id,ref:'tips-'+ info.Id,attrs:{"className":"tips-label","trigger":"click","info":info,"visible-arrow":true,"customContent":true,"custom":true},on:{"hide":function($event){_vm.$bus.$emit('label-draggable-close-popover', [info.Id.toString()]);}}},[_c('div',{staticClass:"tips-i",attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"title"},[_vm._v(" 有"+_vm._s(info.FileCount)+"个案例直属于该标签，您可以批量将其 ")]),_c('div',{staticClass:"title"},[_vm._v(" 添加至 "),_c('label-draggable-popover',{key:'replaceBabel' + info.Id,attrs:{"info":info,"custom":true,"allChild":true,"list":_vm.replaceLabelList},on:{"moveChange":function (data) { return _vm.replaceChange(data,info); }}},[_c('span',{staticClass:"select-span",attrs:{"slot":"custom"},slot:"custom"},[_vm._v("选择标签 "),_c('i',{staticClass:"iconfont icon_arrow_down"})])]),_vm._v(" 便于查找案例 ")],1)]),_c('i',{staticClass:"el-icon-warning-outline",attrs:{"slot":"custom"},on:{"click":function($event){$event.stopPropagation();return _vm.replaceLabelHandler(info)}},slot:"custom"}),_vm._v("1 ")]):_vm._e()]}},{key:"label-type",fn:function(ref){
            var info = ref.info;
return [(info.RelationTypeList && info.RelationTypeList.length > 0)?_c('div',{staticClass:"type-div"},[_vm._v(" "+_vm._s(info.RelationTypeList.map(function (c) { return c.Name; }).join('/'))+" ")]):_vm._e()]}},{key:"move-popover",fn:function(ref){
            var info = ref.info;
return [_c('label-draggable-popover',{key:'move' + info.Id,attrs:{"info":info,"isMove":true,"list":_vm.oldLabelList},on:{"moveChange":function (data) { return _vm.moveChange(data,info); }}})]}}],null,false,2979191961)}),(!_vm.isLoading && _vm.labelList.length <= 0)?_c('div',{staticClass:"no-data"},[_c('img',{attrs:{"src":_vm.theme === 'Dark' || _vm.theme === 'Plus' ? '/images/team/img_null_search_dark.png' : '/images/team/img_null_search.png',"alt":""}}),_c('p',[_vm._v("没有内容哦")])]):_vm._e()],1):_vm._e(),_c('ConTip',{attrs:{"type":_vm.tipsObj.type,"isShowTip":_vm.showTipDig,"cancelText":_vm.tipsObj.cancelText,"confirmText":_vm.tipsObj.confirmText,"confirmType":_vm.tipsObj.confirmType,"tipText":_vm.tipsObj.tipText},on:{"confirmClick":_vm.tipsConfirm,"closeTipDig":_vm.closeTip,"cancelClick":_vm.closeTip}},[(_vm.tipsObj.operateType === 'del-replace')?_c('div',{staticClass:"replace-del-div",attrs:{"slot":"main"},slot:"main"},[(_vm.curLabel && _vm.curLabel.Id)?_c('label-draggable-popover',{key:'delReplaceBabel' + _vm.curLabel && _vm.curLabel.Id ? _vm.curLabel.Id : '--',ref:"del-replace-popover",attrs:{"info":_vm.curLabel,"custom":true,"allChild":true,"delReplace":true,"selValue":_vm.selValue,"list":_vm.replaceLabelList},on:{"moveChange":function (data) { return _vm.delReplaceChange(data,_vm.curLabel); }}},[_c('div',{staticClass:"select-replace",class:{
                'active': _vm.tipsObj.data && _vm.tipsObj.data.Id
              },attrs:{"slot":"custom"},slot:"custom"},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.tipsObj.data && _vm.tipsObj.data.Id ? _vm.tipsObj.data.name : "请选择要替换的标签"))]),_c('i',{staticClass:"iconfont icon_arrow_down"})])]):_vm._e(),_c('p',{staticClass:"tip-p"},[_vm._v("再删除或直接删除！")]),_c('div',{staticClass:"tips-box"},[_vm._v(" 1、若不替换直接删除，相关案例的该标签信息将会丢失 "),_c('br'),_vm._v(" 2、若该标签下有子标签，子标签也将一并被删除，请知晓 ")])],1):_vm._e()]),_c('AddLabel',{attrs:{"isSubsetOperate":_vm.isSubsetOperate,"showAddLabelDig":_vm.showAddLabelDig,"curLabel":_vm.curLabel},on:{"handleClose":_vm.closeAddDig}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }