import { render, staticRenderFns } from "./slide-tab.vue?vue&type=template&id=5185a859&scoped=true&"
import script from "./slide-tab.vue?vue&type=script&lang=js&"
export * from "./slide-tab.vue?vue&type=script&lang=js&"
import style0 from "./slide-tab.vue?vue&type=style&index=0&id=5185a859&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5185a859",
  null
  
)

export default component.exports