/*
 * @Author: tangshuo
 * @Date: 2021-01-26 18:27:53
 * @LastEditors: tangshuo
 * @LastEditTime: 2022-03-11 16:31:05
 * @Remarks: 案例操作相关api
 */

import config from "@/assets/js/config/config";
import { httpServe } from '@/assets/js/utils/request';
import { Decrypt, Encrypt } from '@/assets/js/utils/crypto-way';


/**
 * 获取当前店铺案例列表
 */
export function getHomeShowPageList(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/HomeShow/GetHomeShowPageList`,
        method: 'POST',
        data: params
    });
}


/**
 * 获取小程序端 作品/案例列表
 */
export function GetHostVideo(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostVideoManage/HostVideo/GetHostVideo`,
        method: 'POST',
        data: params,
        authorizationStr: sessionStorage.getItem('hostAuthStr'),
    });
}

/**
 * 获取小程序端 作品/案例列表 new
 */
export function GetHostVideoNew(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostVideoManage/HostVideo/GetHostVideoNew`,
        method: 'POST',
        data: params,
        authorizationStr: sessionStorage.getItem('hostAuthStr'),
    });
}

/**
 * 标签文件夹列表(合集列表)新
 */
export function GetHostVideoLabelPageList(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostVideoManage/HostVideoLabel/GetHostVideoLabelPageList`,
        method: 'POST',
        data: params,
        authorizationStr: sessionStorage.getItem('hostAuthStr'),
    });
}

/**
 * 获取当前店铺标签案例列表
 */
export function getHomeShowPageListByLabelId(params) {
    return httpServe({
        url: `${config.baseApi}/NewWeddingApi/FileManage/LabelManage/FileShow/GetHomeShowPageListByLabelId`,
        method: 'POST',
        data: params
    });
}

/**
 * 获取当前店铺案例详情
 */
export function getFileDetailList(fileId) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/HomeShow/GetFileDetailList`,
        method: 'POST',
        data: {
            FileId: fileId
        }
    });
}


//获取在线浏览或者编辑的地址
export function GetCHLookUrl(params) {
    return httpServe({
        url: `${config.baseApi}/NewWeddingApi/OrderManage/CHManage/OrderCH/GetCHLookUrl`,
        method: 'POST',
        data: params
    },true);
}

/**
 * 根据案例Id获取订单文件
 */
export function GetOrderFileCategoryByFileId(fileId) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/OrderManage/CHManage/OrderCH/GetOrderFileCategoryByFileId`,
        method: 'POST',
        data: {
            FileId: fileId
        }
    });
}
/**
 * 根据案例Id获取订单文件
 */
export function GetOrderFileCategory(data) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/OrderManage/CHManage/OrderCH/GetOrderFileCategoryByOrderIdNew`,
        method: 'POST',
        data: data
    });
}

/**
 * 收藏/取消收藏案例
 */
export function userFileCollectionInfo(fileId, isUsed) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/HomeShow/UpdateUserFileCollectionInfo`,
        method: 'POST',
        data: {
            FileId: fileId,
            IsUsed: isUsed,
        }
    });
}

/**
 * 获取当前店铺酒店列表
 */
export function getBranchHotelList(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/HomeShow/GetBranchHotelList`,
        method: 'POST',
        data: params
    });
}

/**
 * 获取当前店铺标签列表
 */
export function getFileALBQInfo() {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/HomeShow/GetFileALBQInfo`,
        method: 'POST',
    });
}

/**
 * 获取当前店铺标签列表 新 分级
 */
export function GetBranchLabelList_DJ(params) {
    return httpServe({
        url: `${params.source === 'wechat' ? config.hostApi : config.baseApi}NewWeddingApi/FileManage/LabelManage/BranchLabel/GetBranchLabelList_DJ`,
        method: 'POST',
        data: {
            IsSearchFileCount: params.IsSearchFileCount,
        },
        authorizationStr: params.source === 'wechat' ? sessionStorage.getItem("hostAuthStr") : sessionStorage.getItem('Authorization'),
    });
}

/**
 * 获取当前店铺颜色列表
 */
export function getFileQDYSInfo() {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/HomeShow/GetFileQDYSInfo`,
        method: 'POST'
    });
}

/**
 * 获取当前店铺字典列表 旧的
 */
export function getDicInfoByDicName(dicName) {
    if (!dicName) {
        return new Promise()
    }
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/HomeShow/GetDicInfoByDicName`,
        method: 'POST',
        data: {
            DicName: dicName
        }
    });
}

/**
 * 获取当前店铺字典列表 新的
 */
export function getDicOrderTypeList(SearchBranchId) {
    if (!SearchBranchId) {
        return new Promise()
    }
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/SystemModule/Dic/Dic/GetDicOrderTypeList`,
        method: 'POST',
        data: {
            SearchBranchId: SearchBranchId,
            IsSearchPCShow: true
        }
    });
}
/**
 * 获取当前店铺文件夹
 */
export function getLabelFolder(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/LabelManage/FileShow/GetLabelFileByBranchId`,
        method: 'POST',
        data: params
    });
}

/**
 * 获取文件夹信息新
 */
export function getLabelFileByBranchId_DJ(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/LabelManage/BranchLabel/GetLabelFileByBranchId_DJ`,
        method: 'POST',
        data: params
    });
}

/**
 * 上传案例
 */
export function uploadCase(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/HomeShow/UploadAnLi`,
        method: 'POST',
        data: params
    });
}

/**
 * 编辑案例文件(新增、编辑、删除)
 */
export function updateCaseFile(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/HomeShow/UpdateOrDeleteFileDetail_New`,
        method: 'POST',
        data: params
    });
}

/**
 * 设置案例封面
 */
export function setFileFMImg(params) {
    // {
    //     FileId:案例ID,
    //     FmImg:封面图路径,
    //     FileDetailId: 设置的案例图片ID,
    // }
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/HomeShow/SetFileFMImg`,
        method: 'POST',
        data: params
    });
}

/**
 * 获取案例基础信息
 */
export function getNewFileInfoByFileId(params) {
    // {
    //     FileId:案例ID,
    // }
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/FileManage2.0/FileNew/GetNewFileInfoByFileId`,
        method: 'POST',
        data: params
    });
}

/**
 * 获取案例其他文件信息
 */
export function getOtherFileByFileId(params) {
    // {
    //     FileId:案例ID,
    // }
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/HomeShow/GetOtherFileByFileId_New`,
        method: 'POST',
        data: params
    });
}
/**
 * 修改案例基础信息
 */
export function updateAnLiFile(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/HomeShow/UpdateAnLiFile`,
        method: 'POST',
        data: params
    });
}

/**
 * 获取店铺颜色(排序)
 */
export function getBranchColorList(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/FileColor/GetBranchColorListWithCreate`,
        method: 'POST',
        data: params
    });
}

/**
 * 店铺颜色排序
 */
export function colorDoSort(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/FileColor/ColorDoSort`,
        method: 'POST',
        data: params
    });
}

/**
 * 新增店铺颜色
 */
export function addBranchColor(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/FileColor/AddBranchColor`,
        method: 'POST',
        data: params
    });
}

/**
 * 编辑店铺颜色
 */
 export function updateBranchColor(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/FileColor/UpdateBranchColor`,
        method: 'POST',
        data: params
    });
}
/**
 * 删除店铺颜色
 */
export function deleteBranchColor(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/FileColor/DeleteBranchColor`,
        method: 'POST',
        data: params
    });
}

/**
 * 判断店铺颜色是否有关联
 */
 export function judgeBranchColorRelation(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/FileColor/JudgeBranchColorRelation`,
        method: 'POST',
        data: params
    });
}

/**
 * 修改店铺标签
 */
 export function upLabelDetailInfoNew(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/LabelManage/FileShow/UpLabelDetailInfoNew`,
        method: 'POST',
        data: params
    });
}

/**
 * 添加店铺标签
 */
 export function addBranchLabelDetailInfoNew(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/LabelManage/FileShow/AddBranchLabelDetailInfoNew`,
        method: 'POST',
        data: params
    });
}


/**
 * 添加店铺标签 新 多级
 */
export function AddBranchLabelDetailInfoNew_DJ(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/LabelManage/BranchLabel/AddBranchLabelDetailInfoNew_DJ`,
        method: 'POST',
        data: params
    });
}

/**
 * 替换店铺标签(不替换子级)
 */
export function ReplaceBranchLabelDetailInfoNew_DJ(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/LabelManage/BranchLabel/ReplaceBranchLabelDetailInfoNew_DJ`,
        method: 'POST',
        data: params
    });
}

/**
 * 删除店铺标签多级(支持替换)
 */
export function DeleteBranchLabelDetailInfoNew_DJ(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/LabelManage/BranchLabel/DeleteBranchLabelDetailInfoNew_DJ`,
        method: 'POST',
        data: params
    });
}


/**
 * 移动标签
 */
export function RemoveLabelInfoByBranchId_DJ(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/LabelManage/BranchLabel/RemoveLabelInfoByBranchId_DJ`,
        method: 'POST',
        data: params
    });
}

/**
 * 店铺标签排序
 */
 export function lableDoSort(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/LabelManage/BranchLabel/BranchLabelDoSort`,
        method: 'POST',
        data: params
    });
}

/**
 * 店铺标签操作
 */
 export function operateBranchLabelInfo(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/LabelManage/BranchLabel/OperateBranchLabelInfo`,
        method: 'POST',
        data: params
    });
}

/**
 * 自定义案例详情
 */
export function GetHostVideoContentList(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostVideoManage/HostVideoNew/GetHostVideoContentList`,
        method: 'GET',
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        data: params
    });
}


/**
 * 获取内容块列表
 */
export function GetHostVideoContentListByTempId(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostVideoManage/HostVideoNew/GetHostVideoContentListByTempId`,
        method: 'GET',
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        data: params
    });
}

/**
 * H5获取小程序作品详情
 * id  是  int  作品主键
 **/
export function GetHostVideoDetailNoAuth(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostVideoManage/HostVideo/GetHostVideoDetail`,
        method: 'GET',
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        data: params
    })
}


/**
 * 自定义案例和pc直接的关联补充接口
 * FileId	是	string	案例ID
 **/
export function GetZDYFileDetailListByPC(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/HomeShow/GetZDYFileDetailListByPC`,
        method: 'POST',
        data: params
    })
}

/**
 * H5 密码获取小程序作品详情
 **/
export function GetHostVideoDetailByPassWord(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostVideoManage/HostVideo/GetHostVideoDetailByPassWord`,
        method: 'POST',
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        data: params
    })
}

/*
 * H5 获取请求头接口
**/
export function GetHostUserIdentity(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostUserManage/HostUserLogin/GetHostUserIdentity`,
        method: 'POST',
        noAuth: true,
        data: params
    })
}

/**
 * 获取小程序订单类型
 */
export function GetDicOrderTypeList_host(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostUserNewManage/HostUserNew/GetDicOrderTypeList`,
        method: 'GET',
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        data: params
    })
}


/**
 * 查询作品下的标签和颜色数
 */
export function GetHostVideoSearch(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostVideoManage/HostVideo/GetHostVideoSearch`,
        method: 'POST',
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        data: params
    })
}
/**
 * 前台案例统计
 **/
export function MainFileStatisticsChat(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/FileNumStatistics/MainFileStatisticsChat`,
        method: 'POST',
        data: params
    })
}

/**
 * 获取单个案例的访问记录
 **/
export function GetFileStatisticsChatByFileId(params) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/FileNumStatistics/GetFileStatisticsChatByFileId`,
        method: 'POST',
        data: params
    })
}


/**
 * 加密的接口
 * H5获取小程序作品详情
 * id  是  int  作品主键
 * passWord 密码访问
 **/
export function GetHostVideoDetailNoAuthById(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostVideoManage/HostVideoNew/GetHostVideoDetailNoAuthById`,
        method: 'GET',
        data: params,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        authkey: Encrypt(`?id=${params.id}${params.passWord ? '&passWord=' + params.passWord : ''}`)
    })
}


/**
 * 加密的接口
 * 获取内容块列表
 * HvId	是	int	作品主键
 **/
export function GetHostVideoContentListById(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostVideoManage/HostVideoNew/GetHostVideoContentListNoAuthById`,
        method: 'GET',
        data: params,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        'authkey': Encrypt(`?Version=${params.Version}&HvId=${params.HvId}`)
    })
}


/**
 * 分享人信息
 **/
export function GetShareUserInfo(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostVideoManage/HostVideoNew/GetShareUserInfo`,
        method: 'GET',
        data: params
    })
}

/**
 * 获取作品页设置信息
 **/
export function GetWorkListSetting(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostUserNewManage/HostUserNew/GetWorkListSetting`,
        method: 'GET',
        data: params,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
    })
}


/**
 * 基于作品创建模板
 * FromHvId	是	int	作品Id
 * Title	是	string	模板名称
 * CoverImage	是	string	模板封面
 */
export function CreateTempByHostVideo(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostVideoManage/HostVideoNew/CreateTempByHostVideo`,
        method: 'POST',
        data: params,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
    })
}
