<!--
 * @Author: tangshuo
 * @Date: 2021-01-18 20:16:19
 * @LastEditors: tangshuo
 * @LastEditTime: 2022-03-01 16:58:37
 * @Remarks:
-->
<template>
  <div
      id="app"
      :class="{
        'dragging': $store.state.batchOperation.dragging
      }"
  >
    <!-- <router-link to="/">Home</router-link> -->
    <router-view />
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      copyText: "",
    };
  },
  computed: {
    ...mapState({
      themeClass: (state) => `theme-${state.theme ? state.theme : "Min"}`,
    }),
  },
  updated() {
    window.addEventListener("unload", this.saveState);
  },
  watch: {
    themeClass() {
      document.getElementsByTagName("body")[0].className = this.themeClass;
    },
  },
  created() {
    //切换店铺 肤色改变为当前店铺的默认肤色
    const switchShopSkin = sessionStorage.getItem('switchShopSkin');
    if (switchShopSkin) {
      this.$store.state.theme = switchShopSkin;
      sessionStorage.removeItem('switchShopSkin');
      sessionStorage.setItem("insercell", JSON.stringify(this.$store.state));
    }

    // let hostAuthStr =
    //   "79|1037|https://weddingbo-1256393015.file.myqcloud.com/|https://weddingbo-1256393015.file.myqcloud.com/|||oQvv-49CNl-lks6OaMxlm3MpIycU|1|5|True|0|False|0|0||2MPi5CaeAwyd++jlo9/ug0DTkpfD2y54o5t3wFd7BVkFjg21yb+ptjH+r1amDAdbNJqrKwzDRkkRkn4/TYH/PEDTkpfD2y54o5t3wFd7BVkFjg21yb+ptjH+r1amDAdbnaK3C5hghAGB+P6kxVWRkyJToyD2bLWuaBPO2pzUU/Ymqqy+ffgC2+JJRamACmuAFQOTFM4sxbVQpj8Cto8QSw==";
    // sessionStorage.setItem("hostAuthStr", hostAuthStr);
  },
  mounted() {
    this.globalListener(); // 全局监听
    document.getElementsByTagName("body")[0].className = this.themeClass;
  },
  methods: {
    /**
     * 全局监听
     * 页面中以下监听 的地方 使用相关Bus，监听不在写addEventListener
     */
    globalListener() {
      const self = this;
      // 消息监听
      window.addEventListener("message", (e) => {
        self.$bus.$emit('receiveMessage',e);
      },false);

      // 按键
      window.addEventListener("keydown", (e) => {
        self.keydown(e)
        self.$bus.$emit('keydown',e);
      },false);

      // 窗口发生变化
      window.addEventListener("resize", (e) => {
        self.$bus.$emit('resize',e);
      },false)

      window.addEventListener("online", () => {
        console.log("网络连接恢复！");
        // this.$notify.success({
        //   title: "提示",
        //   message: "网络连接已恢复！",
        // });
      });

      window.addEventListener("offline", () => {
        console.log("网络连接中断！");
        self.$notify.error({
          title: "网络连接中断",
          message: "请网络恢复后刷新重试",
          position: "bottom-left",
        });
      });

      // 页面重新加载 或者离开前  存储store
      window.addEventListener("beforeunload", (e) => {
        self.$bus.$emit('beforeunload',e);
        self.saveState(e)
      });
      // mac壳子 监听pagehide 存储store
      if (localStorage.ismac !== undefined && localStorage.ismac === "1") {
        window.addEventListener('pagehide', this.saveState);
      }
    },
    /**
     * 监听键盘事件
     */
    async keydown() {
      let key = window.event.keyCode;
      if (window.event.metaKey && key === 67) {
        //复制67
        document.execCommand("Copy");
        this.copyText = window.getSelection().toString();
      }
      // console.log(window.event.metaKey, key);
      if (window.event.metaKey && key === 86) {
        //粘贴86

        // 读取剪切板内容
        // const text = await navigator.clipboard.readText();
        document.execCommand("insertText", false, this.copyText);

        // this.mouseMove();
      }
    },
    // themeChange(theme) {
    //   this.$store.state.theme = theme;
    // },
    saveState() {
      this.$store.state.caseField = [];

      // pageAuthCode  注意，此处修改了 store.js 里也修改
      this.$store.state.pageAuthCode = ["Company", "Team", "Case", "Hotel",'banquet_hall', 'article',"Inspira", "discover"];
      this.$store.state.navAllList = ['/case','/company','/hotel','/team','/banquet_hall', '/article','/inspira','/teamcase','/discover'];// 除后台之外的导航栏
      this.$store.state.useSpaceInfo={};
      this.$store.state.isLoginToHome = false;
      sessionStorage.setItem("insercell", JSON.stringify(this.$store.state));
    },
  },
};
</script>
<style lang="scss">
@import url("//at.alicdn.com/t/c/font_2332859_ztfj7pvjddq.css");
@font-face {
  font-family: "YouSheBiaoTiHei";
  src: url("./assets/font/YouSheBiaoTiHei.ttf");
  font-weight: normal;
  font-style: normal;
}
.min-notification {
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  align-items: center;
  border: none;
  .el-notification__icon {
    line-height: 24px;
    color: #0093ff;
    font-size: 20px;
  }
  .el-notification__content {
    margin: 0;
    cursor: pointer;
    p {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 24px;
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
* {
  font-size: 14px;
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100%;
}

.noSelect {
  user-select: none;
}
html,
body,
#app {
  height: 100% !important;
  width: 100% !important;
  font-family: PingFangSC-Regular, PingFang SC, MicrosoftYaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow: auto;
}
.lable-color-main {
  box-shadow: none !important;
}
.el-loading-mask {
  background-color: rgba(255, 255, 255, 0.9);
  @include themify($themes) {
    background-color: themed("loading-bg1");
  }
}
.el-table {
  @include themify($themes) {
    background-color: themed("lable-bg");
  }
}
/*滚动条整体样式*/
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/*滚动条滑块*/
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  width: 10px;
  height: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ccc;
}
/*滚动条轨道*/
::-webkit-scrollbar-track {
  width: 10px;
  height: 10px;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
  border-radius: 2px;
  background: #fff;
}

body::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

body::-webkit-scrollbar-thumb {
  width: 0px;
}

body::-webkit-scrollbar-track {
  width: 0px;
}
.el-dialog {
  @include themify($themes) {
    background: themed("lable-bg") !important;
  }
}
/*分页样式 */
.el-pagination.is-background {
  .btn-next,
  .btn-prev {
    width: 30px !important;
    height: 30px !important;
    min-width: 0;
    background: transparent !important;
    border-radius: 50% !important;
    &:hover {
      background: #eeeeee !important;
      @include themify($themes) {
        background: themed("hover-bg") !important;
      }
      i::before {
        color: #222222 !important;
        @include themify($themes) {
          color: themed("input-color") !important;
        }
      }
    }
  }
  .el-pager {
    li {
      width: 30px !important;
      height: 30px !important;
      min-width: 0;
      background: transparent !important;
      color: #999;
      font-size: 13px;
      border-radius: 50% !important;
      &:hover {
        color: #222222 !important;
        background: #eeeeee !important;
        @include themify($themes) {
          background: themed("hover-bg") !important;
          color: themed("input-color") !important;
        }
      }
    }
    .active {
      background: transparent !important;
      color: #fff !important;
      border: 1px solid transparent !important;
      @include themify($themes) {
        background: themed("input-boder") !important;
        color: themed("font-color-active") !important;
      }
      &:hover {
        color: #fff !important;
        @include themify($themes) {
          color: themed("font-color-active") !important;
        }
      }
    }
  }
  .el-pagination__jump {
    margin-left: 20px;
    .el-input__inner {
      border-radius: 15px;
      background: transparent;
      border: 1px solid #999;
    }
  }
}
.el-loading-mask {
  background-color: rgba(255, 255, 255, 0.9);
  @include themify($themes) {
    background-color: themed("loading-bg1");
  }
  .el-loading-spinner .path {
    stroke: #ccc;
    @include themify($themes) {
      stroke: themed("lable-item-color");
    }
  }
}
.el-tooltip__popper {
  font-size: 12px !important;
  padding: 4px 8px !important;
  @include themify($themes) {
    background: themed("tips-bg") !important;
    border-radius: themed("border-radius-min") !important;
  }
}

/*input number 时去掉上下箭头*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.el-image__error,
.el-image__placeholder {
  @include themify($themes) {
    background: themed("cancel-btn-border");
  }
}
/*全局提示样式*/
.el-message {
  background-color: #fff !important;
  border: 0 !important;
  border-radius: 0 !important;
  min-width: 240px !important;
  justify-content: center;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2) !important;
}
.el-message .el-icon-loading {
  font-size: 18px;
}
.loading-msg-icon .el-message__content {
  font-weight: 400;
  margin-left: 4px;
  font-family: PingFangSC, PingFang SC;
  color: #222222;
}
.theme-Dark .el-message {
  border-radius: 25px!important;
}
.err-msg-icon {
  .iconfont {
    font-size: 16px;
    color: $err-color !important;
  }
  .el-message__content {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: $err-color !important;
    line-height: 20px;
    margin-left: 9px;
  }
}
.warn-msg-icon {
  .iconfont {
    font-size: 16px;
    color: $warn-color !important;
  }
  .el-message__content {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: $warn-color !important;
    line-height: 20px;
    margin-left: 9px;
  }
}
.suc-msg-icon {
  .iconfont {
    font-size: 16px;
    color: $font-color-import !important;
  }
  .el-message__content {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: $font-color-import !important;
    line-height: 20px;
    margin-left: 9px;
  }
}

/*弹窗全局样式 */
.el-dialog__wrapper {
  //弹窗居中
  display: flex;
  align-items: center;

  .el-dialog {
    margin-top: 0 !important;
    margin-bottom: 0;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0;
  }
}
/*旧版页面iframe样式 */
.edit-case-iframe {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999;
  &.task {
    z-index: 9998;
  }
}
.edit-case-iframe iframe {
  width: 100%;
  height: 100%;
  border: 0;
  background: transparent;
}
#app.dragging {
  user-select: none;
  -webkit-user-select: none;
  > * {
    user-select: none;
    -webkit-user-select: none;
  }
}
.el-message-box.tips-confirm .el-message-box__title span {
  font-size: 18px;
}
.el-message-box.tips-confirm .el-message-box__headerbtn .el-message-box__close {
  font-size: 24px;
}
.el-message-box.tips-confirm .el-message-box__message p {
  font-size: 14px;
}
.el-message-box.tips-confirm .el-button.el-button--primary {
  background: #444444;
  border-color: #444444;
  &:hover {
    color: #fff;
    border-color: #222222;
    background: #222222;
  }
}
.el-message-box.tips-confirm .el-button:hover {
  color: #444444;
  border-color: rgb(199, 199, 199);
  background-color: rgb(236, 236, 236);
}
</style>
