/**
 * 获取图片的 base64 编码 DataURL
 * @param image JS 图像对象
 * @param setBgAndBorder  true / false  true, 设置图片背景、边距
 * @return {string} 已编码的 DataURL
 */
function getImageDataURL(image, setBgAndBorder) {
    // 创建画布
    const canvas = document.createElement('canvas');
    canvas.width = setBgAndBorder ? image.width + 50 : image.width;
    canvas.height = setBgAndBorder ? image.height + 50 : image.height;
    const ctx = canvas.getContext('2d');

    // 设置背景 颜色为白色
    if (setBgAndBorder) {
        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    // 以图片为背景剪裁画布
    ctx.drawImage(image, setBgAndBorder ? 25 : 0, setBgAndBorder ? 25 : 0, image.width, image.height);

    // 获取图片后缀名
    const extension = image.src.substring(image.src.lastIndexOf('.') + 1).toLowerCase();
    // 某些图片 url 可能没有后缀名，默认是 png
    return canvas.toDataURL('image/' + extension, 1);
}

const picType = 'bmp,jpg,jpeg,png,gif';
// 获取文件后缀 前部分
function getSuffixBefore(filename) {
    return filename.substring(0, filename.lastIndexOf('.'));
}

// 获取文件后缀名
function getSuffix(filename) {
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length);
}

// 下载图片
export function imgDownLoad(downloadName, url) {
    const tag = document.createElement('a');

    const urlName = downloadName.indexOf('?') > -1 ? downloadName.substring(0,downloadName.indexOf('?')) : downloadName;
    const name = getSuffixBefore(urlName).replace(/\./g, '。');
    const nameSuffix = getSuffix(urlName);
    const nameStr = picType.indexOf(nameSuffix.toLowerCase()) !== -1 ? name + '.' + nameSuffix : name + '.png';
    tag.setAttribute('download', nameStr);

    // 此属性的值就是下载时图片的名称，注意，名称中不能有半角点，否则下载时后缀名会错误
    // tag.setAttribute('download', downloadName.replace(/\./g, '。'));

    const image = new Image();

    //重要，设置 crossOrigin 属性，否则图片跨域会报错
    image.setAttribute('crossOrigin', 'Anonymous');

    // 设置 image 的 url, 添加时间戳，防止浏览器缓存图片
    image.src = url + '?time=' + new Date().getTime();
    // 图片未加载完成时操作会报错
    image.onload = () => {
        tag.href = getImageDataURL(image, false);
        tag.click();
    };
}

/**
 * 图片 设置边框 白色背景 重新绘制下载
 * @param downloadName 图片名称
 * @param url 图片路径
 * @param callback  function 返回图片 / 直接下载
 */
export function imgRepaintDownLoad(downloadName, url, callback) {
    const tag = document.createElement('a');

    const urlName = downloadName.indexOf('?') > -1 ? downloadName.substring(0,downloadName.indexOf('?')) : downloadName;
    const name = getSuffixBefore(urlName).replace(/\./g, '。');
    const nameSuffix = getSuffix(urlName);
    const nameStr = picType.indexOf(nameSuffix.toLowerCase()) !== -1 ? name + '.' + nameSuffix : name + '.png';
    tag.setAttribute('download', nameStr);

    // // 此属性的值就是下载时图片的名称，注意，名称中不能有半角点，否则下载时后缀名会错误
    // tag.setAttribute('download', downloadName.replace(/\./g, '。'));

    const image = new Image();

    //重要，设置 crossOrigin 属性，否则图片跨域会报错
    image.setAttribute('crossOrigin', 'Anonymous');

    // 设置 image 的 url, 添加时间戳，防止浏览器缓存图片
    image.src = url + ( url && url.indexOf('?') === -1 ? '?' : '&') + 'time=' + new Date().getTime();
    // 图片未加载完成时操作会报错
    image.onload = () => {
        if (!callback) {
            tag.href = getImageDataURL(image, true);
            tag.click();
        } else {
            const imgUrl = getImageDataURL(image, true);
            callback(imgUrl);
        }
    };
}

/**
 * base64 转 file
 * @param dataUrl
 * @param filename
 * @returns {File}
 */
export function dataURLtoFile(dataUrl, filename = 'file') {
    let arr = dataUrl.split(',')
    let mime = arr[0].match(/:(.*?);/)[1]
    let suffix = mime.split('/')[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
    })
}
