<template>
  <div class="city-box" @click.stop>
    <div class="city-head">
      <el-input
        size="small"
        class="con-input"
        v-model="cityName"
        @change="cityNameChange"
        placeholder="城市名/省份"
      ></el-input>
    </div>
    <div class="city-main">
      <span class="country-item city-item" v-if="false" @click="selectCity()"
        >全国</span
      >
      <span
        v-if="false"
        class="country-item city-item around-item"
        :class="{
          'city-item-active': isAround,
        }"
        @click="selectAround"
        >周边 <i class="el-icon-s-tools" @click.stop="showAddAround = true"></i>
      </span>
      <div class="province-item" v-if="getCommonlyCityList().length">
        <span class="province-name">常用:</span>
        <div class="city-list">
          <span
            class="city-item"
            :class="{
              'city-item-active': !!selCityList.find(
                (s) => s.code === city.code
              ),
            }"
            v-for="city of getCommonlyCityList()"
            :key="city.code"
            @click="selectCity(city)"
            >{{ city.name }}<i class="el-icon-check icon-sel"></i
          ></span>
        </div>
      </div>
      <div class="province-item">
        <span class="province-name" v-if="getCommonlyCityList().length">其他:</span>
        <div class="city-list">
          <span
            class="city-item"
            :class="{
              'city-item-active': !!selCityList.find(
                (s) => s.code === city.code
              ),
            }"
            v-for="city of getCityList()"
            :key="city.code"
            @click="selectCity(city)"
            >{{ city.name }}<i class="el-icon-check icon-sel"></i
          ></span>
          <span v-if="getCityList().length == 0" class="city-item"
            >暂无相关数据</span
          >
        </div>
      </div>
    </div>
    <div class="city-footer" v-if="false">
      <el-button type="info" class="btn-save" @click="confirmSel()"
        >确定</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selCity: {
      default: [],
    },
    isAround1: {
      default: false,
    },
    curIndex: {
      default: "1",
    },
  },
  data() {
    return {
      cityName: "",
      isAround: false, //是否选择周边
      selCityList: [],
      aroundCity: [], //周边城市
      commonlyCity: [], //常用城市
      cityList: [], //城市列表
      hostAuthStr: sessionStorage.getItem("hostAuthStr"), //金刚系统所需请求头
      showAddAround: false, //是否显示设置周边列表
    };
  },
  watch: {
    showAddAround(val) {
      this.$emit("setIsClose", val);
    },
    selCity() {
      this.selCityList = this.$Clone(this.selCity);
    },
  },
  components: {},
  mounted() {
    // this.getBranchPCity();
    this.getNewWeddingBaseUserCity();
    this.selCityList = this.$Clone(this.selCity);
    this.isAround = this.isAround1;
  },
  methods: {
    /**
     * 关闭设置
     */
    closeAddDig(aroundList) {
      this.aroundCity = aroundList;
      this.showAddAround = false;
    },
    /**
     * 获取城市列表
     */
    getCityList() {
      let cityList = [],
        cityList1 = [];
      if (!this.cityName) {
        return this.cityList;
      }
      cityList = this.cityList.filter(
        (c) => c.name.indexOf(this.cityName) > -1
      );
      cityList1 = this.commonlyCity.filter(
        (c) => c.name.indexOf(this.cityName) > -1
      );
      return [...cityList1, ...cityList];
    },
    /**
     * 获取常用城市列表
     */
    getCommonlyCityList() {
      if (!this.cityName) {
        return this.commonlyCity;
      }
      return [];
    },
    getNewWeddingBaseUserCity() {
      this.$axios({
        url:
          process.env.VUE_APP_TEAMURL +
          "xlapi/HostManage/NewWeddingManage/NewWeddingInfo/GetBaseUserCityList",
        method: "POST",
        authorizationStr: this.hostAuthStr,
        data: {
          ShowStatus: this.curIndex == "1" ? 1 : 0,
        },
        success(res) {
          if (res.data.status) {
            this.commonlyCity = res.data.data.CommonUse || [];
            this.cityList = res.data.data.CityModel || [];
          } else {
            this.$Error("获取城市列表失败!");
          }
        },
        error() {
          this.$Error("获取城市列表失败");
        },
      });
    },
    cityNameChange() {},
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit("closeTipDig");
    },
    /**
     * 确认选择
     */
    selectCity(city) {
      if (!city) {
        city = {
          code: "",
          name: "全国",
        };
        this.isAround = false;
        this.selCityList = [];
        this.confirmSel(true);
      } else {
        this.isAround = false;
        if (this.selCityList.find((c) => c.code === city.code)) {
          this.selCityList = this.selCityList.filter(
            (c) => c.code !== city.code
          );
        } else {
          this.selCityList.push(city);
        }
        this.confirmSel();
      }
    },
    /**
     * 选择周边
     */
    selectAround() {
      this.isAround = true;
      this.selCityList = [];
      this.confirmSel();
    },
    /**
     * 获取周边城市
     */
    getBranchPCity() {
      this.$axios({
        url:
          process.env.VUE_APP_TEAMURL +
          "xlapi/HostManage/NewWeddingManage/NewWeddingInfo/GetBranchPCity",
        method: "POST",
        data: {
          branchId: sessionStorage.getItem("BranchId"),
          branchSource: "lh",
        },
        success(res) {
          if (res.data.status) {
            this.aroundCity = res.data.data;
          }
        },
        error() {
          this.$Error("获取周边城市列表失败");
        },
      });
    },
    /**
     * 确认选择城市
     */
    confirmSel(isAll = false) {
      this.$emit(
        "confirmSel",
        this.selCityList,
        this.isAround ? this.aroundCity : [],
        this.isAround,
        isAll
      );
    },
  },
};
</script>
<style lang="scss">
.city-box {
  width: 200px;
  height: 360px;
  .city-head {
    width: 100%;
    padding: 0 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    .el-input {
      width: 168px;
    }
  }
  .city-main {
    width: 100%;
    // height: calc(100% - 98px);
    height: calc(100% - 48px);
    margin-top: 8px;
    overflow-y: auto;
    .around-item {
      position: relative;
      i {
        position: absolute;
        right: 12px;
        top: 8px;
        font-size: 16px;
        opacity: 0.8;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }
    }
    .country-item {
      width: 100%;
      padding: 0 16px;
      height: 32px;
      line-height: 32px;
      color: #777;
      font-size: 14px;
    }
    .province-item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .province-name {
        line-height: 32px;
        padding: 0 16px;
        text-align: center;
        color: #444444;
        font-weight: bold;
        font-size: 14px;
      }
      .city-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
      }
    }
    .city-item {
      display: inline-block;
      padding: 0 16px;
      width: 100%;
      height: 32px;
      line-height: 32px;
      text-align: left;
      color: #999;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      box-sizing: border-box;
      position: relative;
      &:hover {
        background: #f0f2f5;
        @include themify($themes) {
          background: themed("search-his-bg");
          color: themed("input-color");
        }
      }
      .icon-sel {
        position: absolute;
        right: 12px;
        top: 9px;
        color: #222;
        opacity: 0;
      }
    }
    .city-item-active {
      background: #f0f2f5;
      color: #222;
      font-weight: 600;
      @include themify($themes) {
        background: themed("search-his-bg") !important;
        color: themed("search-btn-bg") !important;
      }
      .icon-sel {
        opacity: 1;
      }
    }
  }
  .city-footer {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    padding-right: 16px;
    box-sizing: border-box;
    justify-content: flex-end;
    .btn-save {
      width: 48px;
      height: 32px;
      line-height: 32px;
      background: #444444;
      border-radius: 0;
      margin: 0;
      padding: 0;
    }
  }
}

/*滚动条整体样式*/
::-webkit-scrollbar {
  width: 2px;
  height: 1px;
}
/*滚动条滑块*/
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #dedede;
}
/*滚动条轨道*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
  border-radius: 3px;
  background: #f5f5f5;
  @include themify($themes) {
    background: themed("search-his-bg");
  }
}
</style>