<!--
 * @Author: ZBH
 * @Date: 2021-01-21 09:34:13
 * @LastEditors: tangshuo
 * @LastEditTime: 2022-01-18 18:26:17
 * @Remarks: 下拉选择公共组件
-->
<template>
  <div class="append-text-wrap">
    <el-select
      :style="{ width: width ? width + 'px' : '100%' }"
      class="con-select"
      :class="{
        'select-Icon': prefixIcon,
        'multiple-select': multiple,
        'no-border-input': isNoBorder,
      }"
      :popper-class="popperClass"
      :collapse-tags="collapseTags"
      v-model="value1"
      :multiple="multiple"
      :disabled="disabled"
      :placeholder="placeholder"
    >
      <el-option
        v-for="item in options"
        :key="item[valueKey]"
        :label="item[labelKey]"
        :value="item[valueKey]"
        :disabled="item.disabled"
      >
        <template v-if="multiple">
          <i class="iconfont btn_nor"></i>
          <i class="iconfont btn_sel"></i>
          <span class="search-option-name">{{ item[labelKey] }}</span>
        </template>
        <template v-else>
          <span class="search-option-name">{{ item[labelKey] }}</span>
          <i class="iconfont icon_gou2"></i>
        </template>
      </el-option>
      <div class="add-item flex flex-js" v-if="isShowAdd">
        没找到？
        <span class="add-item-active">点击创建</span>
      </div>
      <i
        v-if="prefixIcon"
        class="prefixI iconfont"
        :class="prefixIcon"
        slot="prefix"
      ></i>
    </el-select>
  </div>
</template>
<script>
export default {
  name: "my-input",
  data() {
    return {
      inputValue: [],
      iconf: "iconfont",
      value1: []
    };
  },
  props: {
    isNoBorder: {
      type: Boolean,
      default: false, //是否禁用
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false, //是否禁用
    },
    isShowAdd: {
      type: Boolean,
      default: false, //是否多选
    },
    multiple: {
      type: Boolean,
      default: false, //是否多选
    },
    width: {
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
      default: "请输入内容", //无内容提示
    },
    clearable: {
      type: Boolean,
      default: false, //是否显示清除按钮
    },
    maxlength: {
      default: "", //最大长度
    },
    suffixIcon: {
      type: String,
      default: "", //带图标 使用slot name=suffix
    },
    prefixIcon: {
      type: String,
      default: "", //带图标 使用slot name=suffix
    },
    isError: {
      type: String,
      default: "", //错误
    },
    labelKey: {
      type: String,
      default: "label", //label 属性对应的字段
    },
    valueKey: {
      type: String,
      default: "value", //value 属性对应的字段
    },
    collapseTags:{
      type: Boolean,
      default: false, //是否合并多选
    },
  },
  watch: {
    inputValue(val) {
      this.$emit("input", val);
    },
    value1: {
      handler(val) {
        this.value1 = val;
        this.inputValue = val;
        this.$emit('update:value', val);
      },
      deep: true,
      immediate: true,
    },
    value: {
      handler(val) {
        this.value1 = val;
        this.inputValue = val;
        this.$emit('update:value', val);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    popperClass() {
      if (this.multiple) {
        return this.isShowAdd
          ? "multiple-popper multiple-ShowAdd"
          : "multiple-popper";
      } else if (this.isShowAdd) {
        return "multiple-ShowAdd";
      }else{
        return ''
      }
    },
  },
  methods: {
    inputChange(e) {
      this.$emit("change", e);
    },
    focus(e) {
      this.$emit("focus", e);
    },
    blur(e) {
      this.$emit("blur", e);
    },
  },
};
</script>
<style lang="scss">
.multiple-popper {
  .el-select-dropdown__wrap {
    height: 100%;
  }

  .el-scrollbar {
    height: 200px;
  }
  .el-select-dropdown__list {
    padding: 6px 0 0;
  }
  // .el-scrollbar__bar {
  // 	display: none;
  // }
  // .el-select-dropdown__wrap::-webkit-scrollbar {
  // 	width: 8px;
  // 	height: 0px;
  // }
  // .el-select-dropdown__wrap::-webkit-scrollbar-track {
  // 	background: transparent;
  // }
  // .el-select-dropdown__wrap::-webkit-scrollbar-thumb {
  // 	border-radius: 20px;
  // 	border: 2px solid transparent;
  // 	box-shadow: 4px 8px 0 $border-color inset;
  // 	background: transparent;
  // }
}
.multiple-ShowAdd {
  .el-select-dropdown__wrap {
    height: calc(100% - 28px);
  }
  .el-scrollbar {
    height: 200px;
  }
  .el-select-dropdown__list {
    padding: 6px 0 0;
  }
}
</style>
<style scoped lang="scss">
::v-deep .el-select {
  .el-input.is-disabled .el-input__inner {
    background-color: #f5f5f5;
    border-color: #eeeeee;
    color: #666666;
  }
  .el-input--prefix {
    .el-input__prefix {
      display: flex;
      justify-content: center;
      align-items: center;
      left: 8px;
      .prefixI {
        font-size: 24px;
        color: #666666;
      }
    }
    .el-input__inner {
      border: 1px solid #eeeeee;
      padding-left: 40px;
      &:hover {
        border: 1px solid #cccccc;
      }
    }
  }

  .el-select__tags {
    overflow: hidden;
    > span {
      display: flex;
      justify-content: flex-start;
    }
    .el-tag.el-tag--info {
      // background-color: #EEEEEE;
      // border-color: #e9e9eb;
      // color: #222222;
    }
  }
}
::v-deep .select-Icon {
  .el-select__tags {
    margin-left: 34px;
    width: calc(100% - 70px) !important;
  }
}

::v-deep .el-input-group__append,
.el-input-group__prepend {
  background: #ffffff;
  border-left: 0px solid transparent;
  border-right: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}
.append-text-wrap {
  position: relative;
  .append-text {
    position: absolute;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 20px;
    top: 0;
    right: 8px;
    height: 40px;
    line-height: 40px;
  }
  .append-isError {
    margin-top: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff3355;
    line-height: 16px;
  }
}

.el-select-dropdown__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666666;
  .icon_gou2 {
    opacity: 0;
  }
}

::v-deep .is-focus {
  .el-input__inner {
    border-color: #222222 !important;
  }
}
.el-popper .el-scrollbar__wrap {
  background: red;
}
.multiple-ShowAdd {
  .add-item {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    height: 40px;
    width: 100%;
    padding-left: 16px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    box-sizing: border-box;
    z-index: 2;
    .add-item-active {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5977b3;
      line-height: 20px;
    }
  }
}
//多选
.multiple-popper {
  .el-select-dropdown__item {
    padding: 0 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #666666;
    .btn_nor {
      display: block;
      margin-right: 8px;
      color: #cccccc;
    }
    .btn_sel {
      display: none;
      margin-right: 8px;
      color: #222222;
    }
  }
  .el-select-dropdown__item.selected {
    color: #666666 !important;
    &:after {
      content: "" !important;
    }
    .btn_nor {
      display: none;
    }
    .btn_sel {
      display: block;
    }
  }
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover {
  background-color: #f5f5f5;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #f5f5f5;
}
.el-select-dropdown__item.selected {
  color: #666666 !important;
  .icon_gou2 {
    opacity: 1;
  }
}
.el-select-dropdown__item.is-disabled {
  color: #cccccc;
}
.el-select-dropdown {
  border-radius: 0 !important;
  ::v-deep .popper__arrow {
    display: none !important;
  }
}
::v-deep .popper__arrow {
  display: none !important;
}
</style>
