/*
 * @Author: zbh
 * @Date: 2022-03-22 17:00:00
 * @LastEditors: zbh
 * @LastEditTime: 2022-03-22 17:00:00
 * @Remarks:
 */
/* 前端性能监控 
 阿里云文档  https://help.aliyun.com/document_detail/65674.htm?spm=a2c4g.11186623.0.0.2f2f4afejJf6ux#concept-65674-zh */


function BrowserMonitor() {
	/**
	 * 用于判断是否是线上环境
	 */
		if (process.env.NODE_ENV === 'production') {
		const BrowserLogger = require('alife-logger');
		const __bl = BrowserLogger.singleton(
		  {
		    pid:"do9i8undw4@9ccb437b4159be8",
		    appType:"web",
			uid: sessionStorage.getItem("BranchId_B"), //店铺id
		    imgUrl:"https://arms-retcode.aliyuncs.com/r.png?",
		    sendResource:true,
		    enableLinkTrace:true,
		    behavior:true
		  }
		);
	}
}

BrowserMonitor()
