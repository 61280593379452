var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ConDig',{attrs:{"title":_vm.title,"width":"480px","isShowDig":_vm.showAddLabelDig,"loading":_vm.isLodding,"appendToBody":true,"confirmText":"保存"},on:{"open":_vm.initData,"closed":function($event){_vm.showAddLabelDig = false},"closeDig":_vm.handleClose,"cancelClick":_vm.handleClose,"confirmClick":_vm.addNewColor}},[_c('main',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLodding),expression:"isLodding"}],staticClass:"add-color-box",attrs:{"slot":"dialogMain"},slot:"dialogMain"},[_c('ConInput',{staticClass:"input-name",attrs:{"size":24,"maxlength":"40","showWordLimit":"","placeholder":"标签名称"},model:{value:(_vm.labelName),callback:function ($$v) {_vm.labelName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"labelName"}}),_c('div',{staticClass:"color-sel-box"},[_c('div',{staticClass:"row-item"},[_c('div',{staticClass:"row-title-box"},[_c('span',{staticClass:"title-text"},[_vm._v("以文件夹形式展示")]),_c('span',{staticClass:"title-less"},[_vm._v("若不设置，则默认合集内第一个作品的封面为合集封面")])]),_c('el-switch',{attrs:{"active-color":(_vm.theme === 'Dark' || _vm.theme === 'Plus')?'#ffcc00':'#222222',"inactive-color":(_vm.theme === 'Dark' || _vm.theme === 'Plus')? '#444' : '#DCDFE6'},model:{value:(_vm.isShowFolder),callback:function ($$v) {_vm.isShowFolder=$$v},expression:"isShowFolder"}})],1),_c('div',{staticClass:"lable-cover-box",class:{
              'verticalBox': _vm.$store.state.caseCoverShowWay === 'vertical'
            }},[(_vm.coverPath)?_c('div',{staticClass:"img-bg",style:(("background: url(" + _vm.coverPath + ") 100% center / cover no-repeat rgba(0, 0, 0, 0.1);"))}):_vm._e(),(_vm.coverPath)?_c('el-image',{staticClass:"cover-img",attrs:{"fit":"cover","src":_vm.coverPath}}):_vm._e(),_c('div',{staticClass:"layer-mask"}),(_vm.coverPath)?_c('ConButton',{staticClass:"cover-btn",attrs:{"title":"替换封面"},on:{"click":_vm.selCaseCover}}):_c('ConIcon',{staticClass:"cover-btn icon_add_big",attrs:{"icon":"icon_add_big"},on:{"click":_vm.selCaseCover}})],1),_c('div',{staticClass:"row-item b-t-e"},[_c('div',{staticClass:"row-title-box"},[_c('span',{staticClass:"title-text"},[_vm._v("该标签/合集对外公开（移动端）")])]),_c('el-switch',{attrs:{"active-color":(_vm.theme === 'Dark' || _vm.theme === 'Plus')?'#ffcc00':'#222222',"inactive-color":(_vm.theme === 'Dark' || _vm.theme === 'Plus')? '#444' : '#DCDFE6'},model:{value:(_vm.isShowOut),callback:function ($$v) {_vm.isShowOut=$$v},expression:"isShowOut"}})],1),_c('div',{staticClass:"row-item b-t-e"},[_c('div',{staticClass:"row-title-box"},[_c('span',{staticClass:"title-text"},[_vm._v("所属类型（选填）")])]),_c('div',{staticClass:"w-select-box multiple-select type",class:{
                'disabled': _vm.disabled
              }},[_c('el-popover',{attrs:{"placement":"bottom-end","popper-class":"type-popover-w","visible-arrow":false,"width":"144","trigger":"click","disabled":_vm.disabled},model:{value:(_vm.isShowTypeBox),callback:function ($$v) {_vm.isShowTypeBox=$$v},expression:"isShowTypeBox"}},[_c('div',{staticClass:"input-div-s color-div",class:_vm.isShowTypeBox ? 'focus' : '',attrs:{"slot":"reference"},slot:"reference"},[_c('div',{staticClass:"label-box-width"},[_vm._v(" "+_vm._s(_vm.RelationTypes.length > 0 ? _vm.RelationTypes.map(function (c) { return c.Name; }).join('/') : '不限')+" ")])]),_c('div',{staticClass:"list-box small4-scroll"},_vm._l((_vm.orderTypeList),function(item,index){return _c('div',{key:'type' + index,staticClass:"item",class:{
                      'active': !!_vm.RelationTypes.find(function (c) { return c.Id === item.Id; })
                    },on:{"click":function($event){$event.stopPropagation();return _vm.selTypeItem(item)}}},[_vm._v(" "+_vm._s(item.Name)+" "),_c('i',{staticClass:"iconfont icon_gou2"})])}),0)]),_c('div',{staticClass:"add-btn",on:{"click":function($event){$event.stopPropagation();return _vm.selType($event)}}},[_c('i',{staticClass:"down",class:_vm.isShowTypeBox ? 'up' : ''})])],1)])]),_c('input',{ref:"lCoverfile",staticStyle:{"opacity":"0","width":"0","height":"0","position":"absolute"},attrs:{"type":"file","id":"lCoverfile","accept":"image/png, image/jpeg, image/gif"},on:{"change":_vm.selCaseImg}})],1)]),_c('tailoring-cover-dig',{ref:"tailoringDig",attrs:{"showTailoringDig":_vm.showTailoringDig,"imgSrc":_vm.tailorImg,"ratio":_vm.cuttingRatio,"notUpload":true,"fileId":"curFolder"},on:{"uploadCallback":_vm.setLableCover,"closeDig":function($event){_vm.tailorImg = null;
        _vm.cuttingRatio = null;
        _vm.showTailoringDig = false;}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }