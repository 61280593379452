
/**
 * 存储案例列表展示方式
 * @param type  all 所有 、 current 当前店铺的
 * @param val 存储的值
 * @returns {string}
 */
export function setCaseDisplayMode(val, type) {
    const BranchId_B = sessionStorage.getItem('BranchId_B');

    if (type && type === 'current' && val) {
        let obj = getCaseDisplayMode('all');
        obj ?
            obj[BranchId_B] = val
            :
            obj = {}
        obj[BranchId_B] = val;
        sessionStorage.setItem('caseDisplayMode', JSON.stringify(obj));
    }
    if (type && type === 'all' && val) {
        sessionStorage.setItem('caseDisplayMode', JSON.stringify(val));
    }
}

/**
 * 获取案例列表展示方式
 * @param type   all 获取所有 、 current 当前店铺的
 * @returns {string}
 */
export function getCaseDisplayMode(type) {
    const BranchId_B = sessionStorage.getItem('BranchId_B');

    let obj = sessionStorage.getItem('caseDisplayMode');
    if (!obj) return obj;
    obj = obj ? JSON.parse(obj) : null;
    if (type === 'all') return obj;
    if (type === 'current') return obj[BranchId_B];
}
