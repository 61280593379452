const CryptoJS = require("crypto-js");

const KEY = CryptoJS.enc.Utf8.parse('YoWedHost#lanhik'); //  密钥        长度必须为16位
const IV = CryptoJS.enc.Utf8.parse('YoWedHost#lanhiv'); //  初始向量    长度随意

/*
 * AES加密 ：字符串 key iv  返回base64
 */
export function Encrypt(str) {
  let key = KEY;
  let iv = IV;
  let srcs = CryptoJS.enc.Utf8.parse(str);
  const encrypt = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC, //这里可以选择AES加密的模式
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Base64.stringify(encrypt.ciphertext);
}

/*
 * AES 解密 ：字符串 key iv  返回base64
 */
export function Decrypt(str) {
  let key = KEY;
  let iv = IV;
  let base64 = CryptoJS.enc.Base64.parse(str);
  let src = CryptoJS.enc.Base64.stringify(base64);
  const decrypt = CryptoJS.AES.decrypt(src, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC, //这里可以选择AES解密的模式
    padding: CryptoJS.pad.Pkcs7
  });
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}
