<template>
  <ConDig
    title="裁剪封面"
    width="489px"
    :isShowDig="showTailoringDig"
    :loading="isLodding"
    :showLeftSlot="true"
    :appendToBody="true"
    leftSlotText="重新选择"
    @open="initData"
    @closeDig="closeDig"
    @cancelClick="closeDig"
    @confirmClick="saveTailorImg"
    @leftSlotClick="changeIcon"
  >
    <main slot="dialogMain" class="tailor-main" v-loading="loading">
      <div class="tailor-img">
        <img v-if="!isTailor" :src="option.img" />
        <vueCropper
          v-else
          ref="cropper"
          :img="option.img"
          :outputSize="option.size"
          :outputType="option.outputType"
          :info="true"
          :full="option.full"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :fixed="option.fixed"
          :fixedNumber="ratio ? ratio : option.fixedNumber"
          :centerBox="option.centerBox"
          :infoTrue="option.infoTrue"
          :fixedBox="option.fixedBox"
          :mode="option.mode"
          @imgLoad="imgLoadHandler"
        ></vueCropper>
      </div>
    <input
      type="file"
      id="FolderCover"
      accept="image/png, image/jpeg"
      style="opacity: 0; width: 0; height: 0; position: absolute"
      @change="selFolderCover"
    />
	</main>
  </ConDig>
</template>

<script>
export default {
  props: [
    "showTailoringDig",
    "imgSrc",
    "fileId",
    "ratio",
    "notUpload"
  ],
  data() {
    return {
      isTailor: true, //是否裁剪中
      isLodding:false,
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "png", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 330, // 默认生成截图框宽度
        autoCropHeight: 220, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [3, 2], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        mode: '100% auto',
      },
      loading: true,
    };
  },
  methods: {
    initData() {
      this.urlTobase64();
    },
    /**
     * 模拟点击 打开文件选择框
     */
    changeIcon() {
      $("#FolderCover").val(""); //赋值为'',防止选择同一文件不触发change事件
      $("#FolderCover").trigger("click");
    },
    /**
     * 保存裁剪图片并替换原图
     */
    saveTailorImg() {
      this.$refs.cropper.getCropData((data) => {
        //保存裁剪图片
        // this.option.img = data;
        // this.uploadImg(this.dataURLtoFile(this.option.img));
        if (this.notUpload) {
          this.uploadCallback(this.dataURLtoFile(data));
          this.closeDig();
          return;
        }
        this.newUploadImg(this.dataURLtoFile(data));
      });
    },

    /**
     * 关闭弹窗
     */
    closeDig() {
      this.loading = true;
      this.option.img = "";
      this.$emit("closeDig");
    },
    async selFolderCover() {
      this.loading = true;
      let file = document.getElementById("FolderCover").files[0];
      let url = this.getObjectURL(file);

      const imgInfo = await this.getImgInfo(url);
      if (
          imgInfo &&
          imgInfo.width &&
          imgInfo.height
      ) {
        this.option.mode = imgInfo.width > imgInfo.height ? '100% auto' : 'auto 100%';
      }
      this.option.img = url;
    },
    /**
     * 本地文件转为可播放Url
     */
    getObjectURL(file) {
      var url = null;
      if (window.createObjectURL != undefined) {
        // basic
        url = window.createObjectURL(file);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file);
      }
      return url;
    },
    /**
     * 网络图片转base64
     */
    async urlTobase64() {
      this.loading = true;
      let src = this.imgSrc;
      if (src === '') {
        this.loading = false;
        return;
      }
      let imgSrc = src;
      if (src.indexOf("blob:") > -1) {
        //为本地路径图片 直接显示
        imgSrc = src;
      } else {
        imgSrc = `${src}?v=${new Date().getTime()}`;
      }
      const imgInfo = await this.getImgInfo(imgSrc);
      if (
          imgInfo &&
          imgInfo.width &&
          imgInfo.height
      ) {
        this.option.mode = imgInfo.width > imgInfo.height ? '100% auto' : 'auto 100%';
      }
      this.option.img = imgSrc;
    },
    /**
     * 图片
     * 加载完成 或者 加载失败
     */
    imgLoadHandler() {
      this.loading = false;
    },
    /**
     * 获取图片信息
     */
    getImgInfo(imgSrc) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.setAttribute('crossOrigin', 'Anonymous');
        img.src = imgSrc;
        img.onload = (ev) => {
          resolve(img);
        };
        img.onerror = () => {
          resolve(null);
        }
      });
    },
    /**
     * 生成唯一文件名
     */
    newGuid() {
      var guid = "";
      for (var i = 1; i <= 32; i++) {
        var n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
        if (i == 8 || i == 12 || i == 16 || i == 20) guid += "-";
      }
      return guid;
    },

    //将base64转换为文件
    dataURLtoFile(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], this.newGuid() + "." + mime.split("/")[1], {
        type: mime,
      });
    },
    /**
     * 新上传图片到腾讯云
     */
    newUploadImg(file) {
      let self = this;
      this.$newUploadFile({
        file: file,
        fileId: "",
        ysType: 1,
        pathKey: `${sessionStorage.getItem("BranchId")}/CaseReveal/${
          this.fileId
        }/CoverImg/`,
        onProgress(progress) {},
        onTaskReady(taskId) {
          console.log("准备就绪,开始上传 ：", taskId);
        },
        success(res) {
          console.log("上传成功", res);
          self.uploadCallback(res.key);
          self.closeDig();
        },
        error() {
          self.$Error("裁剪失败");
        },
      });
    },
    /**
     * 上传成功回调操作
     */
    uploadCallback(that) {
      this.$emit("uploadCallback", that);
    },
  },
};
</script>

<style scoped lang="scss">
/*详情弹窗*/
.tailor-main {
  width: 440px;
  margin: auto;
  padding: 24px 0;
  .tailor-img {
    width: 100%;
    height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    img {
      max-width: 100%;
      max-height: 380px;
    }
  }
}
</style>
<style>
.tailor-main .crop-point {
  background-color: #fff !important;
}
.tailor-main .cropper-view-box {
  outline-color: #fff !important;
}
</style>

