 /*
  * 案例 选择作品 / team 选择金刚 的 批量操作指令
  */

// 绑定传参值的方法
const bindProps = (el, options) => {
    const {
        value: {
            data = {},
            batchType = '',
            is_sel_ele = false,
            handlerSel = function () {}
        } = {}
    } = options;

    // 资源显示为更多资源的时候 不显示选择框
    if (!options.value.data) return;

    el.$value = data;
    el.$selType = batchType;
    el.handlerSel = handlerSel || function () {};
    el.$is_sel_ele = is_sel_ele;

    // 选择
    if (el.$value.hasOwnProperty('is_sel_ele')) {
        el.$is_sel_ele = el.$value.is_sel_ele;
    }
    if (!el.$value.hasOwnProperty('is_sel_ele')) {
        el.$value.is_sel_ele = false;
    }

    el.setAttribute('is_sel_ele', el.$is_sel_ele);
}

export const selItem = {
    bind(el, options) {
        bindProps(el, options)

        // 资源显示为更多资源的时候 不显示选择框
        if (!options.value.data) return;

        el.setAttribute('mouse-hover-sel', true)
        const selDIV = document.createElement('div');
        selDIV.className = 'sel-item-ele';
        selDIV.innerHTML = '<i class="i-sel-e iconfont btn_nor2">' +
            '</i><i class="i-sel-e iconfont btn_sel2"></i>';

        el.prepend(selDIV);

        // 选择当前元素
        el.handlerSelDiv = (e) => {
            e.preventDefault();
            e.stopPropagation();

            el.$value.is_sel_ele = !el.$value.is_sel_ele;

            el.setAttribute('is_sel_ele', el.$value.is_sel_ele);

            el.handlerSel && el.handlerSel(el.$value, el.$selType, e);
        }

        selDIV.addEventListener('click', el.handlerSelDiv);
        el.selDIV = selDIV
    },
    // 当传进来的值更新的时候触发
    componentUpdated(el, options) {
        bindProps(el, options)
    },

    // 指令与元素解绑的时候，移除事件绑定
    unbind(el) {
        if (el.handlerSelDiv) {
            el.selDIV.removeEventListener('click', el.handlerSelDiv);
        }
    },
}
