import axios from 'axios';
import { Message } from 'element-ui';
//Get 数据处理
let URL = (dataObj) => {
	let url = '';
	let dataObjArr = [];
	if (dataObj == '') {
		return;
	}
	for (var key in dataObj) {
		if (dataObj.hasOwnProperty(key)) {
			dataObjArr.push(key);
			if (dataObjArr.length == 1) {
				url += '?' + key + '=' + dataObj[key];
			} else {
				url += '&' + key + '=' + dataObj[key];
			}
		}
	}
	return url
};
//请求封装
export function httpServe(param) {
	//所需参数是否合法
	if (typeof param != "object") {
		throw 'This is no Object';
	}
	let authorization = "";
	//获取当前请求方式
	let method = param.method.toUpperCase();
	//get请求拼接参数
	let Urls = param.url + URL(param.data)
	const  isVisitorCase = sessionStorage.getItem('isVisitorCase'); // 访客访问案例
	//正式
	if (sessionStorage.getItem("Authorization")) {
		// console.log("身份验证成功,允许请求")
		authorization = sessionStorage.getItem("Authorization");
	} else if (localStorage.getItem("Authorization")) {
		console.log("身份验证成功,允许请求")
		authorization = localStorage.getItem("Authorization");

	} else if (
		!isVisitorCase &&
		!param.hasOwnProperty('noAuth') && param.url.indexOf('UserLoginSystemByAccount') === -1
	) {
		console.log("身份验证失败,禁止请求")
		if (process.env.NODE_ENV === 'development') {
			//开发环境
			window.location = window.location.origin + "/login"
		} else {
			//发布环境
			window.location = process.env.VUE_APP_URLPAGE;
		}
	}
	if (param.authorizationStr) {
		authorization = param.authorizationStr
	}
	//请求数据
	let axiosObj = {
		method,
		headers: {
			authorization, 'Content-Type': 'application/json'
		},
		url: method == 'GET' ? Urls : param.url,
	};
	if (param.authkey) { // 访客模式案例加密
		axiosObj.headers = {
			...axiosObj.headers,
			authkey: param.authkey,
		}
	}
	if (method === 'GET' && !param.data) {
		axiosObj.data = {unused: 0} // 这个是关键点，加入这行就可以了  解决get  请求添加不上content_type
	}
	//post
	if (method == 'POST') {
		axiosObj.data = param.data
	}
	return new Promise((resolve, reject) => {
		let isTipLogin = sessionStorage.getItem('isTipLogin');
		axios(axiosObj).then(res => {
			resolve(res.data)
		}).catch(err => {
			reject(err)
			console.table(err)
			//请求错误统一处理
			if (!err.response) {
				// 断网了
				console.log(err)
				err.message = '网络连接不可用'
			} else {
				switch (err.response.status) {
					case 400:
						err.message = '请求错误'
						break
					case 401: {
						isTipLogin = sessionStorage.getItem('isTipLogin');
						err.message = isTipLogin != 1 ? '登录过期，请重新登录' : '';
						sessionStorage.setItem('isTipLogin', 1)
						setTimeout(() => {
							window.location = process.env.VUE_APP_PAGEURL;
						}, 1500);
						err.message = '未授权，请登录';
						// this.$router.push({ path: '/' });
					}
					    break;
					case 403:
						err.message = '拒绝访问'
						break
					case 404:
						err.message = `请求地址出错: ${err.response.config.url}`
						break
					case 408:
						err.message = '请求超时';
						break
					case 500:
						err.message = err.response.data.message ? err.response.data.message : '服务器内部错误'
						break
					case 501:
						err.message = '服务未实现'
						break
					case 502:
						err.message = '网关错误'
						break
					case 503:
						err.message = '服务不可用'
						break
					case 504:
						err.message = '网关超时'
						break
					case 505:
						err.message = 'HTTP版本不受支持'
						break
					default:
				}
			}
			setTimeout(() => {
				Message.closeAll();
				Message({
					message: err.message,
					type: 'error'
				})
			})
		});
	})
}
