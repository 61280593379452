<template>
  <el-popover
      ref="popover"
      :popper-class="className ? className + ' w-label-replace-popover' : 'w-label-replace-popover'"
      placement="bottom-start"
      :visible-arrow="visibleArrow"
      :trigger="trigger ? trigger : 'click'"
      :key="'lable-' + info.Id"
      @after-enter="dispatchPopoverState('after-enter')"
      @after-leave="dispatchPopoverState('after-leave')"
      @show="show"
      @hide="hide"
  >
    <template v-slot:default>
      <template v-if="!customContent">
        <div class="title-div" v-if="!custom">移动至：</div>
        <el-cascader-panel
            :key="'panel-' + labelList.length + value.length"
            :value="value"
            :options="labelList"
              :props="{
              value: 'Id',
              label: 'LabelContent',
              children: allChild ? 'ChildList' : 'list',
              multiple: false
            }"
            @change="change"
            :border="false"
        >
        </el-cascader-panel>
      </template>
      <slot name="content" v-else></slot>
    </template>
    <i
        v-if="!custom && (!info.ChildList || info.ChildList <= 0)"
        class="icon-i iconfont icon_move"
        slot="reference"
        title="移动标签"
    ></i>
    <slot name="custom" slot="reference"></slot>
  </el-popover>
</template>

<script>
export default {
  name: "label-draggable-popover",
  props: {
    info: {
      type: Object,
      default() {
        return {}
      }
    },
    list: {
      type: Array,
      default() {
        return [];
      }
    },
    allChild: {
      type: Boolean,
      default: false
    },
    custom: {
      type: Boolean,
      default: false
    },
    customContent: {
      type: Boolean,
      default: false
    },
    visibleArrow: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    },
    trigger: {
      type: String,
      default: ''
    },
    /**
     * 移动
     */
    isMove: {
      type: Boolean,
      default: false
    },
    delReplace: {
      type: Boolean,
      default: false
    },
    selValue: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      labelList: [],
      value: [],
    }
  },
  // watch: {
  //   list: {
  //     handler() {
  //       this.getData();
  //     },
  //     immediate: true,
  //     deep: true
  //   }
  // },
  methods: {
    show() {
      if (this.delReplace) {
        this.value = this.selValue;
      } else {
        this.labelList = [];
        this.value = [];
      }
      this.getData();
      this.$emit('show');
    },
    hide() {
      this.$emit('hide');
    },
    change(e) {
      this.value = e;
      this.$emit('moveChange', e)
    },
    getData() {
      this.labelList = this.$Clone(this.list);
      // 移动
      if (this.isMove) {
        this.labelList.forEach(c => {
          if (c.list && c.list.length > 0) {
            c.list = [
              {
                Id: c.Id,
                LabelContent: '不限',
                ParentId: '',
              },
              ...c.list
            ]
          }
        })
      }
    },
    // 来自全局的关闭遮罩的回调
    closePopover(ids) {
      if (
          (
              !ids ||
              ids.length <= 0
          )
          ||
          (
              ids &&
              ids.includes(this.info.Id.toString())
          )
      ) {
        this.$nextTick(() => {
          if (this.$refs.popover) {
            this.$refs.popover.doClose();
          }
        })
      }
    },
    // 分发弹层的展示动作
    dispatchPopoverState(type) {
      this.$bus.$emit('label-draggable-popover-status-change', {
        type,
        id: this.info.Id
      })
    }
  },
  created() {
    this.$bus.$on('label-draggable-close-popover', this.closePopover)
    this.$on('hook:beforeDestroy', () => {
      this.$bus.$off('label-draggable-close-popover', this.closePopover)
    })
  }
}
</script>
<style>
body .w-label-replace-popover {
  width: auto;
  border: none;
  padding: 0;
  border-radius: 0;
}
.theme-Dark .w-label-replace-popover,
.theme-Plus .w-label-replace-popover {
  background: #333;
}
.theme-Dark .w-label-replace-popover .el-cascader-node:not(.is-disabled):focus,
.theme-Dark .w-label-replace-popover .el-cascader-node:not(.is-disabled):hover,
.theme-Plus .w-label-replace-popover .el-cascader-node:not(.is-disabled):focus,
.theme-Plus .w-label-replace-popover .el-cascader-node:not(.is-disabled):hover {
  background: #444;
  color: #fc0;
}
.w-label-replace-popover .el-cascader-node.in-active-path,
.w-label-replace-popover .el-cascader-node.is-active,
.w-label-replace-popover .el-cascader-node.is-selectable.in-checked-path {
  background: #F5F5F5;
  color: #222;
}

.theme-Dark .w-label-replace-popover .el-cascader-node.in-active-path,
.theme-Dark .w-label-replace-popover .el-cascader-node.is-active,
.theme-Dark .w-label-replace-popover .el-cascader-node.is-selectable.in-checked-path,
.theme-Plus .w-label-replace-popover .el-cascader-node.in-active-path,
.theme-Plus .w-label-replace-popover .el-cascader-node.is-active,
.theme-Plus .w-label-replace-popover .el-cascader-node.is-selectable.in-checked-path {
  background: #444;
  color: #fc0;
}
.theme-Dark .el-cascader-menu,
.theme-Plus .el-cascader-menu {
  border-color: #444;
  color: #999!important;
}
.tips-label.el-popper[x-placement^=bottom] .popper__arrow,
.tips-label.el-popper[x-placement^=bottom] .popper__arrow::after {
  border-bottom-color: #333;
}
</style>
<style scoped lang="scss">
.theme-Dark .w-label-replace-popover .title-div,
.theme-Plus .w-label-replace-popover .title-div {
  border-color: #444;
  color: #999;
  font-weight: 600;
}
::v-deep .el-cascader-menu {
  width: 140px;
  min-width: 100px;
  box-sizing: border-box;
  color: #666;
  .el-cascader-node {
    padding-left: 0;
  }
  .el-cascader-node__prefix {
    right: 10px;
    left: auto;
    font-weight: 600;
  }
}
::v-deep .el-cascader-panel {
  padding: 0 8px;
}
.title-div {
  line-height: 40px;
  color: #222222;
  padding-left: 8px;
  box-sizing: border-box;
  border-bottom: 1px solid #e4e7ed;
}
.icon-i {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 24px;
  background: transparent;
  cursor: pointer;
  color: $font-color-second;
  &:hover {
    background: $tab-color;
    color: $font-color-import2;
    @include themify($themes) {
      background: themed("hover-bg");
      color: themed("input-color");
    }
  }
}
</style>
