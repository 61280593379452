/**
 * 资料夹内 内容有变动 此文件夹需要修改
 */
import {mapActions, mapState} from "vuex";

export default {
    computed: {
        ...mapState({
            zlCount: (state) => state.zlCount, // 资料夹总数
            folderInfo: (state) => state.folderInfo,
            addParam: (state) => state.addParam,
        }),
    },
    methods: {
        ...mapActions({
            getFolderApiContent: "getFolderApi",
        }),
        /**
         * 判断 添加到内容 是否存在收藏夹里
         */
        switchExistsFolder() {
            let isExistsObj = {
                isExists: false,
                name: '内容'
            };
            const addParam = this.addParam;
            if (!this.addParam) return isExistsObj;

            switch (addParam.type) {
                case 1: // 案例作品
                    isExistsObj = {
                        isExists: !!(this.folderInfo.zpData || []).find(zp =>
                            zp.Type === 1 &&
                            zp.Source === 1 &&
                            zp.Works === addParam.works
                        ),
                        name: '案例'
                    }
                    break;
                case 3: // 作品 （金刚资源 -> 作品）
                    isExistsObj = {
                        isExists: !!(this.folderInfo.zpData || []).find(zp =>
                            zp.Type === 3 &&
                            zp.Source === 2 &&
                            zp.Works === addParam.works
                        ),
                        name: '作品'
                    }
                    break;
                case 4: // 金刚
                    isExistsObj = {
                        isExists: !!(this.folderInfo.jgData || []).find(zp =>
                            zp.Type === 4 &&
                            zp.Source === 2 &&
                            zp.Works === addParam.works
                        ),
                        name: addParam.fromSource || '资源'
                    }
                    break;
                case 5: // 案例图集
                    isExistsObj = {
                        isExists: !!(this.folderInfo.imgData || []).find(zp =>
                            zp.Type === 5 &&
                            (
                                zp.Source === 1 ||
                                zp.Source === 2
                            ) &&
                            zp.Works === addParam.works
                        ),
                        name: '图片'
                    }
                    break;
                case 6: // 酒店
                    isExistsObj = {
                        isExists: !!(this.folderInfo.hotelData || []).find(zp =>
                            zp.Type === 6 &&
                            zp.Source === 1 &&
                            zp.Works === addParam.works
                        ),
                        name: '图集'
                    }
                    break;
            }
            return isExistsObj;
        },
        /**
         * 获取资料夹内容
         *   只获取了资料夹总数量 没有获取资料夹具体数据
          */
        async getFolderContent() {
            if (
                this.zlCount > 0 &&
                (
                    !this.folderInfo.imgData ||
                    this.folderInfo.imgData.length <= 0
                ) &&
                (
                    !this.folderInfo.jgData ||
                    this.folderInfo.jgData.length <= 0
                ) &&
                (
                    !this.folderInfo.zpData ||
                    this.folderInfo.zpData.length <= 0
                ) &&
                (
                    !this.folderInfo.hotelData ||
                    this.folderInfo.hotelData.length <= 0
                )
            ) {
                if (!sessionStorage.getItem("hostAuthStr")) {
                    try {
                        await this.getHostHeadApi();
                    } catch (error) {
                        console.log(error, 'getHostHead');
                    }
                }
                return this.getFolderApiContent(sessionStorage.getItem("hostAuthStr"))
            }
        },
        /**
         * 获取金刚所需请求头
         */
        getHostHeadApi() {
            return new Promise((resolve) => {
                this.$axios({
                    url: process.env.VUE_APP_TEAMURL + "xlapi/HostManage/HostUserManage/HostUserLogin/GetHostUserIdentity",
                    method: "POST",
                    data: {
                        BranchId: sessionStorage.getItem("BranchId"),
                        BranchSource: "lh",
                        userid: sessionStorage.getItem("UserId"),
                    },
                    success(res) {
                        if (res && res.data && res.data.AuthorizationStr) {
                            sessionStorage.setItem("hostAuthStr", res.data.AuthorizationStr);
                            resolve(res.data.AuthorizationStr);
                        } else {
                            resolve(null);
                            this.$Error(
                                res.data.msg ? res.data.msg : `获取金刚系统所需请求头失败!`
                            );
                        }
                    },
                    error() {
                        resolve(null);
                        this.$Error(`获取金刚系统所需请求头失败`);
                    },
                });
            });
        },
        /**
         * 从资料夹中移除
         */
        async del_Folder(option) {
            const {
                zpFolderData = [], // 案例作品
                imgFolderData = {
                    Pid: '',
                    Source: 1 || 2,
                    Type: 5
                } || [], // 图集
                jgFolderData = [], // 金刚资源
                hotelFolderData = [], // 酒店
            } = option;

            if (
                zpFolderData.length <= 0 &&
                (
                    !imgFolderData ||
                    imgFolderData.Pid === '' ||
                    imgFolderData.length <= 0
                ) &&
                jgFolderData.length <= 0 &&
                hotelFolderData.length <= 0
            ) return;

            if (!sessionStorage.getItem("hostAuthStr")) {
                try {
                    await this.getHostHeadApi();
                } catch (error) {
                    console.log(error, 'getHostHead');
                }
            }

            // 判断当前资料夹是否存在该作品图集
            try {
                await this.getFolderContent(); // 只获取了资料夹总数量 没有获取资料夹具体数据
            } catch (error) {
                console.log(error, 'getFolderApi');
            }

            let delAllArr = [];

            let delImg = []; // 图集
            // 图集不是数组的情况
            if (
                imgFolderData &&
                Object.prototype.toString.call(imgFolderData) === '[object Object]'
            ) {
                delImg = this.folderInfo.imgData.filter(c =>
                    c.Pid + '' === imgFolderData.Pid + '' &&
                    c.Source === imgFolderData.Source &&
                    c.Type === imgFolderData.Type
                );
            } else {

                // 图集是数组的情况
                delImg = this.folderInfo.imgData.filter(c =>
                    !!imgFolderData.find(l =>
                        l.Works + '' === c.Works + '' &&
                        l.Type === c.Type &&
                        l.Source === c.Source &&
                        (
                            !l.Pid ||
                            l.Pid + '' === c.Pid + ''
                        )
                    )
                )
            }

            // 其他： 案例、酒店、资源
            let delArr = [];
            const delData = [...zpFolderData,...jgFolderData,...hotelFolderData];

            const jgData = this.folderInfo.jgData || [];
            const zpData = this.folderInfo.zpData || [];
            const hotelData = this.folderInfo.hotelData || [];

            let folderList = [
                ...jgData,
                ...zpData,
                ...hotelData
            ];
            delArr = folderList.filter(c =>
                !!delData.find(l =>
                    l.Works + '' === c.Works + '' &&
                    l.Type === c.Type &&
                    l.Source === c.Source &&
                    (
                        !l.Pid ||
                        l.Pid + '' === c.Pid + ''
                    )
                )
            )
            delAllArr = [...delImg, ...delArr];

            let delFolderId = delAllArr.map(c => c.Id + '');
            try {
                await this.removeFolderList(delFolderId, 0);
            } catch (err) {
                console.log(err, '删除资料夹失败');
            }
            this.getFolderApiContent(sessionStorage.getItem("hostAuthStr")).then(() => {});
        },
        /**
         * 删除多个
         * @param Ids 删除Id // type 0 时， 资料夹Id  ， 不为 0 时    案例/机构等的绑定主键Id
         * @param type
         */
        removeFolderList(Ids,type) {
            if (
                !Ids ||
                Ids.length <= 0 ||
                type === undefined ||
                type === null
            ) return;

            let data = {
                FolderType: type,
                Ids: Ids, // type 0 时， 资料夹Id  ， 不为 0 时	案例/机构等的绑定主键Id
            }
            return new Promise(resolve => {
                this.$axios({
                    url: process.env.VUE_APP_TEAMURL + "xlapi/HostManage/ShareWork/Folder/FolderDelByType",
                    method: "POST",
                    authorizationStr: sessionStorage.getItem("hostAuthStr"),
                    data: data,
                    success: (res) => {
                        resolve(res);
                        if (
                            !res ||
                            !res.data ||
                            !res.data.status
                        ) {
                            this.$Error(res.data.msg ? res.data.msg : "移除失败");
                        }
                    },
                    error: (err) => {
                        resolve(null);
                        console.log(err);
                        this.$Error("移除失败");
                    },
                });
            })
        },
    }
}
