/*
 * @Author: tangshuo
 * @Date: 2020-08-10 15:01:41
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-09-07 10:41:22
 * @Remarks: 酒店相关store
 */
import { getHotelList, getLabelHotelList, getHotelSearchModel } from "@/api/hotel";
export default {
	namespaced: true,
	state: {
		cityList: [[], []], //城市列表[本店城市，官方城市]
		lableList: [[], []], //标签列表[本店标签，官方标签]
		hotelCount: [{ jxCount: 0, allCount: 0 }, { jxCount: 0, allCount: 0 }],//标签列表[本店标签，官方标签]
		hotelList: [],//酒店列表
		lableFolders: [],//标签文件夹
	},
	mutations: {
		//设置店铺酒店列表
		setHotelList(state, data) {
			state.hotelList = data;
		},
		//设置店铺标签文件夹
		setLableFolders(state, data) {
			state.lableFolders = data;
		},
		//设置店铺/官方 标签/城市列表
		setLableCitys(state, data) {
			let lableList = data.dataList.labelInfos || [];
			let cityList = data.dataList.cityInfos || [];
			let hotelCount = data.dataList.typeInfos || [];
			let type = data.type ? data.type : 0
			state.cityList[type] = cityList;
			state.lableList[type] = lableList;
			state.hotelCount[type] = {
				jxCount: hotelCount.HotCount || 0, allCount: hotelCount.AllCount || 0
			};
		},
	},
	getters: {},
	actions: {
		//获取店铺酒店信息
		getHotelList(store, params) {
			let paramsApi = {
				SerchName: params.searchName,
				PageIndex: params.pageIndex,
				PageSize: params.pageSize,
				LabelIds: params.labelIds,
				CityIds: params.cityIds,
				Type: params.type,
				IsShowHot: params.isShowHot
			};
			getHotelList(paramsApi).then(res => {
				if (res.status && res.data) {
					let hotelList = res.data || [];
					let allCount = 0;
					let cosIp = sessionStorage.getItem("BranchCosCdnUrl_B");
					hotelList.forEach((c) => {
						c.fileStr = "";
						if (
							c.HotelFirstYsBanner &&
							c.HotelFirstYsBanner.indexOf("http://") < 0 &&
							c.HotelFirstYsBanner.indexOf("https://") < 0
						) {
							c.fileStr = cosIp + c.HotelFirstYsBanner;
						} else if (!c.HotelFirstYsBanner) {
							c.fileStr = "";
						} else {
							c.fileStr = c.HotelFirstYsBanner;
						}
						c.labelList = [];
						c.labelKeyList = [];
						if (c.LabelInfos) {
							for (const key in c.LabelInfos) {
								c.labelList.push(c.LabelInfos[key]);
								c.labelKeyList.push(parseInt(key));
							}
						}
					});
					allCount = res.page.PageCount;
					store.commit("setHotelList", hotelList);
					params && params.callback ? params.callback(hotelList, allCount) : ''
				} else {
					params && params.error ? params.error(res) : ''
				}

			}).catch(err => {
				params && params.error ? params.error(err) : ''
			})
		},
		//获取店铺酒店信息
		getLabelHotelList(store, params) {
			let paramsApi = {
				SerchName: params.searchName,
				PageIndex: params.pageIndex,
				PageSize: params.pageSize,
				Type: params.type,
				IsShowHot: params.isShowHot
			};
			getLabelHotelList(paramsApi).then(res => {
				if (res.status && res.data) {
					let lableList = res.data || [];
					let allCount = 0;
					let cosIp = sessionStorage.getItem("BranchCosCdnUrl_B");
					lableList.forEach((c) => {
						c.coverPath = "";
						if (
							c.CoverImg &&
							c.CoverImg.indexOf("http://") < 0 &&
							c.CoverImg.indexOf("https://") < 0
						) {
							c.coverPath = cosIp + c.CoverImg;
						} else if (!c.CoverImg) {
							c.coverPath = "";
						} else {
							c.coverPath = c.CoverImg;
						}

					});
					allCount = res.page.PageCount;
					store.commit("setLableFolders", lableList);
					params && params.callback ? params.callback(lableList, allCount) : ''
				} else {
					params && params.error ? params.error(res) : ''
				}

			}).catch(err => {
				params && params.error ? params.error(err) : ''
			})
		},
		//获取店铺/官方 标签/城市
		getHotelSearchModel(store, params) {
			//type传all 同时获取店铺/官方数据
			let paramsApi = {
				Type: params.type,
				IsShowHot:params.isShowHot,
			};
			if (params.type === 'all') {
				//同时获取店铺/官方数据
				Promise.all([getHotelSearchModel({ Type: 0 }), getHotelSearchModel({ Type: 1 })]).then(res => {
					if (res[0].status) {
						let dataList = res[0].data || {};
						store.commit("setLableCitys", { dataList, type: 0 });
					} else {
						params && params.error ? params.error(res) : ''
					}
					if (res[1].status) {
						let dataList = res[1].data || {};
						store.commit("setLableCitys", { dataList, type: 1 });
					}
					if (res[1].status && res[0].status) {
						params && params.callback ? params.callback([res[0].data, res[1].data]) : ''
					} else {
						params && params.error ? params.error(res) : ''
					}

				}).catch(err => {
					params && params.error ? params.error(err) : ''
				})
				return
			}
			getHotelSearchModel(paramsApi).then(res => {
				if (res.status && res.data) {
					let dataList = res.data || {};
					store.commit("setLableCitys", { dataList, type: params.type });
					params && params.callback ? params.callback(dataList) : ''
				} else {
					params && params.error ? params.error(res) : ''
				}

			}).catch(err => {
				params && params.error ? params.error(err) : ''
			})
		},
	}
}
