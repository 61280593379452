<template>
<!-- [本地优质资源]个性化内容推荐设置 改为 -->
  <ConDig
    :title="isTeamIndexPage ? '范围设置' : '[附近优质资源]个性化内容推荐设置'"
    width="480px"
    :modal="true"
    :isShowDig="showAddAround"
    confirmText="保存"
    @closeDig="handleClose()"
    @open="initData"
    :appendToBody="true"
    @cancelClick="handleClose()"
    @confirmClick="saveAround"
  >
    <div slot="dialogMain" class="addAround-main">
      <p class="tips-p" v-if="isTeamIndexPage">提示：设置你当前店铺的本地及周边地区，便于我们为您精准推送本地优质资源</p>
      <div class="set-item-box">
        <el-checkbox v-model="isDefault" class="item-checkbox"></el-checkbox>
        <div class="item-info-box">
          <span class="item-title" @click="isDefault = true">默认</span>
          <span class="item-less-title"
            >默认规则：基于本店合作资源所在地数量最多而智能推荐。</span
          >
        </div>
      </div>
      <div class="set-item-box">
        <el-checkbox v-model="isCustom" class="item-checkbox"></el-checkbox>
        <div class="item-info-box">
          <span class="item-title" @click="isCustom = true">自定义</span>
          <span class="item-less-title"
            >自定义规则：您可以手动设置本地及周边城市为推送范围，届时我们会根据您的设定推送所选城市的优质资源。</span
          >
          <div class="around-city-box">
            <el-popover
              placement="bottom-start"
              width="200"
              trigger="click"
              :visible-arrow="false"
              popper-class="around-city-popover"
            >
              <CitySelectDig
                @confirmSel="selectCity"
                :curIndex="0"
                :selCity="aroundCity"
              ></CitySelectDig>
              <span slot="reference" class="city-item city-item-add">
                <i class="iconfont icon_add_small"></i>
              </span>
            </el-popover>

            <span
              class="city-item"
              v-for="around of aroundCity"
              :key="around.id"
              >{{ around.peripheryCityName }}
              <ConIcon
                class="close-icon"
                size="min"
                icon="icon_close_small"
                @click="removeCity(around)"
              ></ConIcon
            ></span>
          </div>
        </div>
      </div>
    </div>
  </ConDig>
</template>

<script>
import CitySelectDig from "./citySelectDig";
export default {
  data() {
    return {
      isShowAddCity: false, //是否显示添加城市弹窗
      isShowCity: false,
      cityList: [],
      cityName: "",
      aroundCity: [],
      isCustom: false, //是否为自定义
      isDefault: true, //是否为默认
      hostAuthStr: sessionStorage.getItem("hostAuthStr"), //金刚系统所需请求头
    };
  },
  props: {
    showAddAround: {
      default: false,
    },
    // 是否是金刚资源 team路由 的 本地范围
    isTeamIndexPage: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isDefault(val) {
      if (val) {
        this.isCustom = false;
      } else {
        if (!this.isCustom) this.isDefault = true;
      }
    },
    isCustom(val) {
      if (val) {
        this.isDefault = false;
      } else {
        if (!this.isDefault) this.isCustom = true;
      }
    },
    aroundCity(val) {
      if (val.length > 0) {
        this.isCustom = true;
      }
    },
  },
  components: {
    CitySelectDig,
  },
  created() {
    document.body.addEventListener(
      "click",
      () => {
        this.isShowCity = false;
      },
      false
    );
  },
  mounted() {},
  methods: {
    /**
     * 初始化数据
     */
    initData() {
      this.cityName = "";
      this.getBranchPCity();
    },
    selectCity(selList) {
      selList.forEach((city) => {
        city.peripheryCityCode = city.peripheryCityCode || city.code;
        city.peripheryCityName = city.peripheryCityName || city.name;
      });
      //移除未选择的
      this.aroundCity = this.aroundCity.filter(
        (city) => !!selList.find((s) => s.code === city.code)
      );
      //添加新选择的
      let list = selList.filter(
        (s) => !this.aroundCity.find((a) => a.code === s.code)
      );

      this.aroundCity = [...this.aroundCity, ...list];
    },
    /**
     * 添加到列表
     */
    addAround(city) {
      this.aroundCity.push({
        id: 0,
        peripheryCityCode: city.code,
        peripheryCityName: city.name,
      });
      this.cityName = "";
    },
    /**
     * 获取周边城市
     */
    getBranchPCity() {
      this.$axios({
        url:
          process.env.VUE_APP_TEAMURL +
          "xlapi/HostManage/NewWeddingManage/NewWeddingInfo/GetBranchPCity",
        method: "POST",
        data: {
          branchId: sessionStorage.getItem("BranchId"),
          branchSource: "lh",
        },
        success(res) {
          if (res.data.status) {
            let aroundCity = res.data.data || [];
            aroundCity.forEach((city) => {
              city.code = city.peripheryCityCode;
            });
            this.aroundCity = aroundCity;
          }
        },
        error() {
          this.$Error("获取周边城市列表失败");
        },
      });
    },
    /**
     * 移除周边城市
     */
    removeCity(city) {
      let index = this.aroundCity.findIndex(
        (a) => a.peripheryCityCode === city.peripheryCityCode
      );
      this.aroundCity.splice(index, 1);
    },
    /**
     * 保存周边城市
     */
    saveAround() {
      this.$axios({
        url:
          process.env.VUE_APP_TEAMURL +
          "xlapi/HostManage/NewWeddingManage/NewWeddingInfo/InsertBranchPCity",
        method: "POST",
        data: {
          addUser: sessionStorage.getItem("UserId"),
          branchId: sessionStorage.getItem("BranchId"),
          branchSource: "lh",
          listInfo: this.isDefault ? [] : this.aroundCity,
        },
        success(res) {
          if (res.data.status) {
            this.$Success("操作成功");
            this.handleClose(true);
          } else {
            this.$Error("操作失败!");
          }
        },
        error() {
          this.$Error("获取城市列表失败");
        },
      });
    },
    handleClose(isChange = false) {
      this.$emit("closeDig", isChange);
    },
  },
};
</script>

<style scoped lang="scss">
.addAround-main {
  padding: 16px 24px;
  .set-item-box {
    padding-top: 8px;
    display: flex;
    &:last-child .item-info-box {
      border: none;
      @include themify($themes) {
        border: none;
      }
    }
    .item-info-box {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      padding-bottom: 16px;
      border-bottom: 1px solid #eeeeee;
      flex: 1;
      @include themify($themes) {
        border-bottom: 1px solid themed("search-his-bg-h");
      }
      .item-title {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
        line-height: 24px;
        cursor: pointer;
        @include themify($themes) {
          color: themed("input-color");
        }
      }
      .item-less-title {
        margin-top: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
      }
      .around-city-box {
        width: 100%;
        height: 140px;
        overflow: auto;
        .city-item {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 32px;
          padding: 0 8px;
          border: 1px solid #eeeeee;
          margin: 8px 8px 0 0;
          display: inline-flex;
          align-items: center;
          @include themify($themes) {
            border: 1px solid themed("search-his-bg-h");
          }
        }
        .city-item-add {
          cursor: pointer;
          position: relative;
          &:hover {
            background: #eeeeee;
          }
        }
        .close-icon {
          margin-left: 8px;
        }
      }
    }
    .item-checkbox {
      margin-top: 4px;
    }
  }
  ::v-deep .el-checkbox {
    margin-right: 0;
    .el-checkbox__input {
      .el-checkbox__inner {
        width: 16px;
        height: 16px;
        background-color: transparent;
        border-color: #dddddd;
        &:hover,
        &:focus,
        &:active {
          border-color: #333333;
        }

        &::after {
          height: 5px;
          width: 3px;
          left: 5px;
          top: 3px;
          content: "";
          border: 2px solid #fff;
          @include themify($themes) {
            border-color: themed("color-gou");
          }
          border-left: 0;
          border-top: 0;
          position: absolute;
        }
      }
    }
    .el-checkbox__input.is-focus .el-checkbox__inner {
      border-color: #333333;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #333333;
      border-color: #333333 !important;
    }
  }
}
.tips-p {
  font-size: 12px;
  color: #999;
  margin-bottom: 10px;
}
</style>
<style lang="scss">
.around-city-popover {
  width: 120px;
  min-width: 100px;
  background: rgb(255, 255, 255);
  @include themify($themes) {
    background: themed("search-bg");
    border-radius: themed("border-radius-min");
  }
  border: 0;
  padding: 8px 0;
}
</style>
