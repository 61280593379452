/*
 * @Author: tangshuo
 * @Date: 2020-08-10 15:01:41
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-09-28 14:34:03
 * @Remarks: 基础信息store
 */
import {
	getBranchHotelList,
	GetUserInfoDetailNew
} from "@/api/base";
export default {
	namespaced: true,
	state: {
		branchInfo: {}, //店铺信息
		cityList: [], //城市列表
		branchHotelList: [], //店铺酒店信息
		acceptImage: [{
			type: "image/png",
			typeStr: ".png",
		},
		{
			type: "image/jpeg",
			typeStr: ".jpg|.jpeg",
		},
		{
			type: "image/gif",
			typeStr: ".gif",
		},
		], //图片限制格式列表
		acceptVideo: [{
			type: "video/mp4",
			typeStr: ".mp4",
		},
		{
			type: "video/x-m4v",
			typeStr: ".m4v",
		},
		{
			type: "video/quicktime",
			typeStr: ".mov",
		},
		], //视频限制格式列表
	},
	mutations: {
		//设置店铺酒店信息
		setBranchHotelList(state, data) {
			state.branchHotelList = data;
		},
		//设置店铺基础信息
		setBranchInfo(state, data) {
			state.branchInfo = data;
		},
	},
	getters: {},
	actions: {
		//获取当前店铺酒店列表
		getBranchHotelList(store, params) {
			getBranchHotelList().then(res => {
				if (res.status && res.data) {
					store.commit("setBranchHotelList", res.data);
				} else {
					store.commit("UserInfo/setTipObj", {
						type: 'err', //弹窗类型
						massge: res.msg,
						isShowTip: true //是否显示弹窗
					}, {
						root: true
					});
				}
				params ? params.callback() : ''
			})
		},
		//获取当前店铺基础信息
		getGetUserInfo(store, params) {
			let userId = sessionStorage.getItem("UserId")
			GetUserInfoDetailNew(userId).then(res => {
				console.log(res)
				if (res.status && res.data) {

					if (res.data.UserHeadImg && (res.data.UserHeadImg.indexOf('http') == -1 && res.data.UserHeadImg.indexOf('https') == -1)) {
						res.data.UserHeadImgSrc = sessionStorage.getItem("BranchCosCdnUrl") + res.data.UserHeadImg;
					} else {
						res.data.UserHeadImgSrc = res.data.UserHeadImg ? res.data.UserHeadImg : '/images/base/touxiang.png';
					}

					console.log(res.data.UserHeadImgSrc)

					store.commit("setBranchInfo", res.data);
				} else {
					// this.$Message("标签名称不能为空",'suc');
					// store.commit("UserInfo/setTipObj", {
					// 	type: 'err', //弹窗类型
					// 	massge: res.msg,
					// 	isShowTip: true //是否显示弹窗
					// }, { root: true });
				}
				params ? params.callback() : ''
			})
		},
	}
}
