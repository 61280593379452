/*
 * @Author: tangshuo
 * @Date: 2021-02-19 11:55:09
 * @LastEditors: tangshuo
 * @LastEditTime: 2022-01-27 15:29:38
 * @Remarks: 金刚相关api
 */
import config from "@/assets/js/config/config";
import { httpServe } from '@/assets/js/utils/request';

/**
 * 获取金刚系统所需请求头
 */
export function getHostUserIdentity() {
    return httpServe({
        url: `${config.hostApi}/xlapi/HostManage/HostUserManage/HostUserLogin/GetHostUserIdentity`,
        method: 'POST',
        data: {
            BranchId: sessionStorage.getItem("BranchId"),
            BranchSource: "lh",
            userid: sessionStorage.getItem("UserId"),
        }
    });
}
/**
 * 邀请友商
 * @param {*} params
 * @returns
 */
export function inviteBaseUser(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostGroupManage/GroupInfo/InviteBaseUser`,
        method: 'POST',
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        data: params
    });
}

/**
 * 获取好友分组
 * @param {*} params
 * @returns
 */
export function getFriendGroupList(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostGroupManage/GroupInfo/GetFriendGroupList`,
        method: 'GET',
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        data: params
    });
}

/**
 * 使用接口转存数据
 * @param {*} params
 * @returns
 */
export function addTempMsg(params) {
    return httpServe({
        url: `${config.baseApi}/NewWeddingApi/UserLogin/LoginMethod/UserLogin/AddTempMsg`,
        method: 'POST',
        data: params
    });
}

/**
 * 模块城市查询
 * @param {*} params
 * @returns
 */
export function getWeddingCityCodeList(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/NewWeddingManage/HostBranchNew/GetWeddingCityCodeList`,
        method: 'POST',
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        data: params
    });
}

/**
 * 获取全部资源(人员)
 * @param {*} params
 * @returns
 */
export function getBranchShowList(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/NewWeddingManage/HostBranch/BranchShowList`,
        method: 'POST',
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        data: params
    });
}

/**
 * 获取全部资源(作品)
 * @param {*} params
 * @returns
 */
export function getWorkListGetGroup(params) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/NewWeddingManage/HostBranch/WorkListGetGroup`,
        method: 'POST',
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        data: params
    });
}

/**
 * 获取本地优质资源
 * @param {*} params
 * @returns
 */
export function getHighQualityTeamList(params) {
    return httpServe({
        url: `${config.hostApi}/xlapi/HostManage/NewWeddingManage/HostBranchNew/GetHighQualityWeddingList`,
        method: 'POST',
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        data: params
    });
}

/**
 * 获取一线大咖资源
 * @param {*} params
 * @returns
 */
export function getRecommendTeamList(params) {
    return httpServe({
        url: `${config.hostApi}/xlapi/HostManage/NewWeddingManage/HostBranchNew/GetRecommendWeddingList`,
        method: 'POST',
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        data: params
    });
}

/**
 * 获取本地最新入驻资源
 * @param {*} params
 * @returns
 */
export function getNewestInTeamList(params) {
    return httpServe({
        url: `${config.hostApi}/xlapi/HostManage/NewWeddingManage/HostBranchNew/GetNewestInWeddingList`,
        method: 'POST',
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        data: params
    });
}

/**
 * 获取本地最新入驻资源 总数
 * @param {*} params
 * @returns
 */
export function GetNewestInWeddingCount(params) {
    return httpServe({
        url: `${config.hostApi}/xlapi/HostManage/NewWeddingManage/HostBranchNew/GetNewestInWeddingCount`,
        method: 'POST',
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        data: params
    });
}
/**
 * 优质作品赏析（一线大咖资源作品）
 * @param {*} params
 * @returns
 */
export function getRecommendWorkList(params) {
    return httpServe({
        url: `${config.hostApi}/xlapi/HostManage/NewWeddingManage/HostBranchNew/GetRecommendWeddingWorkList`,
        method: 'POST',
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        data: params
    });
}


/**
 * 本地近期更新作品(本地优质资源作品)
 * @param {*} params
 * @returns
 */
export function getHighQualityWorkList(params) {
    return httpServe({
        url: `${config.hostApi}/xlapi/HostManage/NewWeddingManage/HostBranchNew/GetHighQualityWeddingWorkList`,
        method: 'POST',
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        data: params
    });
}

/**
 * 批量新增添加好友合作
 * @param {*} params
 * @returns
 */
export function InviteBaseUserBatch(params) {
    return httpServe({
        url: `${config.hostApi}/xlapi/HostManage/HostGroupManage/GroupInfo/InviteBaseUserBatch`,
        method: 'POST',
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        data: params
    });
}
