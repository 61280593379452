import { render, staticRenderFns } from "./king-kong-case-display-version.vue?vue&type=template&id=b661557a&scoped=true&"
import script from "./king-kong-case-display-version.vue?vue&type=script&lang=js&"
export * from "./king-kong-case-display-version.vue?vue&type=script&lang=js&"
import style0 from "./king-kong-case-display-version.vue?vue&type=style&index=0&lang=css&"
import style1 from "./king-kong-case-display-version.vue?vue&type=style&index=1&id=b661557a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b661557a",
  null
  
)

export default component.exports