/*
 * @Author: tangshuo
 * @Date: 2021-01-18 20:16:19
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-09-24 10:45:53
 * @Remarks: 路由页面
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/home',
    name: 'home',
    component: home,
    redirect: '/company',
    children: [{
            path: '/company',
            name: 'company',
            component: () => import(/* webpackChunkName: 'company' */ '../views/home/home'),
        },
        {
            path: '/case',
            name: 'case',
            component: () => import(/* webpackChunkName: 'case' */ '../views/case/index'),
        },
        {
            path: '/hotel',
            name: 'hotel',
            component: () => import(/* webpackChunkName: 'hotel' */ '../views/hotel/index')
        },
        {
            path: '/team',
            name: 'team',
            component: () => import(/* webpackChunkName: 'team' */ '../views/team'),
        },
        {
            path: '/banquet_hall',
            name: 'banquet_hall',
            component: () => import(/* webpackChunkName: 'banquet_hall' */ '../views/banquet_hall'),
        },
        {
            path: '/article',
            name: 'article',
            component: () => import(/* webpackChunkName: 'article' */ '../views/article'),
        },
        {
            path: '/inspira',
            name: 'inspira',
            component: () => import(/* webpackChunkName: 'inspira' */ '../views/newInspiration/newInspiration.vue'),
        },
        // 发现
        {
            path: '/discover',
            name: 'discover',
            component: () => import(/* webpackChunkName: 'discover' */ '../views/find/index.vue'),
        },
        {
            path: '/noNavIndex',
            name: 'noNavIndex',
            component: () => import(/* webpackChunkName: 'noNavIndex' */ '../views/noNav/index.vue'),
        }]
    },
    {
        path: '/detail',
        name: 'detail',
        component: () =>
            import(/* webpackChunkName: 'detail' */ '../views/case/components/caseDetail2')
    },
    {
        path: '/functionSet',
        name: 'functionSet',
        component: () =>
            import(/* webpackChunkName: 'functionSet' */ '../views/functionSet')
    },
    {
        path: '/init',
        name: 'init',
        component: () => import(/* webpackChunkName: 'init' */ '../views/init/index.vue')
    },
    {
        path: '/init2',
        name: 'init2',
        component: () => import(/* webpackChunkName: 'init2' */ '../views/init/init2.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: 'login' */ '../views/login/login')
    },
    {
        path: '/custormDetail',
        name: 'custormDetail',
        component: () => import(/* webpackChunkName: 'custormDetail' */ '@/components/providePage/custormDetails')
    },
    // 访客看案例
    {
        path: '/visitorCaseDetails',
        name: 'visitorCaseDetails',
        component: () => import(/* webpackChunkName: 'visitorCaseDetails' */ '@/components/providePage/visitorCaseDetails')
    },
    // 新的案例详情 自定义/默认 都用这个
    {
        path: '/newCaseDetails',
        name: 'newCaseDetails',
        component: () => import(/* webpackChunkName: 'newCaseDetails' */ '@/components/providePage/newCaseDetails')
    }
];


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
    // mode: 'history', hash
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
