/*
 */
export default {
    namespaced: true,
    state: {
        team_showWindow: 'normal', // team 弹窗展示样式
        teamAddShow_showWindow: 'normal', // teamAddShow team添加展示 弹窗展示样式
        case_showWindow: 'normal', // case 案例 弹窗展示样式
        team_batch_List: [], // team 选择的list
        case_batch_list: [], // 案例 选择的list
        newResource_batch_List: [], // 本地最新资源 选择的list
        // find_team_batch_List: [], // 发现里的资源
        dragging: false, // 拖动中
    },
    mutations: {
    },
    getters: {},
    actions: {

    }
}
