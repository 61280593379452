<!--
 功能设置
-->
<template>
<div class="accountSet-main">
  <header class="account-header-w flex flex-js">
    <div class="account-header">
      <i class="iconfont icon_arrow_left" @click="closeFunctionSet"></i>
      <span>功能设置</span>
    </div>
  </header>
  <i class="head-line"></i>
  <div class="isLoading-main">
    <div class="tab-list">
      <div class="tab-title">系统设置：</div>
      <div
          class="tab-item tab-item1 flex flex-b"
          @click="tabChange(item)"
          v-Auth="{
          type: 'btn',
          code: item.name,
          pCode: 'Menu',
          rCode: '',
          isAdmin: isSetAuth,
        }"
          v-for="(item, index) in tabList1"
          :key="'ind' + index"
      >
        {{ item.value }}
        <i class="iconfont icon_arrow_right"></i>
      </div>
      <div
          class="tab-item tab-item1 flex flex-b"
          @click="tabChange({ value: '权限设置' })"
          v-if="isSetAuth"
      >
        权限设置
        <i class="iconfont icon_arrow_right"></i>
      </div>
      <div
          v-if="
            authList &&
            authList.length > 0 &&
            authList.find(c => c.path && c.path.toLowerCase() === '/team')
          "
          class="tab-item tab-item1 flex flex-b"
          @click="$emit('openInquiryLog')"
      >
        询档记录
        <i class="iconfont icon_arrow_right"></i>
      </div>
      <div
          class="tab-item tab-item1 flex flex-b"
          @click="tabChange(item)"
          v-Auth="{
          type: 'btn',
          code: item.name,
          pCode: 'Menu',
          rCode: '',
          isAdmin: isSetAuth,
        }"
          v-for="(item, index) in tabList2"
          :key="'ind2' + index"
      >
        {{ item.value }}
        <i class="iconfont icon_arrow_right"></i>
      </div>
      <i class="head-line margin12"></i>
      <div class="tab-title">案例相关：</div>
      <div
          class="tab-item tab-item1 flex flex-b"
          @click="tabChange(item)"
          v-for="(item, index) in tabCase"
          :key="'case' + index"
      >
        {{ item.value }}
        <i class="iconfont icon_arrow_right"></i>
      </div>
      <div
          class="tab-item tab-item1 flex flex-b"
          v-Auth="{
          type: 'btn',
          code: 'Setting',
          pCode: 'Menu',
          rCode: '',
          isAdmin: isSetAuth,
        }"
          @click="tabChange({value: '新增/推送案例默认顺序设置'})"
      >
        新增/推送案例默认顺序设置
        <i class="iconfont icon_arrow_right"></i>
      </div>
      <div
          class="tab-item tab-item1 flex flex-b"
          @click="tabChange({ value: '小程序作品水印设置' })"
      >
        小程序作品水印设置
        <i class="iconfont icon_arrow_right"></i>
      </div>
      <div class="tab-item-noHover tab-item1 flex flex-b">
        作品呈现方式
        <ConSlideTab
            width="94px"
            height="33px"
            :tabList="caseShowWayList"
            :curIndex="caseShowWayIndex"
            itemPaddingLF="0 8.5px"
            @tabChange="slideTabChange"
        ></ConSlideTab>
      </div>
      <template
          v-if="
            (
              authList &&
              authList.length > 0 &&
              authList.find(c => c.path && c.path.toLowerCase() === '/team') &&
              headList &&
              headList.length > 0 &&
              headList.find(c => c.Key && c.Key.toLowerCase() === 'team')
            ) ||
            hotelAuth
          "
      >
        <i class="head-line margin12"></i>
        <div class="tab-title">其它设置：</div>
        <div
            class="tab-item tab-item1 flex flex-b"
            v-if="
              authList &&
              authList.length > 0 &&
              authList.find(c => c.path && c.path.toLowerCase() === '/team')
            "
            @click="tabChange({value: '金刚资源本地范围设置'})"
        >
          金刚资源本地范围设置
          <i class="iconfont icon_arrow_right"></i>
        </div>
        <div
            class="tab-item tab-item1 flex flex-b"
            v-if="hotelAuth"
            @click="tabChange({value: '酒店标签设置'})"
        >
          酒店标签设置
          <i class="iconfont icon_arrow_right"></i>
        </div>
      </template>
    </div>
  </div>
  <SetBranchInfo
      :isShowSet="isShowSet"
      @closeSetDig="isShowSet = false"
  ></SetBranchInfo>
  <CustomTitle
      :isShowCustom="isShowCustom"
      @closeCustomDig="isShowCustom = false"
  ></CustomTitle>
  <CustomLoginBg
      :isShowCustom="isShowLoginBg"
      @closeCustomDig="isShowLoginBg = false"
  ></CustomLoginBg>
  <FullscreenSet
      :isShowSetFull="isShowSetFull"
      @closeFull="isShowSetFull = false"
  ></FullscreenSet>
  <SetSequence
      :isShowSequence="isShowSequence"
      @closeCustomDig="isShowSequence = false" />

  <!-- 颜色 -->
  <ColorManageDig
      :showManageDig="showAddColorDig"
      @handleClose="showAddColorDig = false"
  ></ColorManageDig>
  <!-- 标签 -->
  <lableManageDig
      :showManageDig="showAddLabelDig"
      @handleClose="showAddLabelDig = false"
  ></lableManageDig>

  <!--  本地范围   -->
  <AddAroundDig
      :isTeamIndexPage="true"
      :showAddAround="showAddAround"
      :aroundCityList="aroundCity"
      :allCityList="cityList"
      @closeDig="showAddAround = false"
  ></AddAroundDig>
  <!--  自定义价格  -->
  <custom-price
      priceType="thousand"
      :showCustomPrice="showCustomPrice"
      @cancelClick="showCustomPrice = false"
      @confirmClick="showCustomPrice = false"
  ></custom-price>
</div>
</template>

<script>
import customPrice from "@/views/case/components/custom-price";
import AddAroundDig from "@/views/team/components/addAroundDig";
import lableManageDig from "@/views/case/components/label-mange/lableManageDig.vue";
import ColorManageDig from "@/views/case/components/colorManageDig";
import SetBranchInfo from "./setbranchInfo.vue";
import CustomTitle from "./customTitle.vue";
import CustomLoginBg from "./customLoginBg.vue";
import FullscreenSet from "./fullscreenSet.vue";
import SetSequence from "./setSequence.vue";
import store from "@/store";
import { mapState } from "vuex";
export default {
  name: "functionSet",
  data() {
    return {
      showCustomPrice: false,
      aroundCity: [],
      cityList: [],
      showAddAround: false,
      showAddLabelDig: false,
      showAddColorDig: false,
      closeManage: false,
      isShowSet: false,
      isShowCustom: false,
      isShowLoginBg: false,
      isShowSetFull: false,
      isShowSequence: false,
      tabList1: [
        { value: "用户管理", name: "Authorize" },
      ],
      tabList2: [
        { value: "品牌设置", name: "Setting" },
        { value: "导航菜单自定义", name: "Setting" },
        { value: "公司页（首页）自定义", name: "Setting" },
        { value: "登录背景自定义", name: "Setting" },
        { value: "门户页（休息）设置", name: "Setting" },
      ],
      /**
       * 案例相关设置
       */
      tabCase: [
        { value: "案例标签管理", name: "Setting" }, // 标签
        { value: "案例颜色管理", name: "Setting" }, // 颜色
        { value: "自定义作品模板管理", name: "Setting", val: 'customCaseTemplateList' }, // 自定义作品模板管理
        { value: "价格搜索区间设置", name: "Setting" }, // 价格
        { value: "音乐库管理", name: "Setting" }, // 音乐
        // { value: "新增/推送案例默认顺序设置", name: "Setting" },
        // { value: "小程序作品水印设置", name: "Setting" },
      ],
      hotelAuth: false,
      // isCaseDisplayMode: 'cross',  // vertical 竖版、 cross 横版
      caseShowWayList: [{
        value: '横版',
        type: 'cross'
      },{
        value: '竖版',
        type: 'vertical'
      }],
      caseShowWayIndex: 0,
    }
  },
  created() {
    // 作品封面展示方式
    this.caseShowWayIndex = this.caseShowWayList.findIndex(c => c.type === this.$store.state.caseCoverShowWay);

    // // 案例权限
    // if (
    //     !this.$store.state.authButton ||
    //     !this.$store.state.authButton['case']
    // ) {
    //   this.getAuth('case');
    // }

    // 酒店权限
    this.getHotelAuth();
    if (
        !this.$store.state.authButton ||
        !this.$store.state.authButton['hotel']
    ) {
      this.getAuth('hotel');
    }
  },
  computed: {
    ...mapState({
      branchType: (state) => state.branchType,
      authList: state => state.authList, // 权限
      headList: (state) => state.headList,
    }),
  },
  methods: {
    /**
     * 切换作品封面展示方式
     * @param val
     */
    slideTabChange(val) {
      this.$axios({
        url: "NewWeddingApi/FileManage/LabelManage/BranchLabel/SetBranchGeneralLabelInfo",
        method: "POST",
        data: {
          Key: 'caseCoverShowWay',
          Value: val.item.type,
        },
        success(res) {
          if (res && res.data.status) {
            this.$Success("设置成功");
            this.$store.state.caseCoverShowWay = val.item.type;
            sessionStorage.setItem("insercell", JSON.stringify(this.$store.state));
          }
        },
        error(err) {
          console.log(err);
          this.$Error("设置失败");
        },
      })
    },
    getHotelAuth() {
      this.hotelAuth = this.$store.state.authButton && this.$store.state.authButton["hotel"] ? this.$store.state.authButton["hotel"].find(
          (a) => a.EnCode === "HotelAuthority"
        ) : false;
    },
    /**
     * 获取权限
     */
    getAuth(navName) {
      if (!this.$store.state.authButton || !this.$store.state.authButton.hotel) {
        const module = this.$store.state.authList.find(m => m.path && m.path.toLowerCase() === '/' + navName)
        if (!module || !module.id) return;
        this.$axios({
          url: "NewWeddingApi/SystemModule/Module/Module/GetModuleButtonList",
          method: "POST",
          data: {
            moduleId: module.id
          },
          success: (res) => {
            if (res.data.status) {
              let aa = {
                ...this.$store.state.authButton
              }
              aa[navName] = res.data.data;
              this.$store.state.authButton = aa;
              this.getHotelAuth();
            }
          },
          error(err) {
            console.log(err)
          }
        })
      }
    },
    //返回
    closeFunctionSet() {
      this.$emit("closeFunctionSet");
    },
    tabChange(val) {
      if (val.value === "品牌设置") {
        this.isShowSet = true;
        return;
      }
      if (val.value === "导航菜单自定义") {
        this.isShowCustom = true;
        return;
      }
      if (val.value === "登录背景自定义") {
        this.isShowLoginBg = true;
        return;
      }
      if (val.value === "门户页（休息）设置") {
        this.isShowSetFull = true;
        return;
      }
      if (val.value === "新增/推送案例默认顺序设置") {
        this.isShowSequence = true
        return;
      }
      if (val.value === '案例标签管理') {
        this.showAddLabelDig = true;
        return;
      }
      if (val.value === '案例颜色管理') {
        this.showAddColorDig = true;
        return;
      }
      if (val.value === '金刚资源本地范围设置') {
        this.showAddAround = true;
        return;
      }
      if (val.value === '价格搜索区间设置') {
        this.showCustomPrice = true;
        return;
      }
      this.$emit("tabChange", val);
    },
  },
  components: {
    SetBranchInfo,
    CustomTitle,
    CustomLoginBg,
    FullscreenSet,
    SetSequence,
    ColorManageDig,
    lableManageDig,
    AddAroundDig,
    customPrice
  },
  props: {
    isSetAuth: {
      default: false,
    },
  }
};
</script>
<style scoped lang="scss">
.accountSet-main {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  @include themify($themes) {
    background: themed("main-bg");
  }
  .isLoading-main {
    height: calc(100% - 48px - 64px - 1px);
  }
  z-index: 3;
  .tab-title {
    color: #222;
    display: flex;
    justify-content: space-between;
    padding: 14px 24px 14px 24px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    @include themify($themes) {
      color: themed("input-color");
    }
  }
  .account-header-w {
    border: 0px solid transparent;
  }
  .margin12 {
    margin: 12px 0;
  }
  .tab-item1 {
    padding-left: 24px;
  }
  .head-line {
    display: inline-block;
    width: calc(100% - 48px);
    margin-left: 24px;
    height: 1px;
    background: #eeeeee;
    @include themify($themes) {
      background: themed("hover-bg");
    }
    vertical-align: top;
  }
}
.tab-item-noHover .tab-wrap ::v-deep .slide-tab-item {
  font-weight: 400;
}
.edit-case-iframe {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999;
}
.edit-case-iframe iframe {
  width: 100%;
  height: 100%;
  border: 0;
  background: transparent;
}
</style>
