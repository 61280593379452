/**
 * 各个页面的默认显示设置
 *
 * 根据NewWeddingApi/OrderManage/HeaderManage/HeaderDetail/GetHeader 接口返回来展示
 */
export default {
    case: { // 案例
        list: {
            name1: '进入该模块默认打开',
            option: [
                {name: '默认', val: ''},
                {name: '案例列表', val: 'list'},
                {name: '合集列表', val: 'collection'},
                {name: '收藏列表', val: 'collect'},
            ],
            name2: '页面',
            val: '',
        }
    },
    hotel: { // 酒店
        list: {
            name1: '进入该模块默认打开',
            option: [
                {name: '默认', val: ''},
                {name: '酒店列表', val: 'list'},
                {name: '合集列表', val: 'collection'},
            ],
            name2: '页面',
            val: '',
        },
        details: {
            name1: '进入指定详情默认打开',
            option: [
                {name: '默认', val: ''},
                {name: '首页', val: 'home'},
                {name: '酒店场地', val: 'site'},
                {name: '案例', val: 'case'},
            ],
            val: '',
        }
    },
    team: { // 资源
        list: {
            name1: '进入该模块默认打开',
            option: [
                {name: '默认', val: ''},
                {name: '本店资源', val: 'ourShop'},
                {name: '资源作品展示', val: 'works'},
                {name: '更多资源', val: 'more'},
            ],
            name2: '页面',
            val: '',
        },
        details: {
            name1: '进入指定详情默认打开',
            option: [
                {name: '默认', val: ''},
                {name: '展示', val: 'home'},
                {name: '介绍', val: 'introduce'},
                {name: '案例', val: 'case'},
                {name: '故事', val: 'story'},
                {name: '动态', val: 'dynamic'},
            ],
            val: '',
        },
    },
    article: { // 物品
        details: {
            name1: '进入指定详情默认打开',
            option: [
                {name: '默认', val: ''},
                {name: '预览', val: 'preview'},
                {name: '档期', val: 'schedule'},
                {name: '案例', val: 'case'},
            ],
            val: '',
        }
    },
    banquet_hall: { // 场地
        details: {
            name1: '进入指定详情默认打开',
            option: [
                {name: '默认', val: ''},
                {name: '预览', val: 'preview'},
                {name: '档期', val: 'schedule'},
            ],
            val: '',
        }
    },
}
